import { COLORS } from 'assets/styled';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  $bgColor: string;
  $selected?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10rem;
  padding: 0 20rem;
`;

export const FilterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 10rem));
  width: 250rem;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_1000};
  border-radius: 10rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 20rem;
`;

export const FilterList = styled.div`
  max-height: 300rem;
  overflow-y: auto;
`;

export const ClearWraper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10rem;
`;

export const ClearLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 14rem;
  color: ${COLORS.RED_200};
  ${hoverLineOut}
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s ease;
  padding: 15rem 20rem;
  border-bottom: 1px solid ${COLORS.GREY_300};

  ${(props: StyledProps) =>
    props.$selected &&
    css`
      background-color: ${COLORS.GREY_200};
    `};

  &:hover {
    background-color: ${COLORS.BACKGROUND_COLOR};
  }
`;

export const ItemColor = styled.div`
  border-radius: 10rem;
  width: 20rem;
  height: 10rem;

  ${(props: StyledProps) =>
    props.$bgColor &&
    css`
      background-color: ${props.$bgColor};
    `};
`;

import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';
import { Option } from 'components/Select/type';
import { PriorityOptions } from 'components/Table/Cells/SelectCell/constants';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'toDoTitle',
    value: 'title',
    type: 'string',
  },
  {
    label: 'Created by',
    value: 'created_by_user_id',
    type: 'dropdown',
  },
  {
    label: 'Type',
    value: 'type_id',
    type: 'dropdown',
  },
  {
    label: 'Created at',
    value: 'created_at',
    type: 'date',
  },
  {
    label: 'Priority',
    value: 'priority_id',
    type: 'dropdown',
  },
  {
    label: 'Assigned to',
    value: 'assigned_to_user_id',
    type: 'dropdown',
  },
  {
    label: 'Related',
    value: 'related_to_number',
    type: 'string',
  },
  {
    label: 'Finished',
    value: 'finished',
    type: 'dropdown',
  },
];

const FinishedOptions: Option[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

// Field worker option needs to be added directly for filtering because we don't have it in options
export const useGetToDoAdvancedFilters = (toDoTypesOptions: Option[] = []) => {
  const updatedToDoTypesOptions = [
    ...toDoTypesOptions,
    { value: '1', label: 'Field worker' },
  ];

  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'priority_id') {
      return { ...filter, options: PriorityOptions };
    } else if (filter.value === 'finished') {
      return { ...filter, options: FinishedOptions };
    } else if (filter.value === 'type_id') {
      return { ...filter, options: updatedToDoTypesOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};

import { COLORS, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  isTablet: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 75rem - 185rem - 50rem);
  border-radius: 10rem;
  background: ${COLORS.WHITE};
  padding: 30rem;
  width: 100%;
  max-width: 970rem;
  position: relative;

  ${(props: StyledProps) =>
    props.isTablet &&
    css`
      max-height: calc(100vh - 75rem - 185rem - 100rem);
    `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 300rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoResultsContainer = styled.div`
  width: 100%;
  max-width: 970rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10rem;
`;

export const Message = styled.span`
  margin-top: 30rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const TableWrapper = styled.div`
  margin-top: 40rem;
  overflow-y: auto;
`;

export const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 20rem;
  grid-template-columns: repeat(auto-fill, minmax(220rem, 1fr));
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 40rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const ViewTypeLabel = styled.label`
  cursor: pointer;
  margin-right: ${marginMd};
  font-weight: 300;
  font-size: 18rem;
  line-height: 28rem;
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
    `}
`;

export const AddNewFileButton = styled(Button)`
  margin-left: ${marginMd};
`;

export const SelectInputWrapper = styled.div`
  width: 203rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;
`;

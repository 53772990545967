import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient from '../api/apiService';

const BASE_URL = '/v1/logyx';

export const generateLogyxIntent = async (
  logyxModelId: number,
  productId?: number,
  logyxConfigurationIntentUUID?: string
): Promise<any> => {
  const url = `${BASE_URL}/`;
  const queryParamsObj = {
    product_id: productId,
    logyx_configuration_intent_uuid: logyxConfigurationIntentUUID,
    logyx_model_id: logyxModelId,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.post<any>(fullUrl);

  return data.data;
};

export const generateDealerPanelIntent = async (): Promise<any> => {
  const url = `${BASE_URL}/dealer-panel`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getIntentByUUID = async (intentUUID: string): Promise<any> => {
  const url = `${BASE_URL}/intents/${intentUUID}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

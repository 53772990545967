import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSalesInvoices } from 'services/Invoice/SalesInvoiceService';

export const useGetSalesInvoicesInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean,
  sortBy?: string,
  sortDirection?: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_INVOICES, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getSalesInvoices(
        pageParam,
        perPage,
        undefined,
        undefined,
        undefined,
        searchBy,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetSalesInvoicesOptions = (
  salesInvoicesData: InfiniteData<any> | undefined
) => {
  const salesInvoicesOptions = useMemo(() => {
    if (salesInvoicesData?.pages?.length) {
      return salesInvoicesData.pages
        .map((page: any) => page.sales_invoices)
        .flat()
        .map((sales_invoice: any) => {
          return {
            value: sales_invoice.id,
            label: `${sales_invoice.invoice_number}`,
          };
        });
    }
    return [];
  }, [salesInvoicesData]);
  return salesInvoicesOptions;
};

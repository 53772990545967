import {
  AppointmentReportLabel,
  CloseContainer,
  PrintWrapper,
  Scrollable,
  SpaceBetween,
} from './PreviewAppointmentReportModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useRef } from 'react';
import { Modal } from 'components/Modal/Modal';
import ReportForm, {
  IInitialReportData,
  IReportData,
} from 'pages/FieldWorker/FwReportPage/ReportForm/ReportForm';
import { IAppointment, IReportResource } from 'types/Appointment.types';
import { useGetPreviewReportPageData } from './hooks';
import { BASE_64_PREFIX } from 'pages/Manager/SingleQuotation/constants';
import { formatReportResourcesIntoResourceWorkingHours } from './helpers';
import { removeInactiveInvoices } from 'pages/FieldWorker/FwReportPage/helpers';
import { DownloadSimple } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS, marginLg, marginXs } from 'assets/styled';
import { useReactToPrint } from 'react-to-print';
import { Level } from 'components/Modal/type';

interface IPreviewAppointmentReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  previewReportData: any;
  appointment: IAppointment;
  level?: Level;
}

export const PreviewAppointmentReportModal = ({
  isOpen,
  setIsOpen,
  previewReportData,
  appointment,
  level = 'SECOND',
}: IPreviewAppointmentReportModalProps) => {
  const reportRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { data, isLoadingIntervals, isLoadingInvoices } =
    useGetPreviewReportPageData(appointment?.id, appointment?.sales_order?.id);

  const { intervalsData, invoicesData } = data;

  const reportData: IReportData = {
    appointment: appointment,
    appointmentLines: previewReportData?.appointment_lines || [],
    intervals: intervalsData?.appointment_intervals || [],
    invoices: invoicesData?.sales_invoices
      ? removeInactiveInvoices(invoicesData?.sales_invoices)
      : [],
    firstName: previewReportData?.report?.submitted_by.name,
    lastName: previewReportData?.report?.submitted_by?.last_name,
    signatureFullName: previewReportData?.signature[0].fullname,
  };

  const initialReportData: IInitialReportData = {
    newQuotation: previewReportData?.report?.new_quotation || null,
    pictures: previewReportData?.pictures || [],
    situationDescription: previewReportData?.report?.situation_description,
    followUpActionDescription:
      previewReportData?.report?.follow_up_action_description,
    furtherComments: previewReportData?.report?.customer_comment,
    signature: `${BASE_64_PREFIX}${previewReportData?.signature[0].signature}`,
    expectedTotalWorkingHours:
      previewReportData?.report?.expected_working_hours,
    resourceWorkingHours: formatReportResourcesIntoResourceWorkingHours(
      previewReportData?.report_resources as IReportResource[]
    ),
  };

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: `${t('Appointment report')} ${appointment?.id}`,
    onAfterPrint: () => console.log('Report PDF downloaded successfully'),
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed', maxWidth: '1075rem' }}
      level={level}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <SpaceBetween>
        <AppointmentReportLabel>
          {t('Viewing appointment report') + ':'}
        </AppointmentReportLabel>
        <Icon
          data-testid="export-report-pdf"
          svg={DownloadSimple}
          color={COLORS.PRIMARY}
          size={25}
          marginRight={marginLg}
          marginLeft={marginXs}
          onClick={() => handlePrint()}
        />
      </SpaceBetween>
      <Scrollable>
        <PrintWrapper ref={reportRef}>
          <ReportForm
            reportData={reportData}
            initialData={initialReportData}
            isLoadingIntervals={isLoadingIntervals}
            isLoadingInvoices={isLoadingInvoices}
            previewInvoiceModalLevel={'THIRD'}
          />
        </PrintWrapper>
      </Scrollable>
    </Modal>
  );
};

import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  Title,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  AwaitingLogyxConfigurationContainer,
  AwaitingLabel,
  LoaderWrapper,
} from './ConfigureLogyxModal.styled';
import { config } from 'config';
import { useEffect, useState } from 'react';
import Loader from 'components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import {
  setIsAwaitingConfiguration,
  setLogyxConfigurationIntent,
  setPendingIntentUUID,
} from 'store/Logyx/actions/logyx';
import { ILogyxReducerState } from 'store/Logyx/reducers/logyx';
import { Danger } from 'components/Modal/Danger/Danger';
import { useGenerateLogyxIntent } from './hooks';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { toast } from 'utils/toast';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';
import { useCheckForExistingLogyxConfigurationOnPageFocus } from 'utils/hooks/useCheckForExistingLogyxConfigurationOnPageFocus';
import { Base64 } from 'js-base64';

export interface IConfigureLogyxModalProps {
  isOpen: boolean;
  setIsOpen: any;
  selectedProduct: any;
  onManual: () => void;
  onLogyxConfigurationFinished: (logyxAttributes: any) => void;
}

const LOGYX_FORMS_APP_URL = config.logyx.logyxFormsUrl;

export function ConfigureLogyxModal({
  isOpen,
  setIsOpen,
  selectedProduct,
  onManual,
  onLogyxConfigurationFinished,
}: IConfigureLogyxModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDangerModalOpen, setIsDangerModalOpen] = useState<boolean>(false);
  const { isMobileApp, darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  const logyxInfo: ILogyxReducerState = useSelector(
    (state: IRootReducerState) => state.logyxInfo
  );
  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const logyxModelId = selectedProduct.logyx_model_id;
  const productId = selectedProduct.id;

  const {
    data: logyxIntentData,
    mutate: generateLogyxIntent,
    isSuccess: isGenerateLogyxIntentSuccess,
  } = useGenerateLogyxIntent();

  useCheckForExistingLogyxConfigurationOnPageFocus(
    false,
    logyxIntentData?.intent_uuid
  );

  // Open logyx forms
  const handleConfigureWithLogyx = () => {
    if (!LOGYX_FORMS_APP_URL) {
      alert('Logyx forms app url missing');
      return;
    }
    if (!logyxModelId) {
      alert('No logyx model id present on product');
      return;
    }
    dispatch(setIsAwaitingConfiguration(true));
    dispatch(setLogyxConfigurationIntent(null));
    generateLogyxIntent({ logyxModelId, productId });
  };

  useEffect(() => {
    if (isGenerateLogyxIntentSuccess && logyxIntentData) {
      dispatch(setPendingIntentUUID(logyxIntentData.intent_uuid));

      const base64Metadata = Base64.encode(
        JSON.stringify({
          companyId: companyId,
        })
      );

      const queryParamsObj = {
        modelId: selectedProduct.logyx_model_id,
        ...(darkMode && { isDarkMode: true }),
        lang: i18n.resolvedLanguage || FALLBACK_I18N_LANG,
        intentUUID: logyxIntentData.intent_uuid,
        base64Metadata,
      };
      const fullUrl = getUrlWithQueryParams(
        `${LOGYX_FORMS_APP_URL}/configure/`,
        queryParamsObj
      );
      if (companyId) {
        if (isMobileApp) {
          const mobileMessage: IMobileMessage = {
            type: MobileMessageTypes.OPEN_LOGYX,
            payload: fullUrl,
          };
          postMobileMessage(mobileMessage);
        } else {
          window.open(fullUrl, 'LOGYX_FORMS');
        }
      } else {
        toast.warn('Configuration error, reload page and try again');
      }
    }
  }, [isGenerateLogyxIntentSuccess]);

  // Triggered by onLogyxConfigurationFinishedEvent
  if (
    !logyxInfo.isAwaitingConfiguration &&
    logyxInfo.logyxConfigurationIntent &&
    logyxModelId
  ) {
    if (
      Number(logyxModelId) ===
      Number(logyxInfo?.logyxConfigurationIntent?.data?.modelId)
    ) {
      onLogyxConfigurationFinished(logyxInfo.logyxConfigurationIntent);
      dispatch(setIsAwaitingConfiguration(false));
      dispatch(setLogyxConfigurationIntent(null));
      dispatch(setPendingIntentUUID(null));
    } else {
      alert(
        `Logyx model id of product submitted for configuration doesn't match configuration data model id`
      );
      dispatch(setIsAwaitingConfiguration(false));
      dispatch(setLogyxConfigurationIntent(null));
      dispatch(setPendingIntentUUID(null));
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) =>
        logyxInfo.isAwaitingConfiguration ? () => null : setIsOpen(boolean)
      }
      modalStyle={{ position: 'fixed', margin: 'auto' }} // Center positioning
    >
      {logyxInfo.isAwaitingConfiguration ? (
        <AwaitingLogyxConfigurationContainer>
          <AwaitingLabel>Awaiting logyx configuration</AwaitingLabel>
          <LoaderWrapper>
            <Loader positionType="relative" />
          </LoaderWrapper>
          <CloseAbsoluteWrapper>
            <Close
              onClick={() => {
                setIsDangerModalOpen(true);
              }}
            />
          </CloseAbsoluteWrapper>
        </AwaitingLogyxConfigurationContainer>
      ) : (
        <Container>
          <Title>
            {t(
              'Do you wish to configure the product through Logyx or manually'
            ) + '?'}
          </Title>
          <ButtonWrapper>
            <Button
              label={t('Manual')}
              secondary
              width="200rem"
              onClick={() => {
                onManual();
                setIsOpen(false);
              }}
            />
            <Button
              label={t('Logyx')}
              primary
              width="200rem"
              onClick={() => handleConfigureWithLogyx()}
            />
          </ButtonWrapper>
          <CloseAbsoluteWrapper>
            <Close
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </CloseAbsoluteWrapper>
        </Container>
      )}
      <Modal
        level={'SECOND'}
        isOpen={isDangerModalOpen}
        setIsOpen={setIsDangerModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (isMobileApp) {
                const mobileMessage: IMobileMessage = {
                  type: MobileMessageTypes.CLOSE_LOGYX,
                };
                postMobileMessage(mobileMessage);
              }
              dispatch(setIsAwaitingConfiguration(false));
              dispatch(setLogyxConfigurationIntent(null));
              dispatch(setPendingIntentUUID(null));
              setIsDangerModalOpen(false);
              setIsOpen(false);
            },
            text: t('Cancel'),
            disabled: false,
          }}
          cancel={{
            onClick: () => setIsDangerModalOpen(false),
            text: t('Back'),
          }}
          title={t('Cancel configuration')}
          description={
            t(
              'Are you sure you want to cancel logyx configuration for this product'
            ) + '?'
          }
        />
      </Modal>
    </Modal>
  );
}

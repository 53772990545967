import { ILabel, ILabelData, ILabelDataDTO } from 'types/EntityLabel.types';
import { IReorderLabelDTO } from './hooks';

export const swapDragAndHoverLabelIndexes = (
  localLabels: ILabel[],
  dragIndex: number,
  hoverIndex: number
) => {
  // Create a copy of the array to avoid mutating the original data
  const labelsCopy: ILabel[] = localLabels.map((label) => ({ ...label }));

  // Swap the index properties of the specified elements
  const tempIndex = labelsCopy[dragIndex].index;
  labelsCopy[dragIndex].index = labelsCopy[hoverIndex].index;
  labelsCopy[hoverIndex].index = tempIndex;

  return labelsCopy;
};

export const sortLabelsByIndex = (labels: ILabel[]) => {
  return labels.sort((a: ILabel, b: ILabel) => a.index - b.index);
};

export const isOrderDifferentFromInitial = (
  initialLabels: ILabel[],
  reorderLabelsDTOs: IReorderLabelDTO[]
): boolean => {
  if (initialLabels.length !== reorderLabelsDTOs.length) {
    return true; // Length difference implies a change in order or content
  }

  // Create a map of initial label indexes by label id for quick lookup
  const initialIndexMap = new Map<number, number>();
  initialLabels.forEach((label: ILabel) =>
    initialIndexMap.set(label.id, label.index)
  );

  // Check each label in the reordered array to see if its index has changed
  for (const reorderLabelDTO of reorderLabelsDTOs) {
    const initialIndex = initialIndexMap.get(Number(reorderLabelDTO.labelId));

    if (
      initialIndex === undefined ||
      initialIndex !== Number(reorderLabelDTO.labelIndex)
    ) {
      return true; // Found a line whose index has changed
    }
  }

  return false; // No changes in the order
};

export const formatLabelsData = (labelsData: ILabelData[]): ILabelDataDTO[] => {
  if (!labelsData) return [];

  return labelsData?.map((labelData) => ({
    entityId: labelData.entity_id,
    entityTypeId: labelData.entity_type_id,
    index: labelData.index,
    label: {
      ...labelData.label,
    },
  }));
};

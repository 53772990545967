import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from 'components/Close/Close';
import { H4 } from 'assets/styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useParams } from 'react-router-dom';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  DatePickerWrap,
  Section,
  Title,
  TitleContainer,
} from './EditOrderDetailsModal.styled';
import { Formik } from 'formik';
import {
  useEditPurchaseOrderDetails,
  useGetSinglePurchaseOrder,
} from '../hooks';
import moment from 'moment';
import { PurchaseOrderStatus } from '../constants';
import DatePicker from 'components/DatePicker/DatePicker';
import { editOrderDetailsSchema } from './validation';

export interface IEditOrderDetailsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
}

export function EditOrderDetailsModal({
  isOpen,
  setIsOpen,
  onCancel,
}: IEditOrderDetailsModalProps) {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetSinglePurchaseOrder(id!);
  const { mutate: editPurchaseOrderDetails } = useEditPurchaseOrderDetails(id!);
  const handleEditPurchaseOrder = (values: any) => {
    editPurchaseOrderDetails({
      expected_delivery_date: moment.utc(
        values.expected_delivery_date,
        'YYYY-MM-DD'
      ),
      promised_delivery_date: moment.utc(
        values.promised_delivery_date,
        'YYYY-MM-DD'
      ),
      delivery_date: moment.utc(values.actual_delivery_date, 'YYYY-MM-DD'),
      reference: values.reference,
    });
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit purchase order details')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={handleEditPurchaseOrder}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            expected_delivery_date: data?.expected_delivery_date
              ? moment(data?.expected_delivery_date?.split('T')[0]).format(
                  'YYYY-MM-DD'
                )
              : null,
            promised_delivery_date: data?.promised_delivery_date
              ? moment(data?.promised_delivery_date?.split('T')[0]).format(
                  'YYYY-MM-DD'
                )
              : null,
            actual_delivery_date: data?.delivery_date
              ? moment(data?.delivery_date?.split('T')[0]).format('YYYY-MM-DD')
              : null,
            reference: data?.reference,
          }}
          validateOnMount={true}
          validationSchema={editOrderDetailsSchema}
        >
          {({ errors, setFieldValue, values, handleBlur, isValid }) => {
            return (
              <Container>
                <Section>
                  <H4>{t('Expected delivery date')}</H4>
                  <DatePickerWrap>
                    <DatePicker
                      pwId="expected-delivery-date-field"
                      date={moment(values?.expected_delivery_date)}
                      setDate={(newValue: any) =>
                        setFieldValue(
                          'expected_delivery_date',
                          newValue?.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </DatePickerWrap>
                </Section>
                <Section>
                  <H4>{t('Confirmed delivery date')}</H4>
                  <DatePickerWrap>
                    <DatePicker
                      pwId="date-field"
                      date={moment(values?.promised_delivery_date)}
                      setDate={(newValue: any) =>
                        setFieldValue(
                          'promised_delivery_date',
                          newValue?.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </DatePickerWrap>
                </Section>
                {data?.status?.name === PurchaseOrderStatus.RECEIVED && (
                  <Section>
                    <H4>{t('Actual delivery date')}</H4>
                    <DatePickerWrap>
                      <DatePicker
                        pwId="date-field"
                        date={moment(values?.actual_delivery_date)}
                        setDate={(newValue: any) =>
                          setFieldValue(
                            'actual_delivery_date',
                            newValue?.format('YYYY-MM-DD')
                          )
                        }
                      />
                    </DatePickerWrap>
                  </Section>
                )}
                <Section>
                  <H4>{t('Reference')}</H4>
                  <FormikInput
                    errorMessage={
                      errors['reference'] ? (errors['reference'] as string) : ''
                    }
                    pwId="reference-field"
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('reference', value);
                      handleBlur(e);
                    }}
                    height={'41rem'}
                    name="reference"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '10rem',
                    }}
                    disabled={
                      data?.status?.name !== PurchaseOrderStatus.NEW &&
                      data?.digital_order_enabled
                    }
                  />
                </Section>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    onClick={() => {
                      if (isValid) handleEditPurchaseOrder(values);
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient from '../api/apiService';
import { IReorderLabelsRequestDTO } from 'pages/Manager/LabelsPage/hooks';
import { LabelEntityTypeId } from 'types/EntityLabel.types';

const BASE_URL = '/v1/labels/';

export const getLabels = async (
  page: number,
  perPage: number,
  searchBy?: string,
  entityType?: number
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page,
    per_page: perPage,
    search_term: searchBy,
    ...(entityType && { entity_type_id: entityType }),
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export interface IAddEditLabelDTO {
  id?: number;
  name: string;
  description: string;
  color: string;
  label_group_id: number;
}

export const addLabel = async (dto: IAddEditLabelDTO): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, dto);
  return data.data;
};

export const getLabelsGroups = async (): Promise<any> => {
  const url = `${BASE_URL}groups`;
  const { data } = await apiClient.get<any>(url);
  return data.data.groups;
};

export const deleteLabel = async (id: number): Promise<any> => {
  const url = `${BASE_URL}${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const editLabel = async (dto: IAddEditLabelDTO): Promise<any> => {
  const url = `${BASE_URL}${dto.id}`;
  delete dto.id;
  const { data } = await apiClient.patch<any>(url, dto);

  return data.data;
};

export const addLabelToEntity = async (
  entityType: LabelEntityTypeId,
  entityId: number,
  labelId: number
): Promise<any> => {
  const payload = {
    entity_type_id: entityType,
  };
  const url = `${BASE_URL}${labelId}/entities/${entityId}`;
  const { data } = await apiClient.post<any>(url, payload);

  return data.data;
};

export const deleteLabelFromEntity = async (
  entityType: LabelEntityTypeId,
  entityId: number,
  labelId: number
): Promise<any> => {
  console.log(entityType);
  const queryParamsObj = {
    entity_type_id: entityType,
  };
  const url = `${BASE_URL}${labelId}/entities/${entityId}`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.delete<any>(fullUrl);

  return data.data;
};

export const reorderLabels = async (dto: IReorderLabelsRequestDTO) => {
  const url = `${BASE_URL}reorder`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};

export const getLabelsByEntity = async (
  entityId: string,
  entityType: LabelEntityTypeId,
  page?: number,
  perPage?: number,
  searchBy?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    entity_type_id: entityType,
  };
  const url = `${BASE_URL}entities/${entityId}`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data.entity_labels;
};

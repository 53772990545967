import { CSSProperties, MouseEvent } from 'react';
import { StyledButton, Container, SpinnerWrapper, Text } from './Button.styled';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';

interface IButton {
  pwId?: string;
  label: string;
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
  icon?: any;
  iconStyles?: CSSProperties; // Or styled components css``
  type?: string;
  height?: string;
  width?: string;
  primary?: boolean;
  secondary?: boolean;
  table?: boolean;
  tableAlt?: boolean;
  red?: boolean;
  green?: boolean;
  redOutline?: boolean;
  greenOutline?: boolean;
  link?: boolean;
  redLink?: boolean;
  color?: string;
  fontSize?: string;
  sizeIcon?: number;
  loader?: boolean;
  underline?: boolean;
  className?: string;
  colorIcon?: string;
  weightIcon?: string;
}

const Button = ({
  pwId,
  label,
  onClick,
  icon,
  iconStyles,
  sizeIcon,
  primary,
  secondary,
  table,
  tableAlt,
  red,
  green,
  redOutline,
  greenOutline,
  link,
  redLink,
  underline,
  loader,
  className,
  colorIcon,
  weightIcon,
  ...restProps
}: IButton) => {
  return (
    <Container>
      <StyledButton
        data-testid={pwId}
        className={className}
        $primary={primary}
        $secondary={secondary}
        $table={table}
        $tableAlt={tableAlt}
        $red={red}
        $green={green}
        $redOutline={redOutline}
        $greenOutline={greenOutline}
        $link={link}
        $redLink={redLink}
        $underline={underline}
        {...restProps}
        onClick={onClick}
      >
        {icon && (
          <Icon
            svg={icon}
            wrapperStyle={iconStyles}
            size={sizeIcon}
            color={colorIcon}
            weight={weightIcon}
            marginRight="5rem"
          />
        )}
        <Text>{label}</Text>
      </StyledButton>
      {loader && (
        <SpinnerWrapper>
          <Spinner size={10} color={COLORS.BLACK} />
        </SpinnerWrapper>
      )}
    </Container>
  );
};

export default Button;

import { COLORS, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $inModal: boolean;
}

export const StyledDropdown = styled.div`
  position: relative;
  font-size: 18rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  align-self: flex-start;
`;

export const ChevronDown = styled.div`
  transform: translateY(-50%);
`;

export const Menu = styled.div`
  width: 300rem;
  position: absolute;
  top: 40rem;
  right: 0;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_1000};
  border-radius: 10rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  padding: 20rem 0;

  ${(props: StyledProps) =>
    props.$inModal &&
    css`
      bottom: 40rem;
      top: auto;
    `};
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
  padding: 0 20rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  min-width: 120rem;
`;

export const LabelSpan = styled.span`
  white-space: nowrap;
`;

export const LabelItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 250rem;

  & > div:not(:last-child) {
    position: relative;
    &::before {
      content: '';
      width: 90%;
      height: 1px;
      background-color: ${COLORS.GREY_300};
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${marginSm};
  padding: 0 20rem;
`;

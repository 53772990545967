import { H3 } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  SubmitRow,
  Margin,
  FirstRow,
  SpinnerWrapper,
} from './InvoiceDetailsTab.styled';
import Button from 'components/Button/Button';
import { Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { ICreateInvoiceDetails } from './constants';
import { Dispatch, SetStateAction } from 'react';
import { createInvoiceDetailsSchema } from './validation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Day } from 'assets/icons/day.svg';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import FakeInput from 'components/FakeInput/FakeInput';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

interface IInvoiceDetailsTabProps {
  onForward: () => void;
  onBack: () => void;
  calculateInvoiceDetails: () => ICreateInvoiceDetails;
  setInvoiceDetails: Dispatch<SetStateAction<ICreateInvoiceDetails>>;
  isDownpaymentInvoiceCreated: boolean;
  totalAmount: number | undefined;
  isLoadingTotalAmount: boolean;
}

const InvoiceDetailsTab = ({
  onForward,
  onBack,
  setInvoiceDetails,
  calculateInvoiceDetails,
  isDownpaymentInvoiceCreated,
  totalAmount,
  isLoadingTotalAmount,
}: IInvoiceDetailsTabProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  return (
    <Container>
      <FirstRow>
        <H3>{t('Invoice details')}</H3>
      </FirstRow>
      <Formik
        enableReinitialize
        initialValues={calculateInvoiceDetails()}
        validationSchema={createInvoiceDetailsSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          setTouched,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId="external-reference-field"
                  errorMessage={
                    touched['externalId'] ? errors['externalId'] : ''
                  }
                  height={'41rem'}
                  name="externalId"
                  placeholder={t('External reference')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('externalId', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
                <Input
                  pwId="days-valid-field"
                  type="number"
                  icon={Day}
                  errorMessage={touched['daysValid'] ? errors['daysValid'] : ''}
                  height={'41rem'}
                  name="daysValid"
                  placeholder={t('Term of payment')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('daysValid', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
              </Row>
              <Row>
                <DatePicker
                  date={moment(values?.date)}
                  width="50%"
                  setDate={(newValue: any) =>
                    setFieldValue('date', newValue?.format('YYYY-MM-DD'))
                  }
                  label={t('Invoice date')}
                />
                {totalAmount !== undefined ? (
                  <FakeInput
                    height={'41rem'}
                    symbol={currencySymbol}
                    label={totalAmount?.toString() || ''}
                    width="50%"
                    placeholder={
                      isDownpaymentInvoiceCreated
                        ? t('Total amount')
                        : t('Down payment')
                    }
                  />
                ) : isLoadingTotalAmount ? (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                ) : null}
              </Row>
              <SubmitRow>
                <Button
                  width={'200rem'}
                  onClick={() => onBack()}
                  label={t('Back')}
                  secondary
                />
                <Margin>
                  <Button
                    width={'200rem'}
                    onClick={async () => {
                      const touched = {};
                      Object.keys(values).forEach((key) => {
                        touched[key] = true;
                      });
                      setTouched(touched); // Trigger validation for all fields
                      if (isValid) {
                        setInvoiceDetails(values);
                        onForward();
                      }
                    }}
                    label={t('Next')}
                    primary
                  />
                </Margin>
              </SubmitRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default InvoiceDetailsTab;

import { EmailTemplateColors } from './types';

export const colors = [
  EmailTemplateColors.color_1,
  EmailTemplateColors.color_2,
  EmailTemplateColors.color_3,
  EmailTemplateColors.color_4,
  EmailTemplateColors.color_5,
];

export const defaultTemplate = {
  DRAFT_QUOTATION_HEADLINE: 'Quotation {{QUOTATION_NUMBER}}',
  DRAFT_QUOTATION_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your indicative quotation is ready and the PDF version of it can be found in the attachment of this email.. \n \n Thank you for your inquiry! \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  DEFINITIVE_QUOTATION_HEADLINE: 'Quotation {{QUOTATION_NUMBER}}',
  DEFINITIVE_QUOTATION_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your quotation is ready, and you can find the PDF version in the attachment. \n \n Thank you for your interest and please let us know your response to the offer at your earliest convenience. \n \n {{SIGNATURE_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_ORDER_HEADLINE: 'Order Confirmation {{SALES_ORDER_NUMBER}}',
  SALES_ORDER_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Thank you for your order. We are pleased to confirm your purchase. You can find a PDF version of the order in the attachment. \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_INVOICE_HEADLINE: 'Order Invoice {{SALES_INVOICE_NUMBER}}',
  SALES_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_CREDIT_INVOICE_HEADLINE: 'Credit Invoice {{SALES_INVOICE_NUMBER}}',
  SALES_CREDIT_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  DOWN_PAYMENT_INVOICE_HEADLINE:
    'Down payment Invoice {{SALES_INVOICE_NUMBER}}',
  DOWN_PAYMENT_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your down payment invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SIGNED_QUOTATION_HEADLINE: 'Signed quotation {{QUOTATION_NUMBER}}',
  SIGNED_QUOTATION_BODY:
    'Dear,\n \n Quotation {{QUOTATION_NUMBER}} is signed. \n \n You can find the signed PDF version in the attachment.',
  SALES_INVOICE_DUE_DATE_FIRST_REMINDER_HEADLINE:
    'Past Due Date First Reminder',
  SALES_INVOICE_DUE_DATE_FIRST_REMINDER_BODY:
    'Our records show that an invoice of ours has not yet been paid, despite the due date having passed. Perhaps this escaped your attention. \n \n The invoice in question is {{invoice_number}}, which was sent to you on {{invoice_date_str}}. \n Could you please take a moment to review it? You can pay the outstanding amount via the payment link. \n \n A copy of the invoice is attached. \n \n If the invoice has already been paid, please disregard this letter.',
  SALES_INVOICE_DUE_DATE_SECOND_REMINDER_HEADLINE:
    'Past Due Date Second Reminder',
  SALES_INVOICE_DUE_DATE_SECOND_REMINDER_BODY:
    'Our records show that an invoice of ours still has not been paid, despite a previous reminder. \n \n The invoice in question is {{invoice_number}}, which was sent to you on {{invoice_date_str}}. May we kindly but urgently request that you arrange for payment. \n \n A copy of the invoice is attached. \n \n If the invoice has already been paid, please disregard this letter.',
  SALES_INVOICE_DUE_DATE_THIRD_REMINDER_HEADLINE:
    'Past Due Date Third and Final Reminder',
  SALES_INVOICE_DUE_DATE_THIRD_REMINDER_BODY:
    'Despite two previous reminders, our invoice {invoice_number} has still not been paid. \n \n The invoice was sent to you on {invoice_date_str}. We urgently ask that you pay the outstanding amount within a few days. \n \n If we do not receive payment within 5 business days, we will be forced to transfer the invoice to our debt collection agency. This will incur additional costs for you. \n \n A copy of the invoice is attached. \n \n If the invoice has already been paid, please disregard this letter.',
};

export const DEFAULT_HEADLINE_SIZE = '24px';
export const DEFAULT_BODY_SIZE = '16px';

import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 30rem;
  top: 20rem;
  width: calc(100% - 60rem);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: calc(90vh - 200rem);
  overflow-y: scroll;
`;

export const AppointmentReportLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  margin-bottom: 10rem;
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PrintWrapper = styled.div`
  // These styles will only be applied when printing
  @media print {
    background-color: ${COLORS.WHITE};
    padding: 10mm;
    @page {
      size: auto; /* auto is the initial value */

      /* this affects the margin in the printer settings */
      margin: 10mm 10mm 10mm 10mm;
    }
  }
`;

import { SortDirection } from 'components/Table/constants';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IToDoDTO } from 'types/ToDo.types';

const BASE_URL = '/v1/todo/';
interface QueryParams {
  page?: number;
  per_page?: number;
  search?: string;
  sort_by?: string;
  sort_direction?: string;
  advanced_filters?: string;
  filter_finished?: boolean;
}

export const getToDos = async (
  page?: number,
  sortBy?: string,
  sortDirection?: string,
  perPage?: number,
  searchBy?: string,
  selectedTab?: boolean,
  advancedFilters?: string
): Promise<any> => {
  const queryParamsObj: QueryParams = {
    page: page,
    per_page: perPage,
    search: searchBy,
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
  };
  const url = BASE_URL;

  let fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  if (selectedTab === true) {
    fullUrl += `&filter_finished=true`;
  } else if (selectedTab === false) {
    fullUrl += `&filter_finished=false`;
  }

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const createToDo = async (createToDoData: IToDoDTO): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, createToDoData);

  return data.data;
};

export const updateToDo = async (
  id: string,
  updateToDoData: IToDoDTO
): Promise<any> => {
  const url = `${BASE_URL}${id}`;
  const { data } = await apiClient.patch<any>(url, updateToDoData);
  return data.data;
};

export const getSingleToDo = async (id: string): Promise<any> => {
  const url = `${BASE_URL}${id}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getToDoTypes = async (): Promise<any> => {
  const url = `${BASE_URL}type`;
  const { data } = await apiClient.get<any>(url);

  return data.data.todo_types;
};

export const deleteToDo = async (id: string): Promise<any> => {
  const url = `${BASE_URL}${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const getCommentsForToDo = async (
  id: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

interface IToDoComment {
  headline: string;
  description: string;
  is_internal: boolean;
}

export const addCommentForToDo = async (
  id: string,
  commentData: IToDoComment
): Promise<any> => {
  const url = `${BASE_URL}${id}/comments`;
  const { data } = await apiClient.post<any>(url, commentData);

  return data.data;
};

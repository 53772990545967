import {
  Container,
  FirstRow,
  NoContentLabel,
  NotificationsWrapper,
  Title,
} from './NotificationsPage.styled';
import { useTranslation } from 'react-i18next';
import { useGetNotifications, useMarkAllAsRead } from './hooks';
import { useCallback, useRef } from 'react';
import { NOTIFICATIONS_PER_PAGE } from './constants';
import { INotification } from 'types/Notification.types';
import NotificationCard from './NotificationCard/NotificationCard';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const dataRef = useRef<HTMLDivElement>(null);
  const { data, fetchNextPage, hasNextPage } = useGetNotifications(
    NOTIFICATIONS_PER_PAGE,
    ''
  );

  const { mutate: markAllAsRead, isLoading: isLoadingMarkAllAsRead } =
    useMarkAllAsRead();

  let notifications: INotification[] = [];
  if (data?.pages?.length) {
    notifications = data.pages.map((page) => page.notifications).flat();
  }

  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight - 1
      )
    ) {
      return;
    }

    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  return (
    <Container>
      <FirstRow>
        <Title>{t('Notifications')}</Title>
        <Button
          label={t('Delete all')}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          link
          onClick={() =>
            !isLoadingMarkAllAsRead &&
            markAllAsRead(
              {},
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_NOTIFICATIONS,
                  ]);
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
                  ]);
                },
              }
            )
          }
          fontSize="18rem"
        />
      </FirstRow>
      <NotificationsWrapper onScroll={onScroll} ref={dataRef}>
        {notifications.length ? (
          notifications.map((notification: INotification, index: number) => {
            return <NotificationCard key={index} notification={notification} />;
          })
        ) : (
          <NoContentLabel>
            {t('There are currently no notifications')}
          </NoContentLabel>
        )}
      </NotificationsWrapper>
    </Container>
  );
};
export default NotificationsPage;

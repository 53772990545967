import { ICustomer } from 'types/Customer.types';
import { DiscountType } from 'types/Discount.types';
import { Option } from 'components/Select/type';
import { ICompanySettings } from 'types/Company.types';
import { ICreateQuotationCustomerDetails } from '../NewQuotationPage/CustomerTab/constants';
import { ICreateQuotationOrderDetails } from '../NewQuotationPage/OrderTab/constants';

export const getInitialCustomerDetailsData = (
  initialCountryCCA2: string
): ICreateQuotationCustomerDetails => {
  return {
    existingCustomer: null,
    name: '',
    gender: '',
    email: '',
    phone: '',
    country: initialCountryCCA2,
    street: '',
    code: '',
    last_name: '',
    city: '',
    house_number: '',
    company_name: '',
    vat_number: '',
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    title: '',
  };
};

export const calculateInitialDownpayment = (
  totalSalesPrice: string,
  companySettings: ICompanySettings
) => {
  if (!companySettings) {
    return 0;
  }
  if (
    Number(totalSalesPrice) >
    Number(companySettings?.downpayment_threshold_amount)
  ) {
    return (
      (Number(totalSalesPrice) *
        Number(companySettings.downpayment_default_percentage)) /
      100
    );
  } else {
    return 0;
  }
};

export const getInitialOrderDetailsData = (
  companySettings: ICompanySettings
): ICreateQuotationOrderDetails => {
  return {
    paymentCondition: companySettings.payment_condition,
    reference: '',
    downPayment: '',
    workingHours: '0',
    workingHoursRate: companySettings.working_hours_rate.toString(),
    workingHoursDiscount: '0',
    workingHoursDiscountType: DiscountType.PERCENTAGE,
    discount: '0',
    discountType: DiscountType.PERCENTAGE,
    labels: [],
  };
};

export const generateQuickSalesOrderCustomerDetails = (
  isShippingDataFormShown: boolean
) => {
  const genderOptions: Option[] = [
    { value: 'MALE', label: 'Male', key: 'male' },
    { value: 'FEMALE', label: 'Female', key: 'female' },
    { value: 'OTHER', label: 'Other', key: 'other' },
  ];

  const customerDetails: ICreateQuotationCustomerDetails = {
    existingCustomer: null,
    name: 'Sample sales order',
    last_name: 'Sample sales order',
    gender: genderOptions[0].value,
    email: 'sampleSalesOrder@email.com',
    country: 'NL',
    phone: '+3811231231',
    street: 'Sample sales order',
    code: '21000',
    city: 'Sample sales order',
    house_number: '1337',
    company_name: 'Sample sales order',
    vat_number: '211',
    title: 'Mr.',
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    isShippingDataFormShown,
  };
  return customerDetails;
};

export const generateExistingCustomerDetails = (
  customer: ICustomer,
  isShippingDataFormShown: boolean
) => {
  const customerDetails: ICreateQuotationCustomerDetails = {
    existingCustomer: customer,
    name: customer.name,
    last_name: customer.last_name,
    gender: customer.gender!,
    email: customer.email,
    country: customer.address.country.cca2,
    phone: customer.phone,
    street: customer.address.street,
    code: customer.address.zip_code,
    city: customer.address.city!,
    house_number: customer.address.house_number!,
    company_name: customer.company_name!,
    vat_number: customer.vat_number!,
    title: customer.title!,
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    isShippingDataFormShown,
  };
  return customerDetails;
};

import { formatNumber } from 'utils/numberFormatter';
import { convertToTitleCase } from 'utils/stringUtils';

export const formatProductFormDTONumberFields = (
  key: string,
  value: string
) => {
  const productFormDTONumberFields = [
    'salesPrice',
    'purchasePrice',
    'quantity',
    'discount',
  ];
  if (productFormDTONumberFields.includes(key)) {
    return formatNumber(value);
  } else {
    return value;
  }
};

export const mapValueToPlaceholder = (value: string) => {
  switch (value) {
    case 'placement':
      return convertToTitleCase('Internal note');
    default:
      return convertToTitleCase(value);
  }
};

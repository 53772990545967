import { COLORS, gapMd, marginMd, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  gap: string;
  marginBottom: string;
  withoutBottomBorder: boolean;
  alignItems: string;
  color: string;
}

export const HorizontalLine = styled.div`
  height: 1rem;
  width: 100%;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const AppointmentTypeLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  margin-bottom: 30rem;
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${(props: StyledProps) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
    ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const SectionTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;

  ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const NoContentLabel = styled.label`
  margin-bottom: ${marginMd};
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${gapMd};
  align-self: center;
  margin-top: ${marginMd};
`;

import * as Yup from 'yup';

export const addWorkerSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(20, 'Name must not exceed 20 characters'),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters'),
  });
};

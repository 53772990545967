import { marginMd, paddingLg, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${paddingLg};

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${marginMd};
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export interface ILabel {
  id: number;
  name: string;
  color: string;
  group?: number;
  label_group_id?: number;
  description: string;
  index: number;
}

export interface ILabelDataDTO {
  entityId: number;
  entityTypeId: number;
  index: number;
  label: ILabel;
}

export interface ILabelData {
  entity_id: number;
  entity_type_id: number;
  index: number;
  label: ILabel;
}

export enum LabelGroupId {
  GENERIC = 1,
  TODO = 2,
  PRODUCTS = 3,
  SALES = 4,
  PURCHASE = 2,
}

export enum LabelEntityTypeId {
  QUOTATION = 1,
  SALES_ORDER = 2,
  SALES_INVOICE = 3,
  APPOINTMENT = 4,
  TODO = 5,
  PURCHASE_INVOICE = 6,
  PURCHASE_ORDER = 7,
}

import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { addWorker } from 'services/Workers/WorkersService';
import { queryClient } from 'index';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';

export interface IAddWorkerDTO {
  name: string;
  description: string;
}

export const useAddWorker = () => {
  const { t } = useTranslation();
  return useMutation((dto: IAddWorkerDTO) => addWorker(dto), {
    onSuccess: () => toast.success(t('Worker added successfully')),
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_WORKER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_WORKERS);
    },
    mutationKey: ReactMutationKeys.ADD_WORKER,
  });
};

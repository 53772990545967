import {
  CREATE_DOWN_PAYMENT_INVOICE,
  NEW_STATUS,
  ONGOING_STATUS,
  SalesOrderStatusType,
  subMenuItems,
  TO_ORDER,
} from './constants';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import SingleSalesOrderRouter from 'navigation/SingleSalesOrder/SingleSalesOrderRouter';
import {
  TitleContainer,
  ButtonContainer,
  PenImg,
  P,
  H3,
  Wrapper,
  LeftSide,
  Container,
  IconContainer,
  StatusContainer,
  Option,
  OptionWrapper,
  StatusOptionsContainer,
  SubstatusesContainer,
  ArrowImageWrapper,
  ClosedOptionWrapper,
  HeaderWrapper,
  RouterWrapper,
  LoaderWrapper,
  ButtonWrapper,
  LabelContainer,
  LabelWrapper,
  AddLabelDropdownWrapper,
  SpinnerWrapper,
} from './SingleSalesOrderPage.styled';
import Button from 'components/Button/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SignatureUploaded } from 'assets/icons/signature-uploaded.svg';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import { RoutesConfig } from 'navigation/routes';
import {
  useGetSingleSalesOrder,
  useChangeSalesOrderStatus,
  useExportSalesOrder,
  useGetSingleSalesOrderInfo,
  useGetSalesOrderLineAvailabilityAndNavigateToNewPurchaseOrder,
  useGetSalesOrderLatestEmailStatus,
} from './hooks';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import { useState, useEffect, useRef } from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { useGetSalesOrderStatuses } from 'utils/hooks/useGetSalesOrderStatuses';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { SingleSalesOrderCreatedModal } from './SingleSalesOrderCreatedModal/SingleSalesOrderCreatedModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import { EmailStatuses } from 'types/Email.types';
import { AppointmentType } from 'types/Appointment.types';
import Icon from 'components/Icon/Icon';
import { COLORS, marginSm, marginXs } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Spinner from 'components/Spinner/Spinner';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { ICreateAppointmentNavigationState } from '../PlanningPage/NewAppointmentModal/hooks';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import {
  Cardholder,
  CaretDown,
  CaretLeft,
  CaretUp,
  DownloadSimple,
  EnvelopeSimple,
  PencilSimple,
  Signature,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { ILabelDataDTO, LabelEntityTypeId } from 'types/EntityLabel.types';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import {
  useDeleteLabelFromEntity,
  useGetEntityLabels,
} from '../LabelsPage/hooks';
import { formatLabelsData } from '../LabelsPage/helpers';
import { useCalculateHeaderHeight } from 'utils/hooks/useCalculateHeaderHeight';

const SingleSalesOrderPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const substatusOptions = useGetSalesOrderStatuses(
    SalesOrderStatusType.SUBSTATUS
  );
  const options = useGetSalesOrderStatuses(SalesOrderStatusType.STATUS);
  const { data, isError: isErrorSalesOrder } = useGetSingleSalesOrder(id!);

  // Sales order email is sent to general address
  const emailDetails = {
    email: data?.customer_snapshot?.email,
    type: 'General',
  };

  const { data: emailStatus } = useGetSalesOrderLatestEmailStatus(id!);

  const [isStatusDropdownOpen, setIsStatusDropdownOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isOnGoingStatusModalOpen, setIsOnGoingStatusModalOpen] =
    useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState<boolean>(
    state?.createdFromQuotation ? true : false
  );
  // Down payment modal should not be shown after clicking on mail icon and sending mail,
  // but only after navigating from create quotation page and after sending mail
  const [
    isSendEmailModalOpenedAferCreatingFromQuotation,
    setIsSendEmailModalOpenedAferCreatingFromQuotation,
  ] = useState<boolean>(state?.createdFromQuotation ? true : false);
  const [showSubstatuses, setShowSubstatuses] = useState<boolean>(false);
  const [isCreateAppointmentModalOpen, setIsCreateAppointmentModalOpen] =
    useState<boolean>(false);

  const canEditSalesOrder = useCan(Actions.EDIT_SALES_ORDER);
  const canCreateAppointment = useCan(Actions.CREATE_APPOINTMENT);
  const canCreatePurchaseOrder = useCan(Actions.CREATE_PURCHASE_ORDER);
  const canCreateInvoice = useCan(Actions.CREATE_SALES_INVOICE);

  const allLinesInvoiced =
    data?.sales_order_lines &&
    data?.sales_order_lines?.every(
      (line: ISalesOrderLine) => line.invoiced === true
    );

  const { mutate: changeSalesOrderStatus, isSuccess } =
    useChangeSalesOrderStatus();

  useEffect(() => {
    setIsStatusDropdownOpen(false);
  }, [isSuccess]);

  const { mutate: exportSalesOrder, isLoading: isLoadingExport } =
    useExportSalesOrder();

  const statusContainerWrapperRef = useRef<HTMLDivElement>();
  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditSalesOrder && setIsStatusDropdownOpen(false)
  );

  const companySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const { data: infoData } = useGetSingleSalesOrderInfo(id!);
  const { data: labels, isLoading: isLoadingLabels } = useGetEntityLabels(
    id!,
    LabelEntityTypeId.SALES_ORDER
  );

  let formattedLabels: ILabelDataDTO[] = [];
  if (data) {
    formattedLabels = formatLabelsData(labels);
  }
  const { fetchAvailabilityAndNavigate, isLoadingLineAvailability } =
    useGetSalesOrderLineAvailabilityAndNavigateToNewPurchaseOrder(
      id!,
      data,
      navigate,
      labels
    );

  const { mutate: deleteLabelFromEntity } = useDeleteLabelFromEntity(
    LabelEntityTypeId.SALES_ORDER,
    Number(id)
  );

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet } = useBreakpointFlag();

  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  const { steps, showIntroductionGuide } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER,
    id,
    true,
    true
  );
  const { headerHeight, headerWrapperRef } = useCalculateHeaderHeight();

  return !isErrorSalesOrder ? (
    <Container className="single-sales-order-step-1">
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.sales_order_info)}
      />
      <Wrapper>
        <HeaderWrapper
          ref={headerWrapperRef}
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.SalesOrders.fullPath)}
              />
              <H3>
                {t('Sales Order')} {data?.number}
              </H3>
              <StatusContainer
                ref={statusContainerWrapperRef}
                className="single-sales-order-step-2"
              >
                <P
                  onClick={() => {
                    if (!canEditSalesOrder) return;
                    if (data?.status.name === ONGOING_STATUS)
                      setShowSubstatuses(true);
                    else setShowSubstatuses(false);
                    setIsStatusDropdownOpen(!isStatusDropdownOpen);
                  }}
                >
                  {`${capitalizeFirstLetter(t(data?.status?.name))}`}
                  {data?.sub_status
                    ? `- ${capitalizeFirstLetter(t(data?.sub_status?.name))}`
                    : null}
                </P>
                {canEditSalesOrder && (
                  <PenImg>
                    <Icon
                      onClick={() => {
                        if (data?.status.name === ONGOING_STATUS)
                          setShowSubstatuses(true);
                        else setShowSubstatuses(false);

                        setIsStatusDropdownOpen(!isStatusDropdownOpen);
                      }}
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                    />
                  </PenImg>
                )}
                {isStatusDropdownOpen && (
                  <StatusOptionsContainer>
                    {options.slice(0, -2).map((option) => {
                      return (
                        <OptionWrapper key={option.value}>
                          <Option
                            isSelected={option.value === data?.status?.name}
                            onClick={() => {
                              if (option.value === ONGOING_STATUS) {
                                changeSalesOrderStatus({
                                  id: data?.id || '',
                                  status_id: option.id,
                                  substatus_id: substatusOptions.find(
                                    (substatus) => {
                                      return data?.downpayment
                                        ? substatus.value ===
                                            CREATE_DOWN_PAYMENT_INVOICE
                                        : substatus.value === TO_ORDER;
                                    }
                                  )!.id,
                                });
                              } else {
                                changeSalesOrderStatus({
                                  id: data?.id || '',
                                  status_id: option.id,
                                });
                              }
                              if (
                                option.value === ONGOING_STATUS &&
                                data.status.name === NEW_STATUS
                              ) {
                                setIsOnGoingStatusModalOpen(true);
                              }
                            }}
                          >
                            {capitalizeFirstLetter(t(option.label))}
                          </Option>
                          {option.value === ONGOING_STATUS && (
                            <ArrowImageWrapper
                              onClick={() =>
                                setShowSubstatuses(!showSubstatuses)
                              }
                            >
                              <Icon
                                svg={showSubstatuses ? CaretUp : CaretDown}
                                color={COLORS.BLACK}
                                size={20}
                                weight="regular"
                              />
                            </ArrowImageWrapper>
                          )}
                        </OptionWrapper>
                      );
                    })}
                    <SubstatusesContainer isExpanded={showSubstatuses}>
                      {substatusOptions.map((option) => {
                        return (
                          <Option
                            isSubstatus={true}
                            isSelected={
                              option.id === data?.sub_status?.id &&
                              data?.status?.name === ONGOING_STATUS
                            }
                            key={option.value}
                            onClick={() => {
                              changeSalesOrderStatus({
                                id: data?.id || '',
                                status_id: options.find((option) => {
                                  return option.name === ONGOING_STATUS;
                                })!.id,
                                substatus_id: option.id,
                              });
                            }}
                          >
                            <p>{capitalizeFirstLetter(t(option.label))}</p>
                          </Option>
                        );
                      })}
                    </SubstatusesContainer>
                    {options && (
                      <>
                        {options.slice(-2).map((option) => {
                          return (
                            <ClosedOptionWrapper key={option.value}>
                              <Option
                                isSelected={option.value === data?.status?.name}
                                onClick={() => {
                                  changeSalesOrderStatus({
                                    id: data?.id || '',
                                    status_id: option?.id,
                                  });
                                }}
                              >
                                {capitalizeFirstLetter(t(option?.label))}
                              </Option>
                            </ClosedOptionWrapper>
                          );
                        })}
                      </>
                    )}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>

              <Tooltip
                content={t(data?.payment_status?.name)}
                key={'paymentKey'}
              >
                <Icon
                  svg={Cardholder}
                  size={25}
                  color={COLORS.PRIMARY}
                  marginLeft={marginSm}
                  marginRight={marginSm}
                  pointer={false}
                />
              </Tooltip>
            </LeftSide>

            <IconContainer className="single-sales-order-step-3">
              <Tooltip content={t('Signature')} key={'signatureKey'}>
                <Icon
                  data-testid="add-signature"
                  svg={data?.signature_uploaded ? SignatureUploaded : Signature}
                  color={COLORS.PRIMARY}
                  size={25}
                  marginRight={marginXs}
                  marginLeft={marginXs}
                  onClick={() =>
                    navigate(
                      SingleSalesOrderRoutes.SingleSalesOrderNewSignature.path
                    )
                  }
                />
              </Tooltip>
              <Tooltip content={t('Open PDF')} key={'exportKey'}>
                {isLoadingExport ? (
                  <LoaderWrapper>
                    <Spinner size={20} />
                  </LoaderWrapper>
                ) : (
                  <Icon
                    data-testid="download-pdf"
                    svg={DownloadSimple}
                    size={25}
                    color={COLORS.PRIMARY}
                    marginRight={marginXs}
                    marginLeft={marginXs}
                    onClick={() => exportSalesOrder(data?.id)}
                  />
                )}
              </Tooltip>
              <Tooltip content={t('Send mail')} key={'emailKey'}>
                <Icon
                  data-testid="send-email"
                  svg={
                    data?.sent_to_customer
                      ? emailStatus?.status === EmailStatuses.SENT
                        ? MailSent
                        : emailStatus?.status === EmailStatuses.PENDING
                        ? MailPending
                        : MailFailed
                      : EnvelopeSimple
                  }
                  size={25}
                  onClick={() => setIsAddEmailNoteModalOpen(true)}
                  color={COLORS.PRIMARY}
                  marginLeft={marginXs}
                  marginRight={marginXs}
                />
              </Tooltip>
            </IconContainer>
          </TitleContainer>

          <LabelContainer $noLabels={formattedLabels?.length === 0}>
            <LabelWrapper>
              {isLoadingLabels ? (
                <SpinnerWrapper>
                  <Spinner size={15} />
                </SpinnerWrapper>
              ) : (
                <>
                  {formattedLabels.map((labelObj: ILabelDataDTO) => {
                    return (
                      <EntityLabel
                        key={labelObj.label.id}
                        label={labelObj.label}
                        tooltipContent={
                          labelObj.label.name.length > 15
                            ? labelObj.label.name
                            : labelObj.label.description
                        }
                        onDelete={() => {
                          deleteLabelFromEntity(labelObj.label.id);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </LabelWrapper>
            <AddLabelDropdownWrapper
              $withoutMargin={formattedLabels?.length === 0}
            >
              <AddLabelDropdown
                entityType={LabelEntityTypeId.SALES_ORDER}
                entityId={id!}
              />
            </AddLabelDropdownWrapper>
          </LabelContainer>

          <ButtonContainer>
            <ButtonWrapper className="single-sales-order-step-4">
              <Button
                disabled={!data || !canCreateInvoice || allLinesInvoiced}
                onClick={() =>
                  navigate(
                    SingleSalesOrderRoutes.SingleSalesOrderNewInvoice.path,
                    {
                      state: {
                        prepopulateInvoiceData: data,
                      },
                    }
                  )
                }
                label={t('Create new invoice')}
                width="200rem"
                secondary
              />
              <Button
                onClick={() => setIsCreateAppointmentModalOpen(true)}
                label={t('Create appointment')}
                width="200rem"
                secondary
                disabled={!canCreateAppointment}
              />
              <Button
                onClick={() => fetchAvailabilityAndNavigate()}
                label={t('Create purchase order')}
                width="200rem"
                primary
                disabled={isLoadingLineAvailability || !canCreatePurchaseOrder}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper
          headerHeight={headerHeight}
          routerWithoutPaddingBottom={routerWithoutPaddingBottom}
        >
          <SingleSalesOrderRouter />
        </RouterWrapper>
      </Wrapper>
      <SingleSalesOrderCreatedModal
        shouldShowAllProductsDefinitiveSection={
          isSendEmailModalOpenedAferCreatingFromQuotation
        }
        isOpen={isSendEmailModalOpen}
        isLoadingExport={isLoadingExport}
        setIsOpen={(boolean) => {
          setIsSendEmailModalOpen(boolean);
          if (!boolean) {
            setIsSendEmailModalOpenedAferCreatingFromQuotation(false);
          }
        }}
        onCancel={() => {
          setIsSendEmailModalOpenedAferCreatingFromQuotation(false);
          setIsSendEmailModalOpen(false);
        }}
        onPDFClick={() => {
          exportSalesOrder(data?.id);
        }}
        onEmailClick={() => {
          setIsAddEmailNoteModalOpen(true);
        }}
      />
      <ThreeButtonsModal
        isOpen={isCreateAppointmentModalOpen}
        setIsOpen={setIsCreateAppointmentModalOpen}
        onCancel={() => {
          setIsCreateAppointmentModalOpen(false);
        }}
        firstButtonLabel={t('Measurement')}
        firstButtonDisabled={!data}
        onFirstButton={() => {
          const navigationState: ICreateAppointmentNavigationState = {
            openModal: true,
            type: AppointmentType.MEASURING,
            sales_order_id: id,
            sales_order_number: data?.number,
          };
          navigate(RoutesConfig.Planning.fullPath, {
            state: navigationState,
          });
          setIsCreateAppointmentModalOpen(false);
        }}
        secondButtonLabel={t('Service')}
        secondButtonDisabled={!data}
        onSecondButton={() => {
          const navigationState: ICreateAppointmentNavigationState = {
            openModal: true,
            type: AppointmentType.SERVICE,
            sales_order_id: id,
            sales_order_number: data?.number,
          };
          navigate(RoutesConfig.Planning.fullPath, {
            state: navigationState,
          });
          setIsCreateAppointmentModalOpen(false);
        }}
        thirdButtonlabel={t('Installation')}
        thirdButtonDisabled={!data}
        onThirdButton={() => {
          const navigationState: ICreateAppointmentNavigationState = {
            openModal: true,
            type: AppointmentType.INSTALLATION,
            sales_order_id: id,
            sales_order_number: data?.number,
          };
          navigate(RoutesConfig.Planning.fullPath, {
            state: navigationState,
          });
          setIsCreateAppointmentModalOpen(false);
        }}
        title={t('Create appointment')}
        description={t('Which appointment do you want to create') + '?'}
      />
      <AddEmailNoteModal
        note={
          data?.products_definitive &&
          companySettings?.sales_order_products_definitive_email_note
            ? companySettings.sales_order_products_definitive_email_note
            : ''
        }
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.id}
        type={AddEmailNoteModalType.SALES_ORDER}
        entityType={AddEmailNoteModalType.SALES_ORDER}
        emailDetails={emailDetails}
      />
      {showIntroductionGuide && <IntroductionGuide steps={steps} />}
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleSalesOrderPage;

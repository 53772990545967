import { queryClient } from 'index';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deleteSalesOrderFile,
  getSalesOrderBySalesOrderId,
  getSalesOrderComments,
  getSalesOrderFiles,
  getSalesOrderQuotationsBySalesOrderId,
  newComment,
  uploadSalesOrderFile,
  changeStatus,
  exportSalesOrder,
  sendSalesOrderEmail,
  getSalesOrderHistory,
  getSalesOrderInfoById,
  editDownPaymentAmount,
  uploadSalesOrderSignature,
  getSalesOrderSignatures,
  updateSalesOrderSignature,
  getSalesLinesAvailability,
  getSalesOrderLatestEmailStatus,
  getSalesOrderAttachmentsPreview,
  getDefaultAttachmentsForProducts,
} from 'services/SalesOrder/SalesOrderService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import { ISalesOrder } from 'types/SalesOrders.types';
import { NavigateFunction } from 'react-router-dom';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import { useEffect, useState } from 'react';
import { EmailStatuses } from 'types/Email.types';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';

export const useGetSingleSalesOrder = (orderId: string, isEnabled?: boolean) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_ORDER, orderId],
    queryFn: () => {
      return getSalesOrderBySalesOrderId(orderId);
    },
    enabled: isEnabled === undefined ? !!orderId : isEnabled,
  });

export const useGetSingleSalesOrderQuotations = (orderNumber: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_ORDER_QUOTATIONS, orderNumber],
    queryFn: () => {
      return getSalesOrderQuotationsBySalesOrderId(orderNumber);
    },
  });

export const useNewSalesOrderComment = (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
) =>
  useMutation(() => newComment(headline, description, isInternal, id), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_SALES_ORDER_COMMENT,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER_INFO);
      queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_ORDER_COMMENTS);
    },
    mutationKey: ReactMutationKeys.ADD_SALES_ORDER_COMMENT,
  });

export const useChangeSalesOrderStatus = () =>
  useMutation(
    (params: { id: string; status_id: string; substatus_id?: string }) =>
      changeStatus(params.id, params.status_id, params.substatus_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_SALES_ORDER_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_HISTORY
        );
      },
      mutationKey: ReactMutationKeys.CHANGE_SALES_ORDER_STATUS,
    }
  );

export const useGetSalesOrderComments = (
  perPage: number,
  salesOrderId: string,
  dateFrom: string,
  dateTo: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SALES_ORDER_COMMENTS,
      salesOrderId,
      dateFrom,
      dateTo,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getSalesOrderComments(salesOrderId, pageParam, perPage, dateFrom, dateTo),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useGetSalesOrderFiles = (perPage: number, salesOrderId: string) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_ORDER_FILES, salesOrderId],
    queryFn: ({ pageParam = 1 }) =>
      getSalesOrderFiles(salesOrderId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
  });

interface IUseDeleteSalesOrderFile {
  salesOrderId: string;
  salesOrderFileId: string;
}
export const useDeleteSalesOrderFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeleteSalesOrderFile) =>
      deleteSalesOrderFile(params.salesOrderId, params.salesOrderFileId),
    {
      onSuccess: (data) => {
        toast.success(t('File successfully deleted'));
        invalidateFileQueries();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_SALES_ORDER_FILE,
        });
      },
      mutationKey: ReactMutationKeys.DELETE_SALES_ORDER_FILE,
    }
  );
};

interface IUseUploadSalesOrderFileParams {
  files: any;
  salesOrderId: string;
}
export const useUploadSalesOrderFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadSalesOrderFileParams) => {
      const fileFormData = new FormData();
      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      return uploadSalesOrderFile(params.salesOrderId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_SALES_ORDER_FILE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_INFO
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_ORDER_FILES);
      },
      mutationKey: ReactMutationKeys.UPLOAD_SALES_ORDER_FILE,
    }
  );
};

export const useExportSalesOrder = () =>
  useMutation((salesOrderId: string) => exportSalesOrder(salesOrderId), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EXPORT_SALES_ORDER,
        className: ReactMutationKeys.EXPORT_SALES_ORDER,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.EXPORT_SALES_ORDER,
  });

interface ISendSalesOrderEmailDTO {
  note: string;
  files: any;
}

export const useSendSalesOrderEmail = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: ISendSalesOrderEmailDTO) =>
      sendSalesOrderEmail(salesOrderId, params.note, params.files),
    {
      onSuccess: () => {
        toast.success(t('E-mail is being sent'), { autoClose: 7000 });

        const salesOrderData: any = queryClient.getQueryData([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId.toString(),
        ]);

        if (salesOrderData?.sent_to_customer)
          handleEmailStatusFetching(salesOrderId);
        else handleEmailStatusFirstTimeFetching(salesOrderId);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SEND_SALES_ORDER_EMAIL,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SEND_SALES_ORDER_EMAIL,
    }
  );
};

const handleEmailStatusFetching = (salesOrderId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_EMAIL_STATUS,
      salesOrderId.toString(),
    ]);
    const salesOrderData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_ORDER,
      salesOrderId.toString(),
    ]);

    //Check if status has changed and stop further intervals
    if (
      statusData?.id !== salesOrderData?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      clearInterval(intervalId);
    } else
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_SALES_ORDER_EMAIL_STATUS,
      ]);

    intervalCount++;
  }, 7000);

  // Continue with the rest of the onSuccess logic
  queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER_HISTORY);
};

const handleEmailStatusFirstTimeFetching = (salesOrderId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_EMAIL_STATUS,
      salesOrderId.toString(),
    ]);
    const salesOrderData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_ORDER,
      salesOrderId.toString(),
    ]);

    //Check if status exists and stop further intervals
    if (
      salesOrderData?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      clearInterval(intervalId); // Stop further intervals
    } else {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_SALES_ORDER_EMAIL_STATUS,
      ]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
    }

    intervalCount++;
  }, 7000);

  // Continue with the rest of the onSuccess logic
  queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER_HISTORY);
};

export const useGetSingleSalesOrderHistory = (salesOrderId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_ORDER_HISTORY, salesOrderId],
    queryFn: () => {
      return getSalesOrderHistory(salesOrderId);
    },
    enabled: !!salesOrderId,
  });

export const useGetSingleSalesOrderInfo = (salesOrderId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_ORDER_INFO],
    queryFn: () => {
      return getSalesOrderInfoById(salesOrderId);
    },
  });

export const useChangeDownPaymentAmount = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: string; downpaymentAmount: string }) =>
      editDownPaymentAmount(params.id, params.downpaymentAmount),
    {
      onSuccess: (data) => {
        toast.success(t('Down payment edited'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER);
      },
      mutationKey: ReactMutationKeys.EDIT_DOWN_PAYMENT_AMOUNT,
    }
  );
};

interface IUseUploadSalesOrderSignatureParams {
  file: any;
  salesOrderId: string;
  full_name: string;
  date: string;
  appointmentId?: number;
  type: string;
}

export const useUploadSalesOrderSignature = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadSalesOrderSignatureParams) => {
      const fileFormData = new FormData();
      fileFormData.append('file', params.file);
      fileFormData.append('fullname', params.full_name);
      fileFormData.append('date', params.date);
      params.appointmentId &&
        fileFormData.append('appointment_id', params.appointmentId.toString());
      fileFormData.append('type_id', params.type);
      return uploadSalesOrderSignature(params.salesOrderId, fileFormData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Signature added'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_SIGNATURES,
          salesOrderId,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_SALES_ORDER_SIGNATURE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_SALES_ORDER_SIGNATURE,
    }
  );
};

export const useGetSingleSalesOrderSignatures = (salesOrderId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_ORDER_SIGNATURES, salesOrderId],
    queryFn: () => {
      return getSalesOrderSignatures(salesOrderId);
    },
  });

interface IUseUpdateSalesOrderSignatureParams {
  file: any;
  salesOrderId: string;
  full_name: string;
  date: string;
  appointmentId?: number;
  type: string;
}

export const useUpdateSalesOrderSignature = (
  salesOrderId: string,
  salesOrderSignatureId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUpdateSalesOrderSignatureParams) => {
      const fileFormData = new FormData();
      fileFormData.append('file', params.file);
      fileFormData.append('fullname', params.full_name);
      fileFormData.append('date', params.date);
      params.appointmentId &&
        fileFormData.append('appointment_id', params.appointmentId.toString());
      fileFormData.append('type_id', params.type);
      return updateSalesOrderSignature(
        params.salesOrderId,
        salesOrderSignatureId,
        fileFormData
      );
    },
    {
      onSuccess: (data) => {
        toast.success(t('Signature updated'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_SIGNATURES,
          salesOrderId,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPDATE_SALES_ORDER_SIGNATURE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.UPDATE_SALES_ORDER_SIGNATURE,
    }
  );
};

export const useGetSalesLinesAvailability = (
  salesOrderId: string,
  shouldFetchLineAvailability: boolean
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SALES_LINES_AVAILABILITY, salesOrderId],
    queryFn: () => {
      return getSalesLinesAvailability(salesOrderId);
    },
    enabled: shouldFetchLineAvailability,
  });

export const useGetSalesOrderLineAvailabilityAndNavigateToNewPurchaseOrder = (
  salesOrderId: string,
  salesOrder: ISalesOrder,
  navigate: NavigateFunction,
  labelsData: any[]
) => {
  const [
    shouldFetchLineAvailabilityAndNavigate,
    setShouldFetchLineAvailabilityAndNavigate,
  ] = useState<boolean>(false);
  const {
    data: salesOrderLinesAvailability,
    isLoading: isLoadingLineAvailability,
    isSuccess: isSuccessLineAvailability,
  } = useGetSalesLinesAvailability(
    salesOrderId,
    shouldFetchLineAvailabilityAndNavigate
  );

  const fetchAvailabilityAndNavigate = () => {
    queryClient.resetQueries([ReactQueryKeys.GET_SALES_LINES_AVAILABILITY]);
    setShouldFetchLineAvailabilityAndNavigate(true);
  };

  useEffect(() => {
    if (isSuccessLineAvailability && shouldFetchLineAvailabilityAndNavigate) {
      setShouldFetchLineAvailabilityAndNavigate(false);
      const prepopulatePurchaseOrderFormData: ISalesOrder = {
        ...salesOrder,
        sales_order_lines: salesOrderLinesAvailability.sales_order_lines,
        labels: labelsData.map((labelData) => labelData.label),
      };
      navigate(SingleSalesOrderRoutes.SingleSalesOrderNewPurchaseOrder.path, {
        state: {
          prepopulatePurchaseOrderData: prepopulatePurchaseOrderFormData,
        },
      });
    }
  }, [isSuccessLineAvailability, shouldFetchLineAvailabilityAndNavigate]);

  return { fetchAvailabilityAndNavigate, isLoadingLineAvailability };
};

export const useGetSalesOrderLatestEmailStatus = (salesOrderId: string) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_EMAIL_STATUS,
      salesOrderId,
    ],
    queryFn: () => {
      return getSalesOrderLatestEmailStatus(salesOrderId);
    },
  });

export const useGetSalesOrderAttachmentsPreview = (
  salesOrderId: string,
  type: AddEmailNoteModalType,
  entityType?: AddEmailNoteModalType
) => {
  const isEnabled =
    !!salesOrderId &&
    type === AddEmailNoteModalType.SALES_ORDER &&
    (entityType === AddEmailNoteModalType.SALES_ORDER ||
      entityType === undefined);

  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_SALES_ORDER_ATTACHMENTS_PREVIEW,
      salesOrderId,
    ],
    queryFn: () => {
      return getSalesOrderAttachmentsPreview(salesOrderId);
    },
    enabled: isEnabled,
  });
};

// Unused
export const useGetDefaultAttachmentsForProducts = (
  productIds: string[],
  id: string,
  type: AddEmailNoteModalType
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_DEFAULT_ATTACHMENTS_FOR_PRODUCTS, id],
    queryFn: () => {
      return getDefaultAttachmentsForProducts(productIds);
    },
    enabled:
      !!productIds?.length &&
      type === AddEmailNoteModalType.INSTALLATION_CREATION,
  });

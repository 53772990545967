import {
  Container,
  TabContainer,
  Tab,
  CreateNewSalesOrderLabel,
} from './NewSalesOrderPage.styled';
import { useState } from 'react';
import {
  CreateSalesOrderTab,
  ICreateSalesOrderProductFormDTO,
} from './constants';
import {
  useAddNavigationCheckAfterProductIsAdded,
  useCreateSalesOrder,
} from './hooks';
import { ICustomer } from 'types/Customer.types';
import {
  getInitialCustomerDetailsData,
  getInitialOrderDetailsData,
  generateExistingCustomerDetails,
  generateQuickSalesOrderCustomerDetails,
  calculateInitialDownpayment,
} from './helpers';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { ICountry } from 'store/Common/types';
import { useTranslation } from 'react-i18next';
import { isNumeric } from 'utils/validation';
import { ICompanySettings } from 'types/Company.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { LeavePageModal } from 'components/Modal/LeavePageModal/LeavePageModal';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';
import {
  ICreateSalesOrderLineDTO,
  ICreateSalesOrderPayload,
  ICreateSalesOrderQueryParamsForCustomer,
} from 'types/SalesOrders.types';
import CustomerTab from '../NewQuotationPage/CustomerTab/CustomerTab';
import ProductTab from '../NewQuotationPage/ProductTab/ProductTab';
import OrderTab from '../NewQuotationPage/OrderTab/OrderTab';
import OverviewTab from '../NewQuotationPage/OverviewTab/OverviewTab';
import { calculateSalesPriceWithDiscount } from '../NewQuotationPage/OrderTab/helpers';
import { ICreateQuotationCustomerDetails } from '../NewQuotationPage/CustomerTab/constants';
import { ICreateQuotationProductFormDTO } from '../NewQuotationPage/ProductTab/constants';
import { ICreateQuotationOrderDetails } from '../NewQuotationPage/OrderTab/constants';
import { ILabel } from 'types/EntityLabel.types';

const NewSalesOrderPage = () => {
  const { t } = useTranslation();

  const [queryParamsForCreatingCustomer, setQueryParamsForCreatingCustomer] =
    useState<ICreateSalesOrderQueryParamsForCustomer>({
      force_create_customer: false,
    });

  const [activeTab, setActiveTab] = useState<string>(
    CreateSalesOrderTab.CUSTOMER_DETAILS
  );

  const initialCountry: ICountry = useGetInitialCountry();

  const [customerDetails, setCustomerDetails] =
    useState<ICreateQuotationCustomerDetails>(
      getInitialCustomerDetailsData(initialCountry?.cca2)
    );

  const [productFormDtos, setProductFormDtos] = useState<
    ICreateQuotationProductFormDTO[]
  >([]);

  const companySettings: ICompanySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const [orderDetails, setOrderDetails] =
    useState<ICreateQuotationOrderDetails>(
      getInitialOrderDetailsData(companySettings)
    );

  useSocketConnection();

  const { mutate: createSalesOrder, data, status } = useCreateSalesOrder();

  const {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  } = useAddNavigationCheckAfterProductIsAdded(productFormDtos, status);

  const handleCreateSalesOrder = () => {
    const address = {
      country: customerDetails.country,
      street: customerDetails.street,
      zip_code: customerDetails.code,
      city: customerDetails.city,
      house_number: customerDetails.house_number,
    };
    const customer = {
      name: customerDetails.name,
      last_name: customerDetails.last_name,
      phone: customerDetails.phone,
      email: customerDetails.email,
      address: address,
      gender: customerDetails.gender,
      title: customerDetails.title,
      company_name: customerDetails.company_name,
      vat_number: customerDetails.vat_number,
    };

    const salesOrderLines: ICreateSalesOrderLineDTO[] = productFormDtos.map(
      (productFormDto: ICreateSalesOrderProductFormDTO) => {
        const productAttributes = {};
        for (const attributeKey of Object.keys(productFormDto.attributes)) {
          productAttributes[attributeKey] =
            productFormDto.attributes[attributeKey];
        }

        return {
          ...(!productFormDto.isStockItem && {
            product_id: productFormDto.product.default_product_id
              ? productFormDto.product.default_product_id
              : productFormDto.product.id,
          }),
          quantity: Number(productFormDto.quantity),
          product_attributes: productAttributes,
          description: productFormDto.description,
          ...(productFormDto.logyxConfigurationIntent && {
            logyx_configuration_intent_uuid:
              productFormDto.logyxConfigurationIntent.uuid,
          }),
          placement: productFormDto.placement,
          discount: {
            type: productFormDto.discountType,
            amount: +productFormDto.discount,
          },
          supplier_id: productFormDto.supplier.id,
          ...(isNumeric(productFormDto.purchasePrice) && {
            product_purchase_price: Number(productFormDto.purchasePrice),
          }),
          product_sales_price: Number(productFormDto.salesPrice),
          is_stock_item: productFormDto.isStockItem,
          stock_item_id: productFormDto.stockItemId,
        };
      }
    );
    const workingHourLine: ICreateSalesOrderLineDTO = {
      quantity: Number(orderDetails.workingHours),
      working_hours_line: true,
      working_hours_rate: orderDetails.workingHoursRate.toString(),
      product_attributes: {},
      discount: {
        type: orderDetails.workingHoursDiscountType,
        amount: +orderDetails.workingHoursDiscount,
      },
    };
    if (
      Number(workingHourLine.quantity) !== 0 &&
      Number(workingHourLine.working_hours_rate) !== 0
    ) {
      salesOrderLines.push(workingHourLine);
    }

    const createSalesOrderPayload: ICreateSalesOrderPayload = {
      description: 'Sales order description',
      payment_condition: orderDetails.paymentCondition || '',
      reference: orderDetails.reference,
      sales_order_lines: salesOrderLines,
      discount: {
        type: orderDetails.discountType,
        amount: +orderDetails.discount,
      },
      customer: customer,
      ...(customerDetails.existingCustomer?.id && {
        // customer_id is sent when an existing customer is selected
        customer_id: customerDetails.existingCustomer.id.toString(),
      }),
      shipping_data: {
        first_name: customer.name,
        last_name: customer.last_name,
        phone: customer.phone,
        country: customer.address.country,
        street: customer.address.street,
        city: customer.address.city,
        zip_code: customer.address.zip_code,
        house_number: customer.address.house_number,
        company_name: customer.company_name,
        email: customer.email,
        title: customer.title,
      },
    };

    if (orderDetails.downPayment && +orderDetails.downPayment != 0)
      createSalesOrderPayload.downpayment = +orderDetails.downPayment;

    if (customerDetails.shipping_data)
      createSalesOrderPayload.shipping_data = customerDetails.shipping_data;

    if (orderDetails.labels.length !== 0)
      createSalesOrderPayload.label_ids = orderDetails.labels.map(
        (label: ILabel) => label.id
      );

    createSalesOrder({
      payload: createSalesOrderPayload,
      queryParams: queryParamsForCreatingCustomer,
    });
  };

  const handleActiveTab = () => {
    if (activeTab === CreateSalesOrderTab.CUSTOMER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1. {t('Customer details')}</Tab>
          <Tab>2. {t('Products')}</Tab>
          <Tab>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateSalesOrderTab.PRODUCTS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab active>2. {t('Products')}</Tab>
          <Tab>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateSalesOrderTab.ORDER_DETAILS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab finished>2. {t('Products')}</Tab>
          <Tab active>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab finished>2. {t('Products')}</Tab>
          <Tab finished>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    }
  };

  return (
    <Container>
      <CreateNewSalesOrderLabel>
        {t('Create sales order')}
      </CreateNewSalesOrderLabel>
      {handleActiveTab()}
      {activeTab === CreateSalesOrderTab.CUSTOMER_DETAILS && (
        <CustomerTab
          entityType={'salesOrder'}
          onForward={(
            customerQueryParams: ICreateSalesOrderQueryParamsForCustomer
          ) => {
            setQueryParamsForCreatingCustomer(customerQueryParams);
            setActiveTab(CreateSalesOrderTab.PRODUCTS);
          }}
          customerDetails={customerDetails}
          setCustomerDetails={setCustomerDetails}
          handlePopulateNewQuotationCustomerTab={(customer: ICustomer) =>
            customer
              ? setCustomerDetails(
                  generateExistingCustomerDetails(
                    customer,
                    !!customerDetails.isShippingDataFormShown
                  )
                )
              : setCustomerDetails(
                  generateQuickSalesOrderCustomerDetails(
                    !!customerDetails.isShippingDataFormShown
                  )
                )
          }
          resetCustomerTabForm={() =>
            setCustomerDetails(
              getInitialCustomerDetailsData(initialCountry?.cca2)
            )
          }
        />
      )}
      {activeTab === CreateSalesOrderTab.PRODUCTS && (
        <ProductTab
          onBack={() => setActiveTab(CreateSalesOrderTab.CUSTOMER_DETAILS)}
          onForward={(
            productFormDtosData: ICreateSalesOrderProductFormDTO[]
          ) => {
            const initialTotalSalesPrice = calculateSalesPriceWithDiscount(
              orderDetails,
              productFormDtosData
            );
            const initialDownpayment = calculateInitialDownpayment(
              initialTotalSalesPrice,
              companySettings
            ).toFixed(2);
            setOrderDetails({
              ...orderDetails,
              downPayment: initialDownpayment?.toString(),
            });
            setActiveTab(CreateSalesOrderTab.ORDER_DETAILS);
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
        />
      )}
      {activeTab === CreateSalesOrderTab.ORDER_DETAILS && (
        <OrderTab
          onBack={() => setActiveTab(CreateSalesOrderTab.PRODUCTS)}
          onForward={() => setActiveTab(CreateSalesOrderTab.OVERVIEW)}
          setOrderDetails={setOrderDetails}
          orderDetails={orderDetails}
          productFormDtos={productFormDtos}
          entityType={'salesOrder'}
        />
      )}
      {activeTab === CreateSalesOrderTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(CreateSalesOrderTab.ORDER_DETAILS)}
          onForward={() => handleCreateSalesOrder()}
          customerDetails={customerDetails}
          productFormDtos={productFormDtos}
          orderDetails={orderDetails}
          status={status}
          entityType={'salesOrder'}
          newlyCreatedQuotationId={data?.sale?.id}
        />
      )}

      <LeavePageModal
        isOpen={showPrompt}
        setIsOpen={setCheckNavigation}
        onYes={() => confirmNavigation()}
        onNo={() => cancelNavigation()}
        title={''}
        description={t(
          'Are you sure you want to leave the sales order creation wizard? This will discard the progress.'
        )}
      />
    </Container>
  );
};

export default NewSalesOrderPage;

import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginMd, marginSm } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const AddMoreFieldsLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 420rem;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const RowMarginBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${marginSm};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const SelectWrapper = styled.div`
  width: 200rem;
`;

export const DeleteProductFieldIcon = styled.div`
  position: absolute;
  cursor: pointer;
  margin-left: ${marginSm};
  width: 20rem;
  height: 20rem;
  right: -8rem;
  top: -5rem;
  background-color: ${COLORS.WHITE};
  border-radius: 5rem;
`;

export const FieldNamesWrapper = styled.div`
  margin-top: ${marginMd};
  min-height: 60rem;
  max-height: 50vh;
  overflow-y: auto;
  padding: 10rem 20rem;
`;

export const Row = styled.div`
  width: 100%;
`;

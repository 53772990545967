import moment from 'moment';
import { AppointmentStatus, IAppointment } from 'types/Appointment.types';
import { IReport, IReportTableDTO } from 'types/Report.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export interface IAppointmentTableDTO {
  status: string;
  type: string;
  dateAndTime: string;
  location: string;
  customer: string;
  navigationID: number;
  customerNumber: string;
}

export const formatReportTableData = (reportData: IReport[]) => {
  return reportData?.map((report: IReport) => {
    const reportTableDTO: IReportTableDTO = {
      type: capitalizeFirstLetter(report.appointment.type.name),
      date: moment(report.created_at).format('DD-MM-YYYY'),
      address: report.appointment.address.full_address,
      user: `${report.submitted_by.name} ${report.submitted_by.last_name}`,
      entity: report,
    };
    return reportTableDTO;
  });
};

export const formatAppointmentTableData = (appointmentData: IAppointment[]) => {
  return appointmentData?.map((appointment: IAppointment) => {
    const appointmentTableDTO: IAppointmentTableDTO = {
      status: appointment?.is_failed
        ? AppointmentStatus.FAILED
        : appointment?.status.name,
      type: capitalizeFirstLetter(appointment?.type.name),
      dateAndTime: `${moment
        .utc(appointment?.date_from)
        .tz(moment.tz.guess())
        .format('DD-MM-YYYY HH:mm')}-${moment
        .utc(appointment?.date_to)
        .tz(moment.tz.guess())
        .format('HH:mm')}`,
      location: appointment?.address?.full_address || '',
      customer: appointment?.customer
        ? `${appointment?.customer?.name} ${appointment?.customer?.last_name}`
        : '',
      navigationID: appointment?.id,
      customerNumber: appointment?.customer?.number || '',
    };
    return appointmentTableDTO;
  });
};

import { ILabel } from 'types/EntityLabel.types';
import Chip from '@mui/material/Chip';
import { getContrastingLabelColor } from './helpers';
import Tooltip from 'components/Tooltip/Tooltip';

interface IEntityLabelProps {
  label: ILabel;
  onDelete?: () => void;
  onClick?: () => void;
  maxWidth?: string;
  tooltipContent?: string;
}

export const EntityLabel = ({
  label,
  onDelete,
  onClick,
  maxWidth,
  tooltipContent,
}: IEntityLabelProps) => {
  const contrastColor = getContrastingLabelColor(label.color);

  return (
    <Tooltip content={tooltipContent || label.description}>
      <Chip
        size="small"
        sx={{
          backgroundColor: label.color,
          color: contrastColor,
          '& .MuiChip-label': {
            maxWidth: maxWidth || '300rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '300',
          },
          '& .MuiChip-deleteIcon': {
            color: contrastColor,
            opacity: 0.7,
            '&:hover': {
              opacity: 1,
              color: contrastColor + 50,
            },
          },
        }}
        label={label.name}
        onDelete={onDelete}
        onClick={onClick}
      />
    </Tooltip>
  );
};

import { AxiosError } from 'axios';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  addSavedFilters,
  deleteSavedFilters,
  getSavedFilters,
} from 'services/AdvancedFilters/AdvancedFiltersService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';
import { IAddSavedFiltersDTO } from './types';
import { getCustomers } from 'services/Customer/CustomerService';

export const useGetConditionOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('Is'), value: 'eq' },
    { label: t('Is not'), value: 'ne' },
    { label: t('Contains'), value: 'like' },
    { label: t('Starts with'), value: 'sw' },
    { label: t('Ends with'), value: 'ew' },
    { label: t('Is larger than'), value: 'gt' },
    { label: t('Is smaller than'), value: 'lt' },
  ];
};

export const useGetPreConditionOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('And'), value: 'and' },
    { label: t('Or'), value: 'or' },
  ];
};

export const useGetSavedFilters = (page: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SAVED_ADV_FILTERS, page],
    queryFn: () => {
      return getSavedFilters(page);
    },
  });

export const useAddSavedFilters = (page: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IAddSavedFiltersDTO) =>
      addSavedFilters(page, params.base64, params.name),
    {
      onSuccess: () => {
        toast.success(t('New filter is saved'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_SAVED_ADV_FILTERS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SAVED_ADV_FILTERS);
      },
      mutationKey: ReactMutationKeys.ADD_SAVED_ADV_FILTERS,
    }
  );
};

export const useDeleteSavedFilters = () => {
  const { t } = useTranslation();

  return useMutation((id: number | string) => deleteSavedFilters(id), {
    onSuccess: () => {
      toast.success(t('Filter is successfully deleted'));
    },
    onError: (error: AxiosError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_SAVED_ADV_FILTERS,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SAVED_ADV_FILTERS);
    },
    mutationKey: ReactMutationKeys.DELETE_SAVED_ADV_FILTERS,
  });
};

export const useGetCustomersInfinite = (
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_CUSTOMERS, perPage, searchBy, isActive],
    queryFn: ({ pageParam = 1 }) =>
      getCustomers(
        pageParam,
        perPage,
        searchBy,
        isActive,
        undefined,
        undefined
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginMd, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isBlueColor: boolean;
  isPaginationTR: boolean;
  isPaginationPresent: boolean;
  $withoutPagination: boolean;
  isClickable: boolean;
  flexValue: number;
  extraActionsFlexValue: number;
  $bigCell: true;
  labelsRow: boolean;
  $withoutMarginTop?: boolean;
  isHovered?: boolean;
}

export const TableResponsive = styled.div`
  -webkit-overflow-scrolling: touch;
  position: relative;

  ${respondTo.bigTablet`
    overflow-x: auto;
  `}
`;

export const CustomTable = styled.table`
  margin-top: ${marginMd};
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;

  ${(props: StyledProps) =>
    props.$withoutMarginTop &&
    css`
      margin-top: 0;
    `}

  ${(props: StyledProps) =>
    props.$withoutPagination &&
    css`
      margin-top: 0;
      thead tr th {
        position: sticky;
        top: 0;
      }
    `}

  th {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
    font-weight: 300;
    display: flex;
    align-items: center;

    label {
      color: ${COLORS.WHITE};
    }
  }
  th:first-child {
    border-top-left-radius: ${borderRadiusSm};
    flex: 0;
  }
  th:last-child {
    border-top-right-radius: ${borderRadiusSm};
    flex: 0;
  }

  th,
  td {
    min-width: 0;
    padding: 20rem 10rem;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    transition: ease 200ms;
  }
  td {
    background-color: ${COLORS.WHITE};
  }

  ${respondTo.bigTablet`
    th, td label {
      line-height: 1.3;
      font-size: 18rem;
    }

    th,
    td {
      min-width: 150rem;
      padding: 20rem 5rem;
    }
    th {
      padding: 10rem 5rem;
    }
    td:first-child,
    th:first-child {
      min-width: 0;
    }
    td:last-child,
    th:last-child {
      min-width: 0;
    }
  `}

  td:first-child {
    flex: 0;
    border: none;
    width: 30rem;
  }
  td:last-child {
    flex: 0;
    border: none;
    width: 30rem;
  }

  tr:not(:last-child) {
    td {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  tr:last-child {
    border-color: transparent;
    td {
      flex: 1;
      padding: 10rem;
    }
    td:first-child {
      border-bottom-left-radius: ${borderRadiusSm};
      flex: 0;
    }
    td:last-child {
      border-bottom-right-radius: ${borderRadiusSm};
      flex: 0;
    }
  }
`;

export const TableRow = styled.tr`
  td {
    background-color: ${(props: StyledProps) =>
      props.isHovered ? COLORS.GREY_200 : COLORS.WHITE};
  }
`;

export const TD = styled.td`
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};

  border-bottom: 1px solid ${COLORS.GREY_300};

  ${(props: StyledProps) =>
    props.labelsRow &&
    css`
      border: none;
    `};
`;

export const TDLabels = styled.td`
  display: flex;
  width: 100% !important;
  max-width: 100%;
  justify-content: flex-start !important;
  gap: 10rem !important;
  padding: 0 30rem 20rem !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 50rem);
    background-color: ${COLORS.GREY_300};
    height: 1px;
  }
`;

export const PaginationTR = styled.tr`
  height: 84rem;
  pointer-events: none;
  user-select: none;
  ${(props: StyledProps) =>
    !props.isPaginationPresent &&
    css`
      height: 50rem;
    `}
`;

export const TdLabel = styled.label`
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  user-select: none;
  position: relative;
  word-break: normal;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isBlueColor &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
    `}
`;

export const ThLabel = styled.label`
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  position: relative;
  word-break: normal;
`;

export const ThWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ThIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: 15rem;

  ${respondTo.bigTablet`
    margin-top: 10rem;
  `}
`;

export const Th = styled.th`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `}

  ${(props: StyledProps) =>
    props.$bigCell &&
    css`
      justify-content: space-between;
    `}
`;

export const ExtraActionsHeader = styled.th`
  ${(props: StyledProps) =>
    props.extraActionsFlexValue &&
    css`
      flex: ${props.extraActionsFlexValue};
    `}

  ${respondTo.bigTablet`
    min-width: 100rem !important;
  `}
`;

export const ExtraActionsHeaderInvisibleContentWrapper = styled.div`
  opacity: 0;
  user-select: none;
  pointer-events: none;
  display: flex;
`;

export const ExtraActionsTableData = styled.td`
  display: flex;
  justify-content: space-evenly;
  gap: 10rem;
  ${(props: StyledProps) =>
    props.extraActionsFlexValue &&
    css`
      flex: ${props.extraActionsFlexValue};
    `}

  ${respondTo.bigTablet`
    min-width: 100rem !important;
  `}

   border-bottom: 1px solid ${COLORS.GREY_300};

  ${(props: StyledProps) =>
    props.labelsRow &&
    css`
      border: none;
    `};
`;

const swipe = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20rem);
  }
  100% {
    transform: translateX(0);
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 30rem;
  bottom: 15rem;
  animation: ${swipe} 2s infinite;
`;

export const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10rem;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  background-color: ${COLORS.WHITE};
  padding-bottom: ${paddingSm};
`;

import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addCommentForToDo,
  getCommentsForToDo,
} from 'services/ToDo/ToDoService';

export const useGetCommentsForToDo = (
  todoId: string,
  page?: number,
  perPage?: number,
  isEnabled?: boolean
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_TODO_COMMENTS, todoId, page, perPage],
    queryFn: () => {
      return getCommentsForToDo(todoId, page, perPage);
    },
    enabled: isEnabled === undefined ? !!todoId : isEnabled,
  });

interface IComment {
  headline: string;
  description: string;
  is_internal: boolean;
}

export const useAddToDoComment = (todoId: string) =>
  useMutation((commentDTO: IComment) => addCommentForToDo(todoId, commentDTO), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_TODO_COMMENT,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_TODO_COMMENTS);
    },
    mutationKey: ReactMutationKeys.ADD_TODO_COMMENT,
  });

import { useQuery, useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getResources,
  deleteResource,
  editResource,
} from 'services/Resources/ResourcesService';
import { queryClient } from 'index';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IEditResourceDTO } from './type';
import { useTranslation } from 'react-i18next';

export const useGetResources = (
  page?: number,
  perPage?: number,
  searchBy?: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_RESOURCES,
      page,
      perPage,
      searchBy,
      dateFrom,
      dateTo,
    ],
    queryFn: () => {
      return getResources(page, perPage, searchBy, dateFrom, dateTo);
    },
    enabled: isEnabled,
  });

export const useDeleteResource = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteResource(id), {
    onSuccess: () => toast.success(t('Resource deleted successfully')),
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_RESOURCE,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_RESOURCES);
    },
    mutationKey: ReactMutationKeys.DELETE_RESOURCE,
  });
};

export const useEditResource = (id: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editResourceData: IEditResourceDTO) => editResource(id, editResourceData),
    {
      onSuccess: () => toast.success(t('Resource edited successfully')),
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_RESOURCE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_RESOURCES);
      },
      mutationKey: ReactMutationKeys.EDIT_RESOURCE,
    }
  );
};

import Icon from 'components/Icon/Icon';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import {
  ButtonWrapper,
  LabelItemsWrapper,
  LabelSpan,
  LabelWrapper,
  Menu,
  SearchContainer,
  StyledDropdown,
} from './AddLabelDropdown.styled';
import { CaretDown, MagnifyingGlass } from '@phosphor-icons/react';
import Input from 'components/Input/Input';
import { COLORS } from 'assets/styled';
import { LabelDropdownItem } from './LabelDropdownItem/LabelDropdownItem';
import { ILabel, LabelEntityTypeId } from 'types/EntityLabel.types';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import {
  useAddLabelToEntity,
  useGetLabelsInfinite,
} from 'pages/Manager/LabelsPage/hooks';
import { LABELS_PER_PAGE } from 'pages/Manager/LabelsPage/constants';
import { useDebounce } from 'utils/hooks/useDebounce';

interface IProps {
  entityType: LabelEntityTypeId;
  entityId?: string;
  onClick?: (newLabel: ILabel) => void;
  inModal?: boolean;
}

export const AddLabelDropdown = ({
  entityType,
  entityId,
  onClick,
  inModal,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [openDropDownMenu, setOpenDropDownMenu] = useState<boolean>(false);
  const dropdownButtonRef = useRef();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const { mutate: addLabelToEntity } = useAddLabelToEntity(
    entityType,
    Number(entityId)
  );

  useOutsideAlerter(dropdownButtonRef, () => setOpenDropDownMenu(false));

  const {
    data: labelsData,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingGetLabels,
  } = useGetLabelsInfinite(LABELS_PER_PAGE, debouncedSearchBy, entityType);

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoadingGetLabels);

  const labels: ILabel[] = useMemo(() => {
    return (labelsData?.pages || []).map((page) => page.labels).flat();
  }, [labelsData]);

  return (
    <StyledDropdown ref={dropdownButtonRef}>
      <LabelWrapper>
        <LabelSpan onClick={() => setOpenDropDownMenu((prev) => !prev)}>
          {t('Add Label')}
        </LabelSpan>
        <Icon
          svg={CaretDown}
          size={15}
          color={COLORS.PRIMARY}
          weight="regular"
          onClick={() => setOpenDropDownMenu((prev) => !prev)}
        />
      </LabelWrapper>

      {openDropDownMenu && (
        <Menu $inModal={inModal}>
          <SearchContainer>
            <Input
              icon={MagnifyingGlass}
              width={'100%'}
              height={'30rem'}
              placeholder={t('Search')}
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              style={{ fontSize: '14rem' }}
              wrapperStyles={{ width: '100%' }}
            />
          </SearchContainer>
          <LabelItemsWrapper>
            {labels.map((label: ILabel) => {
              return (
                <LabelDropdownItem
                  onClick={() => {
                    if (onClick) onClick(label);
                    else addLabelToEntity(label.id);
                  }}
                  key={label.id}
                  label={label}
                />
              );
            })}
            <div
              ref={loadMoreRef}
              style={{
                height: '1px',
              }}
            />
          </LabelItemsWrapper>
          <ButtonWrapper>
            <Button
              width={'200rem'}
              height={'30rem'}
              onClick={() => navigate(RoutesConfig.Labels.fullPath)}
              label={t('Manage labels')}
              secondary
            />
          </ButtonWrapper>
        </Menu>
      )}
    </StyledDropdown>
  );
};

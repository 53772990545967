import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getSalesOrders,
  getSalesOrdersSummary,
} from 'services/SalesOrder/SalesOrderService';

export const useGetSalesOrdersInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean,
  sortBy?: string,
  sortDirection?: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_ORDERS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getSalesOrders(
        pageParam,
        perPage,
        undefined,
        undefined,
        undefined,
        searchBy,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetSalesOrdersSummaryInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_ORDERS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getSalesOrdersSummary(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetSalesOrdersOptions = (
  salesOrdersData: InfiniteData<any> | undefined
) => {
  const salesOrdersOptions = useMemo(() => {
    if (salesOrdersData?.pages?.length) {
      return salesOrdersData.pages
        .map((page: any) => page.sales_orders)
        .flat()
        .map((sales_order: any) => {
          return {
            value: sales_order.id,
            label: `${sales_order.number}`,
          };
        });
    }
    return [];
  }, [salesOrdersData]);
  return salesOrdersOptions;
};

import { Danger } from 'components/Modal/Danger/Danger';
import { Modal } from 'components/Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableConfig } from './constants';
import { formatTableData } from './helpers';
import {
  useCreateMandate,
  useDeleteMandate,
  useEditMandate,
  useSetPreferredMandate,
} from './hooks';
import {
  Heading,
  RowSpaceBetween,
  TableWrapper,
} from './MollieMandates.styled';
import { MollieMandatesTable } from './MollieMandatesTable/MollieMandatesTable';
import { IMandate, IMollieCustomer } from './types';
import Button from 'components/Button/Button';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';

interface IMollieMandatesProps {
  customerId: number;
  mollieCustomer: IMollieCustomer;
  mandates: IMandate[];
}
export const MollieMandates = ({
  customerId,
  mollieCustomer,
  mandates,
}: IMollieMandatesProps) => {
  const { t } = useTranslation();
  const [isCreateMandateModalOpen, setIsCreateMandateModalOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [mandateToDelete, setMandateToDelete] = useState<string>();
  const { mutate: setPreferredMandate } = useSetPreferredMandate(
    mollieCustomer?.id
  );
  const { mutate: deleteMandate } = useDeleteMandate();
  const { mutate: editMandate } = useEditMandate();
  const { mutate: createMandate } = useCreateMandate();

  return (
    <div>
      <RowSpaceBetween>
        <Heading>{t('Mollie mandates')}</Heading>
        <Button
          label={t('Create new mandate')}
          secondary
          onClick={() => setIsCreateMandateModalOpen(true)}
        />
      </RowSpaceBetween>
      <TableWrapper>
        <MollieMandatesTable
          tableData={formatTableData(mandates)}
          tableConfig={tableConfig}
          primaryMandate={mollieCustomer?.primary_mandate}
          onButtonClick={(rowData: any) => setPreferredMandate(rowData.id)}
          onEnabled={(enabled: boolean, id: string) => {
            editMandate({
              mandateId: id,
              enabled: enabled,
            });
          }}
          onDelete={(rowData: any) => {
            setMandateToDelete(rowData.id);
            setIsDeleteModalOpen(true);
          }}
        />
      </TableWrapper>
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteMandate(mandateToDelete!);
              setIsDeleteModalOpen(false);
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete mandate')}
          description={`${t('Are you sure you want to delete this mandate')}?`}
        />
      </Modal>
      <ConfirmModal
        isOpen={isCreateMandateModalOpen}
        setIsOpen={setIsCreateMandateModalOpen}
        onConfirm={() => {
          createMandate({ customer_id: customerId });
          setIsCreateMandateModalOpen(false);
        }}
        onCancel={() => setIsCreateMandateModalOpen(false)}
        title={t('Are you sure you want to create a Mollie mandate?')}
        description={t(
          'A mandate is required to process recurring payments. An email will be sent containing a link for mandate approval.'
        )}
      />
    </div>
  );
};

import * as Yup from 'yup';

export const editOrderDetailsSchema = () => {
  return Yup.object().shape({
    reference: Yup.string()
      .optional()
      .nullable()
      .max(80, 'Reference should be less than 80 characters'),
  });
};

import { ISalesOrderLine } from 'types/SalesOrders.types';

import { useTranslation } from 'react-i18next';
import {
  Container,
  More,
  ProductName,
  RightPart,
} from './AppointmentSalesOrderLine.styled';
import { ViewProductModal } from 'components/Modal/ViewProductModal/ViewProductModal';
import { useState } from 'react';
import { ViewProductModalFormatter } from 'components/Modal/ViewProductModal/helpers';
import { ViewProductType } from 'components/Modal/ViewProductModal/type';
import Button from 'components/Button/Button';

interface IAppointmentSalesOrderLineProps {
  salesOrderLine: ISalesOrderLine;
  isSelected: boolean;
  toggleIsSelected: (salesOrderLineId: number) => void;
  isReadOnly?: boolean;
}
export const AppointmentSalesOrderLine = ({
  salesOrderLine,
  isSelected,
  toggleIsSelected,
  isReadOnly = false,
}: IAppointmentSalesOrderLineProps) => {
  const { t } = useTranslation();
  const [isViewProductModalOpen, setIsViewProductModalOpen] =
    useState<boolean>(false);
  return (
    <Container isSelected={isSelected} isReadOnly={isReadOnly}>
      <ProductName>{salesOrderLine.product?.name}</ProductName>
      <RightPart>
        {salesOrderLine.product && (
          <More onClick={() => setIsViewProductModalOpen(true)}>
            {t('More')}
          </More>
        )}
        {!isReadOnly && (
          <Button
            data-testid={
              isSelected
                ? `unselect-button-${salesOrderLine.product?.name}`
                : `select-button-${salesOrderLine.product?.name}`
            }
            onClick={() => toggleIsSelected(Number(salesOrderLine.id))}
            width={'90rem'}
            secondary
            height="30rem"
            label={isSelected ? t('Unselect') : t('Select')}
          />
        )}
      </RightPart>
      <ViewProductModal
        level="SECOND"
        isOpen={isViewProductModalOpen}
        setIsOpen={setIsViewProductModalOpen}
        onCancel={() => setIsViewProductModalOpen(false)}
        productLineData={ViewProductModalFormatter(
          salesOrderLine,
          ViewProductType.SALES_ORDER
        )}
      />
    </Container>
  );
};

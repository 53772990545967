import React from 'react';
import * as Sentry from '@sentry/react';
import { config } from 'config';

interface ISentryProviderProps {
  children: React.ReactNode;
}

if (config.sentry.dsnUrl) {
  Sentry.init({
    dsn: config.sentry.dsnUrl,
    environment: config.environment,
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/erp.codeflex.dev\/api/,
      /^https:\/\/erp-api-staging.codeflex.it/,
      /^https:\/\/app.blue-print\.io\/api/,
    ],
  });
}

const SentryProvider = ({ children }: ISentryProviderProps) => {
  return <>{children}</>;
};

export default SentryProvider;

import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { Modal } from 'components/Modal/Modal';
import { Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  IconWrapper,
  Row,
  StyledCustomSelect,
  Title,
  TitleContainer,
} from './AddEditLabelModal.styled';
import { useAddLabel, useGetLabelColorOptions } from './hooks';
import { IAddEditLabelDTO } from 'services/Label/LabelService';
import { HexColorPicker } from 'react-colorful';
import { Palette } from '@phosphor-icons/react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { addNewLabelValidationSchema } from './validation';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Option } from 'components/Select/type';
import { ColorLabelOption } from 'components/Select/SelectComponents/ColorLabel/ColorLabelOption';
import { ColorLabelSingleValue } from 'components/Select/SelectComponents/ColorLabel/ColorLabelSingleValue';
import { ILabel } from 'types/EntityLabel.types';
import { useEditLabel } from '../hooks';

export interface IAddEditLabelModalProps {
  onCancel: () => void;
  isOpen: boolean;
  labelForEditing: ILabel | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  labelGroupOptions: Option[];
}

export const AddEditLabelModal = ({
  setIsOpen,
  isOpen,
  labelForEditing,
  onCancel,
  labelGroupOptions,
}: IAddEditLabelModalProps) => {
  const { t } = useTranslation();

  const { mutate: addLabel, isLoading: isLoadingAddLabel } = useAddLabel();
  const { mutate: editLabel, isLoading: isLoadingEditLabel } = useEditLabel();

  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [customColor, setCustomColor] = useState<string | null>('');

  const colorPickerRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(colorPickerRef, () => {
    setIsColorPickerOpen(false);
  });

  useEffect(() => {
    if (!isOpen) {
      setIsColorPickerOpen(false);
    }
  }, [isOpen]);

  const colorOptions: Option[] = useGetLabelColorOptions(customColor);

  return (
    <Modal
      isOpen={isOpen || !!labelForEditing}
      setIsOpen={setIsOpen}
      onBackDropClick={onCancel}
      level="FIRST"
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        padding: '20rem',
        width: '480rem',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{labelForEditing ? t('Edit label') : t('Create label')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            name: labelForEditing?.name || '',
            color: labelForEditing?.color || '',
            group: labelForEditing?.label_group_id || '',
            description: labelForEditing?.description || '',
          }}
          validationSchema={addNewLabelValidationSchema}
          validateOnMount={true}
          validateOnChange={true}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            handleBlur,
            isValid,
          }) => {
            return (
              <Container>
                <Input
                  pwId="name-field"
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'40rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '300rem',
                  }}
                />
                <Field
                  isSearchable={false}
                  pwId="group-field"
                  errorMessage={touched['group'] ? errors['group'] : ''}
                  height={'40rem'}
                  name="group"
                  options={labelGroupOptions}
                  component={StyledCustomSelect}
                  selectedValue={values.group}
                  placeholder={t('Group')}
                  isMulti={false}
                  onSelect={(value: string) => setFieldValue('group', value)}
                />
                <div style={{ position: 'relative' }}>
                  <Row>
                    <Field
                      isSearchable={false}
                      pwId="color-field"
                      errorMessage={touched['color'] ? errors['color'] : ''}
                      height={'40rem'}
                      name="color"
                      options={colorOptions}
                      component={StyledCustomSelect}
                      placeholder={t('Color')}
                      components={{
                        Option: ColorLabelOption,
                        SingleValue: ColorLabelSingleValue,
                      }}
                      isMulti={false}
                      onSelect={(value: string) =>
                        setFieldValue('color', value)
                      }
                    />
                    <IconWrapper>
                      <Icon
                        color={COLORS.BLACK}
                        svg={Palette}
                        size={30}
                        onClick={() => {
                          setIsColorPickerOpen(true);
                        }}
                      />
                    </IconWrapper>
                  </Row>
                  {isColorPickerOpen && (
                    <div
                      ref={colorPickerRef}
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        zIndex: 1000,
                      }}
                    >
                      <HexColorPicker
                        color={values.color}
                        onChange={(newColor: string) => {
                          setCustomColor(newColor);
                          setFieldValue('color', newColor);
                        }}
                      />
                    </div>
                  )}
                </div>
                <Input
                  pwId="description-field"
                  isTextArea
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  height={'150rem'}
                  name="description"
                  placeholder={t('Description')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                    marginTop: '10rem',
                  }}
                />
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={
                      !isValid || isLoadingAddLabel || isLoadingEditLabel
                    }
                    onClick={() => {
                      if (labelForEditing) {
                        const dto: IAddEditLabelDTO = {
                          id: labelForEditing.id,
                          name: values.name,
                          label_group_id: Number(values.group),
                          color: values.color,
                          description: values.description,
                        };
                        editLabel(dto, {
                          onSuccess: () => {
                            onCancel(); // Also sets label for editing to null
                          },
                        });
                      } else {
                        const dto: IAddEditLabelDTO = {
                          name: values.name,
                          color: values.color,
                          description: values.description,
                          label_group_id: Number(values.group),
                        };
                        addLabel(dto, {
                          onSuccess: () => {
                            setIsOpen(false);
                          },
                        });
                      }
                    }}
                    primary
                    width="200rem"
                    label={labelForEditing ? t('Edit') : t('Create')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { addResource } from 'services/Resources/ResourcesService';
import { queryClient } from 'index';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';

export interface IAddResourceDTO {
  name: string;
  description: string;
}

export const useAddResource = () => {
  const { t } = useTranslation();
  return useMutation((dto: IAddResourceDTO) => addResource(dto), {
    onSuccess: () => toast.success(t('Resource added successfully')),
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_RESOURCE,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_RESOURCES);
    },
    mutationKey: ReactMutationKeys.ADD_RESOURCE,
  });
};

import Button from 'components/Button/Button';
import moment from 'moment';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { INotification } from 'types/Notification.types';
import { useLocale } from 'utils/hooks/useLocale';
import {
  useGetNotificationTitleFromNotificationType,
  useMarkAsRead,
} from './hooks';
import {
  Container,
  DateLabel,
  DescriptionLabel,
  Row,
  RowGap,
  RowSpaceBetween,
  Title,
} from './NotificationCard.styled';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface INotificationCardProps {
  notification: INotification;
}

const NotificationCard = ({ notification }: INotificationCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLocale();

  const { mutate: markAsRead } = useMarkAsRead();

  const handleViewClick = () => {
    markAsRead(notification.id);
    // Optimistically update the cache
    // We can't wait for isSuccessMarkAsRead because the component gets dismounted due to navigating away
    queryClient.setQueryData<number>(
      [ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT],
      (oldCount) => (oldCount != null ? oldCount - 1 : 0)
    );
    if (notification.sales_order_id) {
      navigate(
        RoutesConfig.SingleSalesOrder.fullPath.replace(
          ':id/*',
          notification.sales_order_id.toString()
        )
      );
    } else if (notification.quotation_id) {
      navigate(
        RoutesConfig.SingleQuotation.fullPath.replace(
          ':id/*',
          notification.quotation_id.toString()
        )
      );
    } else if (notification.sales_invoice_id) {
      navigate(
        RoutesConfig.SingleSalesInvoice.fullPath.replace(
          ':id/*',
          notification.sales_invoice_id.toString()
        )
      );
    }
  };

  const notificationTitle = useGetNotificationTitleFromNotificationType(
    notification.notification_type_id
  );
  return (
    <Container>
      <RowSpaceBetween>
        <Title>{t(notificationTitle)}</Title>
        <RowGap>
          <Button
            width={'200rem'}
            onClick={() => handleViewClick()}
            label={t('View')}
            primary
          />
          <Icon
            size={24}
            svg={Trash}
            weight="regular"
            color={COLORS.RED_100}
            onClick={() => {
              markAsRead(notification.id, {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_NOTIFICATIONS,
                  ]);
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
                  ]);
                },
              });
            }}
          />
        </RowGap>
      </RowSpaceBetween>
      <Row>
        <DateLabel>
          {moment(notification.created_at)
            .locale(locale)
            .format('MMM DD, YYYY')}
        </DateLabel>
      </Row>
      <DescriptionLabel>{notification.description}</DescriptionLabel>
    </Container>
  );
};

export default NotificationCard;

import { H3, marginMd } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  StyledCustomSelect,
  SecondRow,
  ExistingOptionLabel,
  SelectWrapper,
  CheckboxContainer,
  CustomSelectWrapper,
  StyledCustomSelectSmall,
} from './CustomerTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik, getIn } from 'formik';
import { Input } from 'components/Input/InputFormik';
import {
  createQuotationCustomerTabSchema,
  createQuotationNoShipToAddressCustomerTabSchema,
} from './validation';
import {
  CUSTOMERS_PER_PAGE,
  ICreateQuotationCustomerDetails,
} from './constants';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { Select } from 'components/Select/Select';
import { ICustomer } from 'types/Customer.types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useGetCustomerByEmail } from './hooks';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { ICreateQuotationQueryParamsForCustomer } from 'types/Quotations.types';
import { useGetCityAndAddress } from '../hooks';
import debounce from 'lodash.debounce';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetCustomersInfinite } from 'components/Modal/AdvancedFilterModal/hooks';

interface ICustomerTabProps {
  onForward: (
    customerQueryParams: ICreateQuotationQueryParamsForCustomer
  ) => void;
  customerDetails: ICreateQuotationCustomerDetails;
  setCustomerDetails: Dispatch<SetStateAction<ICreateQuotationCustomerDetails>>;
  handlePopulateNewQuotationCustomerTab: any;
  resetCustomerTabForm: () => void;
  entityType?: 'quotation' | 'salesOrder';
}

const CustomerTab = ({
  onForward,
  customerDetails,
  setCustomerDetails,
  handlePopulateNewQuotationCustomerTab,
  resetCustomerTabForm,
  entityType,
}: ICustomerTabProps) => {
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const [areAllShippingFieldsTouched, setAreAllShippingFieldsTouched] =
    useState<boolean>(false);
  const countryOptions: Option[] = useGetCountryOptions();
  const [isNewCustomerSameEmailModalOpen, setIsNewCustomerSameEmailModalOpen] =
    useState(false);
  const [isUsingExistingCustomer, setIsUsingExistingCustomer] =
    useState<boolean>(!!customerDetails?.existingCustomer);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null
  );
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const isShippingDataFormShown = !!customerDetails.isShippingDataFormShown;
  const setIsShippingDataFormShown = (
    boolean: boolean,
    customerDetailsFormikValues: any
  ) => {
    const newCustomerDetails: ICreateQuotationCustomerDetails = {
      ...customerDetailsFormikValues,
      isShippingDataFormShown: boolean,
    };
    setCustomerDetails(newCustomerDetails);
  };

  const { t } = useTranslation();

  const {
    data: customersData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetCustomersInfinite(CUSTOMERS_PER_PAGE, debouncedSearchBy, true);

  const customerOptions = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: any) => {
          return {
            value: customer,
            label: `${customer?.name} ${customer?.last_name} - ${customer.number} - ${customer.address.zip_code} ${customer.address.house_number}`,
            key: customer.id,
          };
        });
    }
    return [];
  }, [customersData]);

  const titleOptions = useGetTitleOptions();

  useEffect(() => {
    if (selectedCustomer) {
      handlePopulateNewQuotationCustomerTab(selectedCustomer);
    }
  }, [selectedCustomer]);

  const {
    mutate: getCustomerByEmail,
    data: existingCustomerWithSameEmail,
    isLoading: existingCustomerWithSameEmailIsLoading,
    isSuccess: existingCustomerWithSameEmailIsSuccess,
  } = useGetCustomerByEmail();

  useEffect(() => {
    if (existingCustomerWithSameEmailIsSuccess) {
      if (existingCustomerWithSameEmail?.customer) {
        setIsNewCustomerSameEmailModalOpen(true);
      } else {
        const customerQueryParams: ICreateQuotationQueryParamsForCustomer = {
          force_create_customer: false,
        };
        onForward(customerQueryParams);
      }
    }
  }, [existingCustomerWithSameEmailIsSuccess]);

  // Get city and address handling
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: getCityAndAddressIsSuccess,
  } = useGetCityAndAddress();

  const {
    mutate: getCityAndAddressForShipTo,
    data: cityAndAddressShipToData,
    isSuccess: getCityAndAddressShipToIsSuccess,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      getCityAndAddressIsSuccess &&
      cityAndAddressData.street &&
      cityAndAddressData.city
    ) {
      formikRef?.current.setFieldValue('street', cityAndAddressData.street);
      formikRef?.current.setFieldValue('city', cityAndAddressData.city);
    }
  }, [getCityAndAddressIsSuccess]);

  useEffect(() => {
    if (
      getCityAndAddressShipToIsSuccess &&
      cityAndAddressShipToData.street &&
      cityAndAddressShipToData.city
    ) {
      formikRef?.current.setFieldValue(
        'shipping_data.street',
        cityAndAddressShipToData.street
      );
      formikRef?.current.setFieldValue(
        'shipping_data.city',
        cityAndAddressShipToData.city
      );
    }
  }, [getCityAndAddressShipToIsSuccess]);

  const debouncedGetCityAndAddress = debounce(
    (zipCode: string, houseNumber: string) => {
      if (zipCode && houseNumber) {
        getCityAndAddress({
          zipCode: zipCode,
          houseNumber: houseNumber,
        });
      }
    },
    500
  );
  const debouncedGetCityAndAddressForShipTo = debounce(
    (zipCode: string, houseNumber: string) => {
      if (zipCode && houseNumber) {
        getCityAndAddressForShipTo({
          zipCode: zipCode,
          houseNumber: houseNumber,
        });
      }
    },
    500
  );
  // End get city and address handling

  return (
    <Container>
      <FirstRow>
        <H3>{t('Customer details')}</H3>
        {isUsingExistingCustomer ? (
          <SelectWrapper>
            <Select
              pwId="existing-customer"
              name="existingCustomer"
              placeholder={t('Select Customer')}
              isMulti={false}
              isDisabled={false}
              isSearchable={true}
              onChange={(e: any) => setSelectedCustomer(e.value)}
              options={customerOptions}
              onInputChange={(value: string) => setSearchBy(value)}
              isLoading={isLoading}
              translate={false}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          </SelectWrapper>
        ) : (
          <Button
            label={
              entityType === 'salesOrder'
                ? t('Quick sales order')
                : t('Quick quotation')
            }
            width="200rem"
            height="41rem"
            secondary
            onClick={() => handlePopulateNewQuotationCustomerTab()}
          />
        )}
      </FirstRow>
      <SecondRow>
        <ExistingOptionLabel
          data-testid="existing-customer-label"
          isSelected={isUsingExistingCustomer}
          onClick={() => {
            if (!isUsingExistingCustomer) {
              resetCustomerTabForm();
              setIsUsingExistingCustomer(true);
            }
          }}
          marginRight={marginMd}
        >
          {t('Existing Customer')}
        </ExistingOptionLabel>
        <ExistingOptionLabel
          isSelected={!isUsingExistingCustomer}
          onClick={() => {
            if (isUsingExistingCustomer) {
              resetCustomerTabForm();
              setIsUsingExistingCustomer(false);
              setSelectedCustomer(null);
            }
          }}
        >
          {t('New Customer')}
        </ExistingOptionLabel>
      </SecondRow>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={customerDetails}
        validationSchema={
          isShippingDataFormShown
            ? createQuotationCustomerTabSchema
            : createQuotationNoShipToAddressCustomerTabSchema
        }
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId={'name-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['name'] ? errors['name'] : ''
                  }
                  height={'41rem'}
                  name="name"
                  placeholder={t('First name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId={'last-name-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['last_name']
                      ? errors['last_name']
                      : ''
                  }
                  height={'41rem'}
                  name="last_name"
                  placeholder={t('Last name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('last_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={'phone-number-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['phone']
                      ? errors['phone']
                      : ''
                  }
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <CustomSelectWrapper>
                  <Field
                    pwId={'gender-field'}
                    disabled={isUsingExistingCustomer}
                    errorMessage={
                      areAllFieldsTouched || touched['title']
                        ? errors['title']
                        : ''
                    }
                    height={'41rem'}
                    width="50%"
                    name="title"
                    options={titleOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Title')}
                    isMulti={false}
                    onSelect={(value: string) => setFieldValue('title', value)}
                  />
                </CustomSelectWrapper>
              </Row>
              <Row>
                <Input
                  pwId={'email-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['email']
                      ? errors['email']
                      : ''
                  }
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <CustomSelectWrapper>
                  <Field
                    pwId={'country-field'}
                    disabled={isUsingExistingCustomer}
                    errorMessage={
                      areAllFieldsTouched || touched['country']
                        ? errors['country']
                        : ''
                    }
                    height={'41rem'}
                    name="country"
                    width="50%"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Country Code')}
                    isMulti={false}
                    onSelect={(value: string) =>
                      setFieldValue('country', value)
                    }
                  />
                </CustomSelectWrapper>
              </Row>

              <Row>
                <Input
                  pwId={'zip-code-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['code'] ? errors['code'] : ''
                  }
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) =>
                    debouncedGetCityAndAddress(value, values.house_number)
                  }
                />
                <Input
                  pwId={'house-number-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['house_number']
                      ? errors['house_number']
                      : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  onChange={(value: any) => {
                    debouncedGetCityAndAddress(values.code, value);
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={'city-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={getIn(touched, 'city') && getIn(errors, 'city')}
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId={'street-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['street']
                      ? errors['street']
                      : ''
                  }
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={'company-name-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['company_name']
                      ? errors['company_name']
                      : ''
                  }
                  height={'41rem'}
                  name="company_name"
                  placeholder={t('Company Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('company_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId={'company-vat-field'}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['vat_number']
                      ? errors['vat_number']
                      : ''
                  }
                  height={'41rem'}
                  name="vat_number"
                  placeholder={t('Company VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('vat_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isShippingDataFormShown}
                    label={t('Different shipping address')}
                    style={{ fontSize: '18rem' }}
                    onChange={() => {
                      if (
                        areAllShippingFieldsTouched &&
                        isShippingDataFormShown
                      )
                        setAreAllShippingFieldsTouched(false);
                      setIsShippingDataFormShown(
                        !isShippingDataFormShown,
                        values
                      );
                    }}
                  />
                </CheckboxContainer>
              </Row>
              {isShippingDataFormShown && (
                <>
                  <Row>
                    <label>{t('Ship-to address')}</label>
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-first-name-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.first_name') &&
                          getIn(errors, 'shipping_data.first_name'))
                          ? getIn(errors, 'shipping_data.first_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.first_name"
                      placeholder={t('First name')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.first_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                    <Input
                      pwId="shipping-data-last-name-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.last_name') &&
                          getIn(errors, 'shipping_data.last_name'))
                          ? getIn(errors, 'shipping_data.last_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.last_name"
                      placeholder={t('Last name')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.last_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-phone-number-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.phone') &&
                          getIn(errors, 'shipping_data.phone'))
                          ? getIn(errors, 'shipping_data.phone')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.phone"
                      placeholder={t('Phone number')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.phone', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                    <CustomSelectWrapper>
                      <Field
                        pwId={'gender-field'}
                        errorMessage={
                          areAllShippingFieldsTouched ||
                          (getIn(touched, 'shipping_data.title') &&
                            getIn(errors, 'shipping_data.title'))
                            ? getIn(errors, 'shipping_data.title')
                            : ''
                        }
                        height={'41rem'}
                        width="50%"
                        name="shipping_data.title"
                        options={titleOptions}
                        component={StyledCustomSelectSmall}
                        placeholder={t('Title')}
                        isMulti={false}
                        onSelect={(value: string) =>
                          setFieldValue('shipping_data.title', value)
                        }
                      />
                    </CustomSelectWrapper>
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-email-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.email') &&
                          getIn(errors, 'shipping_data.email'))
                          ? getIn(errors, 'shipping_data.email')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.email"
                      placeholder={t('E-mail')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.email', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                    <CustomSelectWrapper>
                      <Field
                        pwId="shipping-data-country-field"
                        errorMessage={
                          areAllShippingFieldsTouched ||
                          (getIn(touched, 'shipping_data.country') &&
                            getIn(errors, 'shipping_data.country'))
                            ? getIn(errors, 'shipping_data.country')
                            : ''
                        }
                        height={'41rem'}
                        name="shipping_data.country"
                        width="50%"
                        options={countryOptions}
                        component={StyledCustomSelectSmall}
                        placeholder={t('Country Code')}
                        isMulti={false}
                        onSelect={(value: string) =>
                          setFieldValue('shipping_data.country', value)
                        }
                      />
                    </CustomSelectWrapper>
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-zip-code-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.zip_code') &&
                          getIn(errors, 'shipping_data.zip_code'))
                          ? getIn(errors, 'shipping_data.zip_code')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.zip_code"
                      placeholder={t('Zip code')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.zip_code', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                      onChange={(value: any) => {
                        if (values.shipping_data?.house_number && value) {
                          debouncedGetCityAndAddressForShipTo(
                            value,
                            values!.shipping_data!.house_number
                          );
                        }
                      }}
                    />
                    <Input
                      pwId="shipping-data-house-number-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.house_number') &&
                          getIn(errors, 'shipping_data.house_number'))
                          ? getIn(errors, 'shipping_data.house_number')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.house_number"
                      placeholder={t('House number')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.house_number', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                      onChange={(value: any) => {
                        if (values.shipping_data?.house_number && value) {
                          debouncedGetCityAndAddressForShipTo(
                            values!.shipping_data!.zip_code,
                            value
                          );
                        }
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-city-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.city') &&
                          getIn(errors, 'shipping_data.city'))
                          ? getIn(errors, 'shipping_data.city')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.city"
                      placeholder={t('City')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.city', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                    <Input
                      pwId="shipping-data-street-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.street') &&
                          getIn(errors, 'shipping_data.street'))
                          ? getIn(errors, 'shipping_data.street')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.street"
                      placeholder={t('Street')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.street', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId="shipping-data-company-name-field"
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.company_name') &&
                          getIn(errors, 'shipping_data.company_name'))
                          ? getIn(errors, 'shipping_data.company_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.company_name"
                      placeholder={t('Company name')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.company_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                        paddingRight: '15rem',
                      }}
                    />
                  </Row>
                </>
              )}

              <AlignRow>
                <Button
                  data-testid="next-button"
                  disabled={existingCustomerWithSameEmailIsLoading}
                  width={'200rem'}
                  onClick={() => {
                    setAreAllFieldsTouched(true);
                    if (isShippingDataFormShown)
                      setAreAllShippingFieldsTouched(true);

                    if (
                      isValid ||
                      (Object.keys(errors).length === 1 &&
                        Object.keys(errors)[0] === 'shipping_data' &&
                        !isShippingDataFormShown)
                    ) {
                      if (!isShippingDataFormShown) delete values.shipping_data;
                      setCustomerDetails(values);
                      if (isUsingExistingCustomer) {
                        const customerQueryParams: ICreateQuotationQueryParamsForCustomer =
                          {
                            force_create_customer: false,
                          };
                        onForward(customerQueryParams);
                      } else {
                        getCustomerByEmail(values.email);
                      }
                    }
                  }}
                  label={t('Next')}
                  primary
                />
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-existing-customer-modal"
        isOpen={isNewCustomerSameEmailModalOpen}
        setIsOpen={setIsNewCustomerSameEmailModalOpen}
        onYes={() => {
          const customerQueryParams: ICreateQuotationQueryParamsForCustomer = {
            force_create_customer: true,
          };
          onForward(customerQueryParams);
        }}
        onNo={() => setIsNewCustomerSameEmailModalOpen(false)}
        title={''}
        description={`${
          t('Email already exists for customer') +
          ` ${existingCustomerWithSameEmail?.customer?.number} (${existingCustomerWithSameEmail?.customer?.name} ${existingCustomerWithSameEmail?.customer?.last_name})` +
          t(', do you still want to proceed with adding a new customer')
        }?`}
      />
    </Container>
  );
};

export default CustomerTab;

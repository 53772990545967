import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  Title,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  AwaitingLogyxConfigurationContainer,
  AwaitingLabel,
  LoaderWrapper,
} from './EditWithLogyxModal.styled';
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'config';
import {
  setLogyxConfigurationIntent,
  setIsAwaitingEdit,
  setPendingIntentUUID,
} from 'store/Logyx/actions/logyx';
import { ILogyxReducerState } from 'store/Logyx/reducers/logyx';
import { IRootReducerState } from 'store/store';
import Loader from 'components/Loader/Loader';
import { useEffect, useState } from 'react';
import { Danger } from 'components/Modal/Danger/Danger';
import { useGenerateLogyxIntent } from '../../AddNewProduct/ConfigureLogyxModal/hooks';
import { ICreateQuotationProductFormDTO } from '../../constants';

import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { toast } from 'utils/toast';
import { ConfirmLogyxSalesPriceUpdateModal } from './ConfirmLogyxSalesPriceUpdateModal/ConfirmLogyxSalesPriceUpdateModal';
import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';
import { useCheckForExistingLogyxConfigurationOnPageFocus } from 'utils/hooks/useCheckForExistingLogyxConfigurationOnPageFocus';
import { Base64 } from 'js-base64';

export interface IEditWithLogyxModalProps {
  isOpen: boolean;
  setIsOpen: any;
  productFormDTOForLogyxEditing: ICreateQuotationProductFormDTO | null;
  onLogyxEditConfigurationFinished: (
    editIntent: ILogyxConfigurationIntent,
    shouldUpdateSalesPrice: boolean
  ) => void;
}

const LOGYX_FORMS_APP_URL = config.logyx.logyxFormsUrl;

export function EditWithLogyxModal({
  isOpen,
  setIsOpen,
  productFormDTOForLogyxEditing,
  onLogyxEditConfigurationFinished,
}: IEditWithLogyxModalProps) {
  const [
    isConfirmLogyxSalesPriceUpdateModalOpen,
    setIsConfirmLogyxSalesPriceUpdateModalOpen,
  ] = useState<boolean>(false);
  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const logyxModelId =
    productFormDTOForLogyxEditing?.logyxConfigurationIntent?.logyx_model_id;
  const productId = productFormDTOForLogyxEditing?.product?.id;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDangerModalOpen, setIsDangerModalOpen] = useState<boolean>(false);
  const logyxInfo: ILogyxReducerState = useSelector(
    (state: IRootReducerState) => state.logyxInfo
  );
  const { isMobileApp, darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const {
    data: logyxIntentData,
    mutate: generateLogyxIntent,
    isSuccess: isGenerateLogyxIntentSuccess,
  } = useGenerateLogyxIntent();

  useCheckForExistingLogyxConfigurationOnPageFocus(
    true,
    logyxIntentData?.intent_uuid
  );

  // Open logyx forms
  const handleEditWithLogyx = () => {
    if (!LOGYX_FORMS_APP_URL) {
      alert('Logyx forms app url missing');
      return;
    }
    if (!logyxModelId) {
      alert('No logyx model id present on product');
      return;
    }
    dispatch(setIsAwaitingEdit(true));
    dispatch(setLogyxConfigurationIntent(null));
    generateLogyxIntent({
      logyxConfigurationIntentUUID:
        productFormDTOForLogyxEditing?.logyxConfigurationIntent?.uuid,
      logyxModelId,
      productId,
    });
  };

  useEffect(() => {
    if (isGenerateLogyxIntentSuccess && logyxIntentData) {
      dispatch(setPendingIntentUUID(logyxIntentData.intent_uuid));
      const base64Metadata = Base64.encode(
        JSON.stringify({
          companyId: companyId,
        })
      );
      const queryParamsObj = {
        modelId: logyxModelId,
        ...(darkMode && { isDarkMode: true }),
        lang: i18n.resolvedLanguage || FALLBACK_I18N_LANG,
        isEdit: true,
        intentUUID: logyxIntentData.intent_uuid,
        base64Metadata,
      };
      const fullUrl = getUrlWithQueryParams(
        `${LOGYX_FORMS_APP_URL}/configure/`,
        queryParamsObj
      );
      if (companyId) {
        if (isMobileApp) {
          const mobileMessage: IMobileMessage = {
            type: MobileMessageTypes.OPEN_LOGYX,
            payload: fullUrl,
          };
          postMobileMessage(mobileMessage);
        } else {
          window.open(fullUrl, 'LOGYX_FORMS');
        }
      } else {
        toast.warn('Configuration error, reload page and try again');
      }
    }
  }, [isGenerateLogyxIntentSuccess]);

  // Triggered by onLogyxEditConfigurationFinishedEvent
  if (
    !logyxInfo.isAwaitingEdit &&
    logyxInfo.logyxConfigurationIntent &&
    logyxModelId
  ) {
    if (
      Number(logyxModelId) ===
      Number(logyxInfo?.logyxConfigurationIntent?.data?.modelId)
    ) {
      if (!isConfirmLogyxSalesPriceUpdateModalOpen) {
        setIsConfirmLogyxSalesPriceUpdateModalOpen(true);
      }
    } else {
      alert(
        `Logyx model id of product submitted for configuration doesn't match configuration data model id`
      );
      dispatch(setLogyxConfigurationIntent(null));
      dispatch(setIsAwaitingEdit(false));
      dispatch(setPendingIntentUUID(null));
      setIsConfirmLogyxSalesPriceUpdateModalOpen(false);
      setIsOpen(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) =>
        logyxInfo.isAwaitingEdit ? () => null : setIsOpen(boolean)
      }
      modalStyle={{ position: 'fixed', margin: 'auto' }} // Center positioning
    >
      {logyxInfo.isAwaitingEdit ? (
        <AwaitingLogyxConfigurationContainer>
          <AwaitingLabel>{t('Editing through Logyx')}</AwaitingLabel>
          <LoaderWrapper>
            <Loader positionType="relative" />
          </LoaderWrapper>
          <CloseAbsoluteWrapper>
            <Close
              onClick={() => {
                setIsDangerModalOpen(true);
              }}
            />
          </CloseAbsoluteWrapper>
        </AwaitingLogyxConfigurationContainer>
      ) : (
        <Container>
          <Title>
            {t('Are you sure you want to edit this product through Logyx') +
              '?'}
          </Title>
          <ButtonWrapper>
            <Button
              label={t('Cancel')}
              secondary
              width="200rem"
              onClick={() => setIsOpen(false)}
            />
            <Button
              label={t('Edit')}
              primary
              width="200rem"
              onClick={() => handleEditWithLogyx()}
            />
          </ButtonWrapper>
          <CloseAbsoluteWrapper top="-15rem" right="-15rem">
            <Close
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </CloseAbsoluteWrapper>
        </Container>
      )}
      <Modal
        level={'SECOND'}
        isOpen={isDangerModalOpen}
        setIsOpen={setIsDangerModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (isMobileApp) {
                const mobileMessage: IMobileMessage = {
                  type: MobileMessageTypes.CLOSE_LOGYX,
                };
                postMobileMessage(mobileMessage);
              }
              dispatch(setIsAwaitingEdit(false));
              dispatch(setLogyxConfigurationIntent(null));
              dispatch(setPendingIntentUUID(null));
              setIsDangerModalOpen(false);
              setIsOpen(false);
            },
            text: t('Cancel'),
            disabled: false,
          }}
          cancel={{
            onClick: () => setIsDangerModalOpen(false),
            text: t('Back'),
          }}
          title={t('Cancel configuration')}
          description={
            t('Are you sure you want to cancel editing through Logyx') + '?'
          }
        />
      </Modal>
      <ConfirmLogyxSalesPriceUpdateModal
        isOpen={isConfirmLogyxSalesPriceUpdateModalOpen}
        setIsOpen={setIsConfirmLogyxSalesPriceUpdateModalOpen}
        onConfirm={() => {
          onLogyxEditConfigurationFinished(
            logyxInfo.logyxConfigurationIntent!,
            true
          );
          dispatch(setLogyxConfigurationIntent(null));
          dispatch(setIsAwaitingEdit(false));
          dispatch(setPendingIntentUUID(null));
          setIsConfirmLogyxSalesPriceUpdateModalOpen(false);
          setIsOpen(false);
        }}
        onCancel={() => {
          onLogyxEditConfigurationFinished(
            logyxInfo.logyxConfigurationIntent!,
            false
          );
          dispatch(setLogyxConfigurationIntent(null));
          dispatch(setIsAwaitingEdit(false));
          dispatch(setPendingIntentUUID(null));
          setIsConfirmLogyxSalesPriceUpdateModalOpen(false);
          setIsOpen(false);
        }}
      />
    </Modal>
  );
}

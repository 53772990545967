import { COLORS } from 'assets/styled';
import { Option } from 'components/Select/type';
import { PriorityTypeId } from 'types/ToDo.types';

export const PriorityOptions: Option[] = [
  {
    value: PriorityTypeId.VERY_HIGH,
    label: 'Very High',
    color: COLORS.RED_200,
    key: 'VERY_HIGH',
  },
  {
    value: PriorityTypeId.HIGH,
    label: 'High',
    color: COLORS.ORANGE,
    key: 'HIGH',
  },
  {
    value: PriorityTypeId.MEDIUM,
    label: 'Medium',
    color: COLORS.PURPLE,
    key: 'MEDIUM',
  },
  {
    value: PriorityTypeId.LOW,
    label: 'Low',
    color: COLORS.GREEN,
    key: 'LOW',
  },
];

import { COLORS } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30rem;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  margin-top: 10rem;
  align-items: center;
  justify-content: center;
  gap: 30rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 300rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16rem;
`;

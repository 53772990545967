import { OptionProps } from 'react-select';
import { components } from 'react-select';
import { ColorSquare, OptionContainer } from './ColorLabel.styled';

export const ColorLabelOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <OptionContainer data-testid={props.data.pwId}>
        {props.data.label}
        <ColorSquare color={props.data.value} />
      </OptionContainer>
    </components.Option>
  );
};

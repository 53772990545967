import { gapSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  gap: string;
  marginBottom: string;
  withoutBottomBorder: boolean;
  alignItems: string;
}

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: flex-start;
  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${(props: StyledProps) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
    ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const ColumnOuter = styled.div`
  min-height: 190rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  gap: 47rem;
`;

export const ColumnGap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${gapSm};
`;

export const ResourceWorkingHourRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ResourceName = styled.label`
  font-size: 16rem;
  font-weight: 300;
`;

export const RequiredResourcesLabel = styled.label`
  font-size: 14rem;
  font-weight: 300;
`;

import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getPurchaseOrders } from 'services/PurchaseOrder/PurchaseOrderService';

export const useGetPurchaseOrdersInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean,
  sortBy?: string,
  sortDirection?: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_PURCHASE_ORDERS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getPurchaseOrders(
        pageParam,
        perPage,
        undefined,
        undefined,
        undefined,
        searchBy,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetPurchaseOrdersOptions = (
  purchaseOrdersData: InfiniteData<any> | undefined
) => {
  const purchaseOrdersOptions = useMemo(() => {
    if (purchaseOrdersData?.pages?.length) {
      return purchaseOrdersData.pages
        .map((page: any) => page.purchase_orders)
        .flat()
        .map((purchase_order: any) => {
          return {
            value: purchase_order.id,
            label: `${purchase_order.number}`,
          };
        });
    }
    return [];
  }, [purchaseOrdersData]);
  return purchaseOrdersOptions;
};

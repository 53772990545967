import {
  CloseContainer,
  ExportLabel,
  FirstRow,
  InvoiceNumberLabel,
  InvoiceStatusLabel,
  LeftSide,
  RightSide,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
} from './PreviewSalesInvoiceModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'components/Modal/Modal';
import {
  useExportSalesInvoice,
  useGetSingleSalesInvoice,
} from 'pages/Manager/SingleInvoice/SingleSalesInvoice/hooks';
import { formatNumber } from 'utils/numberFormatter';
import {
  IFwSalesInvoiceLineTableDTO,
  ISalesInvoice,
  ISalesInvoiceLine,
} from 'types/Invoice.types';
import Table from 'components/Table/Table';
import { tableConfig } from './constants';
import { formatFwSalesInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Icon from 'components/Icon/Icon';
import { DownloadSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { Level } from 'components/Modal/type';

interface IPreviewSalesInvoiceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  invoiceIdForPreview: number | null;
  previewInvoiceModalLevel: Level;
}

export const PreviewSalesInvoiceModal = ({
  isOpen,
  setIsOpen,
  invoiceIdForPreview,
  previewInvoiceModalLevel,
}: IPreviewSalesInvoiceModalProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { data: salesInvoiceData, isLoading: isLoadingSalesInvoice } =
    useGetSingleSalesInvoice(
      invoiceIdForPreview ? invoiceIdForPreview?.toString() : ''
    );

  const salesInvoice: ISalesInvoice | undefined =
    salesInvoiceData?.sales_invoice;

  let tableData: IFwSalesInvoiceLineTableDTO[] = [];
  if (salesInvoice?.sales_invoice_lines) {
    tableData = formatFwSalesInvoiceLineTableData(
      salesInvoice?.sales_invoice_lines
        .map((salesInvoiceLine: ISalesInvoiceLine) => {
          return salesInvoiceLine;
        })
        .flat(),
      currencySymbol
    );
  }

  const { mutate: exportSalesInvoice, isLoading: isLoadingExportSalesInvoice } =
    useExportSalesInvoice();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed', maxWidth: '850rem' }}
      level={previewInvoiceModalLevel}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <FirstRow>
        <LeftSide>
          {isLoadingSalesInvoice ? (
            <Spinner size={20} />
          ) : (
            <InvoiceNumberLabel>
              {salesInvoice?.invoice_number}
            </InvoiceNumberLabel>
          )}
          {isLoadingSalesInvoice ? (
            <Spinner size={20} />
          ) : (
            <InvoiceStatusLabel>
              {t(salesInvoice?.payment_status.name)}
            </InvoiceStatusLabel>
          )}
        </LeftSide>
        <RightSide
          onClick={() =>
            exportSalesInvoice(
              salesInvoice?.id ? salesInvoice?.id.toString() : ''
            )
          }
        >
          <ExportLabel>{t('Export')}</ExportLabel>
          {isLoadingExportSalesInvoice ? (
            <Spinner size={18} />
          ) : (
            <Icon svg={DownloadSimple} color={COLORS.PRIMARY} size={20} />
          )}
        </RightSide>
      </FirstRow>
      <RowSpaceBetweenMarginTop>
        {isLoadingSalesInvoice ? (
          <Spinner size={20} />
        ) : (
          <SmallColumnDiv>
            <SmallColumnDivLabel>
              {salesInvoice?.is_credit ? t('Corrective amount') : t('Total')}
            </SmallColumnDivLabel>
            <SmallColumnDivLabelValue>
              {currencySymbol}
              {formatNumber(salesInvoice?.total_amount)}
            </SmallColumnDivLabelValue>
          </SmallColumnDiv>
        )}
        {isLoadingSalesInvoice ? (
          <Spinner size={20} />
        ) : (
          <SmallColumnDiv>
            <SmallColumnDivLabel>{t('Open amount')}</SmallColumnDivLabel>
            <SmallColumnDivLabelValue>
              {currencySymbol}
              {formatNumber(salesInvoice?.open_amount)}
            </SmallColumnDivLabelValue>
          </SmallColumnDiv>
        )}
        {isLoadingSalesInvoice ? (
          <Spinner size={20} />
        ) : (
          <SmallColumnDiv>
            <SmallColumnDivLabel>{t('Received amount')}</SmallColumnDivLabel>
            <SmallColumnDivLabelValue>
              {currencySymbol}
              {formatNumber(salesInvoice?.total_amount_payed)}
            </SmallColumnDivLabelValue>
          </SmallColumnDiv>
        )}
      </RowSpaceBetweenMarginTop>
      <Table
        page={-1}
        perPage={-1}
        total={salesInvoiceData?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={false}
        loadPage={() => null}
      />
    </Modal>
  );
};

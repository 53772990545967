import apiClient from '../api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import {
  IBulkBookData,
  ICreatePurchaseOrderData,
  IEditPurchaseOrderDetails,
  IEditPurchaseOrderLineStatus,
} from 'types/PurchaseOrders.types';
import i18n from 'providers/i18n/i18n';
import { IEditPurchaseOrderLinePrice } from 'pages/Manager/SinglePurchaseOrder/EditProductModal/hooks';
import { SortDirection } from 'components/Table/constants';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { downloadBlob } from 'utils/downloadBlob';
import { IMoveToNewOrderDTO } from 'pages/Manager/DirectLogyxOrderPage/ManualModal/hooks';
import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';

const BASE_URL = '/v1/purchase-orders/';

export const createPurchaseOrder = async (
  createPurchaseOrderData: ICreatePurchaseOrderData
): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, createPurchaseOrderData);

  return data.data;
};

export const createPurchaseOrderBatch = async (
  createPurchaseOrderBatchData: ICreatePurchaseOrderData[]
): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(`${url}batch`, {
    purchase_orders: createPurchaseOrderBatchData,
  });

  return data.data;
};

export const getPurchaseInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getPurchaseOrders = async (
  page: number,
  perPage: number,
  searchBy?: string,
  purchaseOrderStatuses?: string,
  supplierId?: number,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(purchaseOrderStatuses && {
      purchase_order_statuses: purchaseOrderStatuses,
    }),
    ...(supplierId && { supplier_id: supplierId }),
    ...(dateInterval && { date_interval: dateInterval }),
    ...(searchBy && { search: searchBy }),
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection !== SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
    ...(selectedLabelIds && { label_ids: selectedLabelIds.join(',') }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getPurchaseOrderByPurchaseOrderId = async (
  purchaseOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}`;
  const { data } = await apiClient.get<any>(url);

  return data?.data?.purchase_order;
};

export const changeStatus = async (
  id: string,
  status_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/status`;
  const { data } = await apiClient.patch<any>(url, {
    new_status_id: status_id,
  });

  return data.data;
};

export const newComment = async (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/comments`;
  const { data } = await apiClient.post<any>(url, {
    headline,
    description,
    is_internal: isInternal,
  });

  return data.data;
};

export const getPurchaseOrderComments = async (
  id: string,
  page: number,
  perPage: number,
  dateFrom: string,
  dateTo: string
) => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(dateFrom && { date_from: dateFrom }),
    ...(dateTo && { date_to: dateTo }),
  };

  const url = BASE_URL + `${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const getPurchaseOrderFiles = async (
  purchaseOrderId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${purchaseOrderId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadPurchaseOrderFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deletePurchaseOrderFile = async (
  purchaseOrderId: string,
  purchaseOrderFileId: string
) => {
  const url = BASE_URL + `${purchaseOrderId}/files/${purchaseOrderFileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const exportPurchaseOrder = async (
  purchaseOrderId: string
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${purchaseOrderId}/export`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl, purchaseOrderId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const getSinglePurchaseOrderInvoices = async (
  purchaseOrderId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${purchaseOrderId}/invoices`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getSinglePurchaseOrderSalesOrders = async (
  purchaseOrderId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${purchaseOrderId}/sales-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const sendPurchaseOrderEmail = async (purchaseOrderId: string) => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${purchaseOrderId}/email-customer`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl);
  return data.data;
};

export const editPurchaseOrderDetails = async (
  purchaseOrderId: string,
  editData: IEditPurchaseOrderDetails
): Promise<any> => {
  const url = BASE_URL + `${purchaseOrderId}`;
  const { data } = await apiClient.patch<any>(url, editData);

  return data.data;
};

export const editPurchaseOrderFile = async (
  purchaseOrderId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${purchaseOrderId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const editPurchaseOrderLinePrice = async (
  purchaseOrderId: string,
  lineId: string,
  editLineDTO: IEditPurchaseOrderLinePrice
) => {
  const url = BASE_URL + `${purchaseOrderId}/lines`;
  const { data } = await apiClient.patch(url, {
    lines: [
      {
        line_id: lineId,
        product_purchase_price: editLineDTO.product_purchase_price,
        expected_total_amount: editLineDTO.expected_total_amount,
      },
    ],
  });

  return data.data;
};

export const getPurchaseOrderInfoById = async (
  purchaseOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const confirmPurchaseOrder = async (
  purchaseOrderId: number,
  fileFormData: FormData
): Promise<any> => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}${purchaseOrderId}/confirm`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const exportPurchaseOrderExcel = async (
  fileName: string
): Promise<any> => {
  const url = `${BASE_URL}export-excel`;

  const { data } = await apiClient.get<Blob>(url, {
    responseType: 'blob',
  });

  downloadBlob(data, fileName);

  return data;
};

export const startLogyxValidationForPurchaseOrder = async (
  purchaseOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}/start-logyx-integration`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getLogyxOrder = async (
  logyxOrderId: string,
  purchaseOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}/logyx_orders/${logyxOrderId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const createCart = async (purchaseOrderId: string): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}/create-cart`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getReconfigureLogyxURL = async (
  lineId: string,
  purchaseOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseOrderId}/lines/${lineId}/reconfigure-logyx`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getAvailableSupplierOrders = async (
  supplierId: string
): Promise<any> => {
  const queryParamsObj = {
    supplier_id: supplierId,
  };
  const url = `${BASE_URL}available-supplier-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const moveToExistingOrder = async (
  selectedOrderId: number,
  selectedOrderLineId: any
): Promise<any> => {
  const requestBody = {
    purchase_order_id: selectedOrderId,
    purchase_order_line_ids: JSON.stringify([selectedOrderLineId]),
  };

  const url = `${BASE_URL}move-to-existing-order`;
  const { data } = await apiClient.post<any>(url, requestBody);

  return data.data;
};

export const bulkBookPurchaseOrder = async (
  bulkBookPurchaseOrderData: IBulkBookData
): Promise<any> => {
  const url = `${BASE_URL}bulk-book`;
  const { data } = await apiClient.patch(url, bulkBookPurchaseOrderData);

  return data.data;
};

export const editPurchaseOrderLineStatus = async (
  purchaseOrderId: string,
  lineId: string,
  editLineDTO: IEditPurchaseOrderLineStatus
) => {
  const url = BASE_URL + `${purchaseOrderId}/lines/${lineId}`;
  const { data } = await apiClient.patch(url, editLineDTO);

  return data.data;
};

export const movePurchaseOrderLineToNewOrder = async (
  moveToNewOrderDTO: IMoveToNewOrderDTO
) => {
  const url = BASE_URL + `move-to-new-order`;

  const { data } = await apiClient.post(url, moveToNewOrderDTO);

  return data.data;
};

export const reorderPurchaseOrderLines = async (
  purchaseOrderId: number,
  dto: IReorderLinesRequestDTO
) => {
  const url = BASE_URL + `${purchaseOrderId}/reorder-lines`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};

export const normalizeValues = (values: any) => {
  const normalizedValues = { ...values };

  Object.keys(normalizedValues).forEach((key) => {
    if (
      typeof normalizedValues[key] === 'object' &&
      normalizedValues[key] !== null &&
      'value' in normalizedValues[key]
    ) {
      normalizedValues[key] = normalizedValues[key].value;
    }
  });

  return normalizedValues;
};

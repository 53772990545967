import { ILabel } from 'types/EntityLabel.types';
import {
  ActionsWrapper,
  Container,
  DeleteIcon,
  DeleteLabel,
  Description,
  DescriptionWrapper,
  EditDeleteWrapperRelative,
  EditIcon,
  EditLabel,
  Row,
  RowGap,
  SelectInputWrapper,
  StaticLabel,
} from './LabelRow.styled';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { IAddEditLabelDTO } from 'services/Label/LabelService';
import { useEditLabel } from '../hooks';
import Spinner from 'components/Spinner/Spinner';

interface ILabelRowProps {
  label: ILabel;
  isLast: boolean;
  onEditClick: (label: ILabel) => void;
  onDeleteClick: (labelId: number) => void;
  labelGroupOptions: Option[];
}

export const LabelRow = ({
  label,
  isLast,
  onEditClick,
  onDeleteClick,
  labelGroupOptions,
}: ILabelRowProps) => {
  const { t } = useTranslation();
  const { mutate: editLabel } = useEditLabel();

  return (
    <Container withoutLine={isLast}>
      <Row>
        <RowGap>
          <StaticLabel>{t('Label') + ':'}</StaticLabel>
          <EntityLabel label={label} />
        </RowGap>
        <ActionsWrapper>
          <EditDeleteWrapperRelative onClick={() => onEditClick(label)}>
            <EditLabel>{t('Edit')}</EditLabel>
            <EditIcon>
              <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
            </EditIcon>
          </EditDeleteWrapperRelative>
          <EditDeleteWrapperRelative onClick={() => onDeleteClick(label.id)}>
            <DeleteLabel>{t('Delete')}</DeleteLabel>
            <DeleteIcon>
              <Icon
                svg={Trash}
                size={18}
                color={COLORS.RED_200}
                weight="regular"
              />
            </DeleteIcon>
          </EditDeleteWrapperRelative>
        </ActionsWrapper>
      </Row>

      <RowGap marginTop={'20rem'}>
        {label.description && (
          <DescriptionWrapper>
            <StaticLabel>{t('Description') + ':'}</StaticLabel>
            <Description>{label.description}</Description>
          </DescriptionWrapper>
        )}
        <SelectInputWrapper>
          {labelGroupOptions ? (
            <Select
              key={label.id}
              name="labelGroup"
              placeholder={t('Label Group')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              defaultValue={labelGroupOptions?.find(
                (group: any) => Number(group.value) === label.label_group_id
              )}
              onChange={(e: Option) => {
                const dto: IAddEditLabelDTO = {
                  id: label.id,
                  name: label.name,
                  label_group_id: Number(e.value),
                  color: label.color,
                  description: label.description,
                };
                editLabel(dto);
              }}
              options={labelGroupOptions}
            />
          ) : (
            <Spinner />
          )}
        </SelectInputWrapper>
      </RowGap>
    </Container>
  );
};

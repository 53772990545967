import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10rem 20rem;
  gap: 5rem;

  &:hover {
    background-color: ${COLORS.GREY_200};
  }
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15rem;
`;

export const StaticLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  color: ${COLORS.BLACK};
  min-width: 100rem;
  cursor: pointer;
`;

import { useLayoutEffect, useRef, useState } from 'react';

export const useCalculateHeaderHeight = () => {
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (headerWrapperRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === headerWrapperRef.current) {
            setHeaderHeight(entry.contentRect.height);
          }
        }
      });
      resizeObserver.observe(headerWrapperRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return {
    headerHeight,
    headerWrapperRef,
  };
};

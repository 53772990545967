import { ILabel } from './EntityLabel.types';

interface ITypeObject {
  name: string;
  id: number;
}

interface IToDoUser {
  name: string;
  last_name: string;
  id: number;
}

export interface IToDo {
  title: string;
  todo_type: ITypeObject;
  todo_priority: ITypeObject;
  assigned_to: IToDoUser;
  related_to_id: number;
  related_to_number: string;
  related_type_id: number;
  finished: boolean;
  created_at: string;
  created_by: IToDoUser;
  id: number;
  labels: ILabel[];
}

export interface IToDoDTO {
  title: string;
  description: string;
  type_id: string;
  priority_id: string;
  assigned_to_user_id: number | null;
  related_to_id: number | null;
  related_type_id: string | null;
  label_ids: number[] | [];
}

export interface IToDoTableDTO {
  todoId: number;
  title: string;
  type: string;
  priority: string;
  assignedTo: any;
  relatedTo: string;
  finished: boolean;
  createdAt: string;
  createdBy: string;
  relatedTypeId: string;
  relatedToId: string;
  labels: ILabel[];
}

export enum PriorityTypeId {
  LOW = '1',
  MEDIUM = '2',
  HIGH = '3',
  VERY_HIGH = '4',
}

export enum TodoRelatedTypeId {
  QUOTATION = '1',
  SALES_ORDER = '2',
  SALES_INVOICE = '3',
  PURCHASE_ORDER = '4',
}

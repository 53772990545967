import { Select } from 'components/Select/Select';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { Option } from 'components/Select/type';
import {
  useGetUsersById,
  useGetUsersSummaryInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';

const USERS_PER_PAGE = 10;

interface IUsersSelectProps {
  handleValueChange: (e: any) => void;
  defaultValue: Option | number | string;
}

const UsersSelect = ({
  handleValueChange,
  defaultValue,
}: IUsersSelectProps) => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const {
    data: usersData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(USERS_PER_PAGE, debouncedSearchBy, true);

  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetched,
  } = useGetUsersById(
    [defaultValue?.toString() || ''],
    typeof defaultValue === 'string' || typeof defaultValue === 'number'
  );

  const isLoading = isLoadingUser || isLoadingUsers;

  const preselectedUser =
    typeof defaultValue === 'object'
      ? defaultValue
      : {
          label: isFetched
            ? userData?.user?.name + ' ' + userData?.user?.last_name
            : '',
          value: userData?.user?.id,
        };

  const users = useMemo(() => {
    if (usersData?.pages?.length) {
      return usersData.pages
        .map((page) => page.users)
        .flat()
        .map((user: any) => {
          return {
            value: user?.id,
            label: user.name + ' ' + user.last_name,
          };
        });
    }
    return [];
  }, [usersData]);

  return (
    <Select
      key={`${isLoadingUser}`}
      name="advancedFilterValue"
      placeholder={t('Value')}
      isMulti={false}
      isDisabled={false}
      isSearchable={true}
      defaultValue={preselectedUser}
      onChange={handleValueChange}
      onInputChange={(e: string) => setSearchBy(e)}
      onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
      options={users || []}
      labelTransform={(string: string) => capitalizeFirstLetter(string)}
      isLoading={isLoading}
    />
  );
};

export default UsersSelect;

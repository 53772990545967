import {
  CSSProperties,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Container,
  InputLabel,
  StyledInput,
  ErrorMessage,
  StyledTextareaInput,
  IconWrap,
  SymbolSpan,
  SymbolWrap,
} from './Input.styled';
import Placeholder from './Placeholder/Placeholder';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  style?: CSSProperties;
  error?: boolean;
  errorMessage?: string;
  wrapperStyles?: CSSProperties;
  isTextArea?: boolean;
  value?: string;
  icon?: any;
  pwId?: string;
  secure?: boolean;
  symbol?: string;
  maxWidth?: string;
}
const Input = ({ pwId, ...props }: IInput) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(
    !!props.defaultValue || props.defaultValue === 0
  );

  useEffect(() => {
    if (props.disabled && !inputRef?.current?.value) {
      setIsFocused(false);
    }
  }, [props.disabled]);

  return (
    <Container
      wrapperStyles={props?.wrapperStyles}
      onClick={() => {
        inputRef?.current?.focus();
        setIsFocused(true);
      }}
    >
      {props.label && (
        <InputLabel type={props.type} htmlFor={props.name}>
          {props.label}
        </InputLabel>
      )}
      {props.isTextArea ? (
        <StyledTextareaInput
          data-testid={pwId}
          ref={inputRef}
          {...props}
          id={props.name}
          style={props.style}
          onChange={(e: any) => {
            props.onChange && props.onChange(e);
            if (e.target.value === '') {
              setIsDirty(false);
            } else {
              setIsDirty(true);
            }
          }}
          onBlur={(e: any) => {
            props.onBlur && props.onBlur(e);
            setIsFocused(false);
          }}
          type={props.type || 'text'}
          spellcheck="false"
          placeholder="&nbsp;"
          secure={props.secure}
          errormessage={props.errorMessage}
        />
      ) : (
        <>
          <StyledInput
            data-testid={pwId}
            ref={inputRef}
            {...props}
            id={props.name}
            style={props.style}
            onChange={(e: any) => {
              props.onChange && props.onChange(e);
              if (e.target.value === '') {
                setIsDirty(false);
              } else {
                setIsDirty(true);
              }
            }}
            errormessage={props.errorMessage}
            onBlur={(e: any) => {
              props.onBlur && props.onBlur(e);
              setIsFocused(false);
            }}
            type={props.type || 'text'}
            secure={props.secure}
            placeholder="&nbsp;"
            $hasIcon={props.icon || props.symbol}
          />
          {props.icon && (
            <IconWrap>
              <Icon
                svg={props.icon}
                size={20}
                color={COLORS.GREY_1000}
                pointer={false}
              />
            </IconWrap>
          )}
          {props.symbol && (
            <SymbolWrap>
              <SymbolSpan>{props.symbol}</SymbolSpan>
            </SymbolWrap>
          )}
        </>
      )}
      <Placeholder
        isTranslatedToTop={isFocused || isDirty || !!props.value}
        isFormikPlaceholder={false}
        isSelectPlaceholder={true}
        isTextAreaPlaceholder={props.isTextArea}
        placeholder={props.placeholder}
      />
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default Input;

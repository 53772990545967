import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { IFwAppointmentLine } from 'types/Appointment.types';

const mapObjectFieldsIntoArray = (attributesObject: object) => {
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};

export const getInitialProductsData = (
  appointmentLine: IFwAppointmentLine | null
) => {
  if (appointmentLine) {
    const productFormDTOObj: ICreateQuotationProductFormDTO = {
      supplier: appointmentLine.supplier,
      product: appointmentLine.stock_item_line
        ? {
            name: appointmentLine.stock_item?.name,
          }
        : {
            name: appointmentLine.product.name,
            id: appointmentLine.product.default_product_id,
          },
      placement: appointmentLine.placement,
      description: appointmentLine.description,
      salesPrice: appointmentLine.stock_item_line
        ? appointmentLine.product_sales_price.toString()
        : appointmentLine.product?.price
        ? appointmentLine.product?.price
        : '',
      purchasePrice: appointmentLine.stock_item_line
        ? appointmentLine.product_purchase_price.toString()
        : appointmentLine.product?.purchase_price
        ? appointmentLine.product?.purchase_price
        : '',
      quantity: appointmentLine.quantity.toString(),
      discount: appointmentLine.discount.amount.toString(),
      discountType: appointmentLine.discount.type,
      attributes: appointmentLine.product?.attributes
        ? mapObjectFieldsIntoArray(appointmentLine.product.attributes)
        : [],
      logyxConfigurationIntent: appointmentLine.product
        ?.logyx_configuration_intent
        ? appointmentLine.product?.logyx_configuration_intent
        : null,
      isStockItem: !!appointmentLine.stock_item_line,
      stockItemId:
        appointmentLine.stock_item_line && appointmentLine.stock_item?.id
          ? Number(appointmentLine.stock_item.id)
          : undefined,
      ...(appointmentLine.stock_item_line && {
        stockItemType: appointmentLine.stock_item?.type.name,
      }),
    };

    if (appointmentLine.product?.attributes) {
      for (const addedAttributeKey of Object.keys(
        appointmentLine.product.attributes
      )) {
        productFormDTOObj.product[addedAttributeKey] =
          appointmentLine.product.attributes[addedAttributeKey];
      }
    }

    return [productFormDTOObj];
  } else {
    return [];
  }
};

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductTab from 'pages/Manager/NewQuotationPage/ProductTab/ProductTab';
import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { useEffect, useState } from 'react';
import OverviewTab from 'pages/Manager/NewQuotationPage/OverviewTab/OverviewTab';
import {
  useEditFwAppointmentLine,
  useAddFwAppointmentLine,
  useGetPrepopulateLineData,
  useGetAppointmentType,
} from './hooks';
import { isNumeric } from 'utils/validation';
import { getInitialProductsData } from './helpers';
import { FwNewAppointmentLineTab } from './constants';
import {
  AddNewProductLabel,
  Container,
  LoaderWrapper,
} from './FwNewAppointmentLinePage.styled';
import {
  AppointmentType,
  ICreateFwAppointmentLineDTO,
  IEditFwAppointmentLineDTO,
} from 'types/Appointment.types';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';
import PendingAppointmentsModal from 'pages/Manager/SingleSalesOrder/NewSalesOrderLinePage/PendingAppointmentsModal/PendingAppointmentsModal';
import Loader from 'components/Loader/Loader';
import { useGetPendingAppointments } from 'pages/Manager/SingleSalesOrder/NewSalesOrderLinePage/hooks';

const FwNewAppointmentLinePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const [prepopulateLineData, canAddOnlyStockItems, salesOrderId] =
    useGetPrepopulateLineData();

  const [activeTab, setActiveTab] = useState<string>(
    FwNewAppointmentLineTab.PRODUCTS
  );
  const [productFormDtos, setProductFormDtos] = useState<
    ICreateQuotationProductFormDTO[]
  >(getInitialProductsData(prepopulateLineData));

  const appointmentType: AppointmentType = useGetAppointmentType(id!);

  useSocketConnection();

  const { mutate: addAppointmentLine, status: addStatus } =
    useAddFwAppointmentLine(id!);
  const { mutate: editAppointmentLine, status: editStatus } =
    useEditFwAppointmentLine(id!, prepopulateLineData?.id);

  useEffect(() => {
    if (addStatus === 'success' || editStatus === 'success') {
      navigate(-1);
    }
  }, [addStatus, editStatus]);

  const [includeInAppointmentId, setIncludeInAppointmentId] = useState<
    number | null
  >(null);
  const [isPendingAppointmentsModalOpen, setIsPendingAppointmentsModalOpen] =
    useState<boolean>(false);

  // When creating new appointment line, we need to check for existence of future appointments
  const {
    mutate: getPendingAppointments,
    data: pendingAppointmentsData,
    isLoading: isLoadingPendingAppointments,
    reset: resetGetPendingAppointments,
  } = useGetPendingAppointments();

  const handleSubmit = () => {
    if (prepopulateLineData) {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }
      const editSalesOrderLineData: IEditFwAppointmentLineDTO = {
        ...(!productFormDto.isStockItem &&
          !prepopulateLineData && {
            product_id:
              prepopulateLineData && productFormDto.product.default_product_id
                ? productFormDto.product.default_product_id
                : productFormDto.product.id,
          }),
        quantity: Number(productFormDto.quantity),
        product_attributes: productAttributes,
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        product_sales_price: Number(productFormDto.salesPrice),
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? Number(productFormDto.purchasePrice)
          : 0,
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
      };
      editAppointmentLine(editSalesOrderLineData);
    } else {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }
      const createSalesOrderLineData: ICreateFwAppointmentLineDTO = {
        include_in_appointment_id: includeInAppointmentId,
        supplier_id: Number(productFormDto.supplier.id),
        ...(!productFormDto.isStockItem &&
          !prepopulateLineData && {
            product_id:
              prepopulateLineData && productFormDto.product.default_product_id
                ? productFormDto.product.default_product_id
                : productFormDto.product.id,
          }),
        ...(productFormDto.stockItemId && {
          stock_item_id: productFormDto.stockItemId,
        }),
        quantity: Number(productFormDto.quantity),
        product_attributes: productAttributes,
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? Number(productFormDto.purchasePrice)
          : 0,
        product_sales_price: Number(productFormDto.salesPrice),
        ...(appointmentType !== AppointmentType.MEASURING && {
          deduct_stock: true,
        }),
      };
      addAppointmentLine(createSalesOrderLineData);
    }
  };

  return (
    <Container>
      {isLoadingPendingAppointments && (
        <LoaderWrapper>
          <Loader positionType="relative" />
        </LoaderWrapper>
      )}
      <AddNewProductLabel>
        {prepopulateLineData ? t('Edit product') : t('Add new product')}
      </AddNewProductLabel>
      {activeTab === FwNewAppointmentLineTab.PRODUCTS && (
        <ProductTab
          onForward={() => {
            if (appointmentType === AppointmentType.MEASURING && salesOrderId) {
              getPendingAppointments(Number(salesOrderId), {
                onSuccess: (data) => {
                  if (!data?.pending_appointments?.length) {
                    setActiveTab(FwNewAppointmentLineTab.OVERVIEW);
                    resetGetPendingAppointments();
                  } else {
                    setIsPendingAppointmentsModalOpen(true);
                  }
                },
              });
            } else setActiveTab(FwNewAppointmentLineTab.OVERVIEW);
          }}
          onBack={() => {
            if (prepopulateLineData) {
              navigate(location.pathname.replace('/add-line', '/products'));
            } else {
              navigate(-1);
            }
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
          canAddAnotherProduct={false}
          canAddOnlyStockItems={canAddOnlyStockItems}
          withoutPrices
        />
      )}
      {activeTab === FwNewAppointmentLineTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(FwNewAppointmentLineTab.PRODUCTS)}
          onForward={() => handleSubmit()}
          customerDetails={null}
          productFormDtos={productFormDtos}
          orderDetails={null}
          status={addStatus || editStatus}
          newlyCreatedQuotationId={''}
          withoutPrices
        />
      )}
      <PendingAppointmentsModal
        isOpen={isPendingAppointmentsModalOpen}
        setIsOpen={setIsPendingAppointmentsModalOpen}
        appointments={pendingAppointmentsData?.pending_appointments || []}
        onCancel={() => {
          setIsPendingAppointmentsModalOpen(false);
          setActiveTab(FwNewAppointmentLineTab.OVERVIEW);
          setIncludeInAppointmentId(null);
          resetGetPendingAppointments();
        }}
        onSelect={(appointmentId: number) => {
          setIsPendingAppointmentsModalOpen(false);
          setIncludeInAppointmentId(appointmentId);
          setActiveTab(FwNewAppointmentLineTab.OVERVIEW);
          resetGetPendingAppointments();
        }}
      />
    </Container>
  );
};

export default FwNewAppointmentLinePage;

import { ICountry } from 'store/Common/types';

export const useGetInitialCountry = (): ICountry => {
  // Replaced useSelector + useMemo calls with hardcoded initial country
  const initialCountry: ICountry = {
    region: {
      name: 'Europe',
      is_europe: true,
      id: 2,
    },
    cca2: 'NL',
    currency: {
      symbol: '€',
      code: 'EUR',
      id: 1,
      name: 'Euro',
    },
    id: 75,
    name: 'Netherlands',
  };

  return initialCountry;
};

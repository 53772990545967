import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { IQuotationLine } from 'types/Quotations.types';

const mapObjectFieldsIntoArray = (attributesObject: object) => {
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};

export const getInitialProductsData = (
  quotationLine: IQuotationLine | null
) => {
  if (quotationLine) {
    const productFormDTOObj: ICreateQuotationProductFormDTO = {
      supplier: quotationLine.supplier,
      product: quotationLine.stock_item_line
        ? {
            name: quotationLine.stock_item?.name,
          }
        : {
            name: quotationLine.product.name,
            id: quotationLine.product.default_product_id,
          },
      placement: quotationLine.placement,
      description: quotationLine.description,
      salesPrice: quotationLine.stock_item_line
        ? quotationLine.product_sales_price.toString()
        : quotationLine.product?.price
        ? quotationLine.product?.price
        : '',
      purchasePrice: quotationLine.stock_item_line
        ? quotationLine.product_purchase_price.toString()
        : quotationLine.product?.purchase_price
        ? quotationLine.product?.purchase_price
        : '',
      quantity: quotationLine.quantity.toString(),
      discount: quotationLine.discount.amount.toString(),
      discountType: quotationLine.discount.type,
      attributes: quotationLine.product?.attributes
        ? mapObjectFieldsIntoArray(quotationLine.product.attributes)
        : [],
      logyxConfigurationIntent: quotationLine.product
        ?.logyx_configuration_intent
        ? quotationLine.product?.logyx_configuration_intent
        : null,
      isStockItem: !!quotationLine.stock_item_line,
      stockItemId:
        quotationLine.stock_item_line && quotationLine.stock_item?.id
          ? Number(quotationLine.stock_item.id)
          : undefined,
      ...(quotationLine.stock_item_line && {
        stockItemType: quotationLine.stock_item?.type.name,
      }),
    };

    if (quotationLine.product?.attributes) {
      for (const addedAttributeKey of Object.keys(
        quotationLine.product.attributes
      )) {
        productFormDTOObj.product[addedAttributeKey] =
          quotationLine.product.attributes[addedAttributeKey];
      }
    }

    return [productFormDTOObj];
  } else {
    return [];
  }
};

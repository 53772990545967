import {
  CardContainer,
  TimelineCard,
  BackgroundColorWrapper,
  Heading,
  Container,
  SwiperWrapper,
} from './SingleSalesOrderGeneralPage.styled';
import { useGetSingleSalesOrder } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import moment from 'moment';
import { CLOSED_STATUS, EMPTY_PRICE } from '../constants';
import { formatNumber } from 'utils/numberFormatter';
import CustomSwiper from 'components/CustomSwiper/CustomSwiper';
import { useState } from 'react';
import { EditOrderDetailsModal } from './EditOrderDetailsModal/EditOrderDetailsModal';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { useGetSingleSalesOrderAppointments } from '../SingleSalesOrderAppointmentsPage/hooks';
import { useGetSingleSalesOrderInvoices } from '../SingleSalesOrderInvoicesPage/hooks';
import { INVOICE_NEW, INVOICE_PARTLY_PAID, INVOICE_SENT } from './constants';
import { IAppointment } from 'types/Appointment.types';
import { useGetHistoryItems } from './hooks';
import { EditCustomerDetailsModal } from 'components/Modal/EditCustomerDetailsModal/EditCustomerDetailsModal';
import { EditCustomerDetailsType } from 'components/Modal/EditCustomerDetailsModal/constants';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useLocale } from 'utils/hooks/useLocale';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SingleSalesOrderGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const currencySymbol = useGetCurrencySymbol();
  const canEditSalesOrder = useCan(Actions.EDIT_SALES_ORDER);
  const { data: salesOrder, isLoading } = useGetSingleSalesOrder(
    typeof id === 'string' ? id : ''
  );

  const { data: salesOrderAppointments } = useGetSingleSalesOrderAppointments(
    id!
  );
  const { data: salesOrderInvoices } = useGetSingleSalesOrderInvoices(id!);

  const [isEditOrderDetailsModalOpen, setIsEditOrderDetailsModalOpen] =
    useState<boolean>(false);

  const linkedInvoices = (): boolean => {
    return salesOrderInvoices?.sales_invoices?.find(
      (invoice: any) =>
        invoice.payment_status.name === INVOICE_NEW ||
        invoice.payment_status.name === INVOICE_PARTLY_PAID ||
        invoice.payment_status.name === INVOICE_SENT
    )
      ? true
      : false;
  };

  const linkedAppointments = (): boolean => {
    const now = new Date().toISOString();
    return salesOrderAppointments?.appointments?.find(
      (appointment: IAppointment) => appointment.date_from > now
    )
      ? true
      : false;
  };

  const [isEditCustomerDetailsModalOpen, setIsEditCustomerDetailsModalOpen] =
    useState<boolean>(false);

  const [
    isEditingCustomerDetailsFailedModalOpen,
    setIsEditingCustomerDetailsFailedModalOpen,
  ] = useState<boolean>(false);

  const orderDetailsCardLeftData = {
    'Sales order number': salesOrder?.number,
    'Creation date': salesOrder?.date
      ? moment(salesOrder?.date?.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    'Closed date': salesOrder?.closed_date?.split('T')?.[0],
    'Sales person': salesOrder?.user?.name + ' ' + salesOrder?.user?.last_name,
    Reference: salesOrder?.reference,
    'Payment condition': salesOrder?.payment_condition,
  };
  const orderDetailsCardRightData = {
    'Total sales amount': `${currencySymbol}${formatNumber(
      salesOrder?.total_amount
    )}`,
    'Open sales amount': `${currencySymbol}${formatNumber(
      salesOrder?.open_amount
    )}`,
    'Received sales amount': `${currencySymbol}${formatNumber(
      salesOrder?.total_amount_paid
    )}`,
    'Credit amount': salesOrder?.total_credit_amount
      ? `${currencySymbol}${formatNumber(
          Math.abs(salesOrder?.total_credit_amount)
        )}`
      : `${currencySymbol}${EMPTY_PRICE}`,
    'Total discount': salesOrder?.total_discount_amount
      ? `${currencySymbol}${formatNumber(salesOrder?.total_discount_amount)}`
      : `${currencySymbol}${EMPTY_PRICE}`,
    'Total purchase cost exc. VAT': `${currencySymbol}${formatNumber(
      salesOrder?.total_cost
    )}`,
    'Expected profit': `${salesOrder?.expected_profit}%`,
    'Down payment': salesOrder?.downpayment
      ? `${currencySymbol}${formatNumber(salesOrder?.downpayment)}`
      : '',
    'Unbilled amount': `${currencySymbol}${formatNumber(
      salesOrder?.unbilled_amount
    )}`,
  };

  const customerDetailsCardLeftData = {
    Number: salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.customer_number
      : salesOrder?.customer?.number,
    Name: salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.first_name +
        ' ' +
        salesOrder?.customer_snapshot?.last_name
      : salesOrder?.customer?.name + ' ' + salesOrder?.customer?.last_name,
    Address: salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.address?.full_address
      : salesOrder?.customer?.address?.full_address,
    'Company name': salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.company_name
      : salesOrder?.customer?.company_name,
    'Company VAT': salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.company_vat
      : salesOrder?.customer?.vat_number,
    customerID: salesOrder?.customer_id,
  };
  const customerDetailsCardRightData = {
    'E-mail': salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.email
      : salesOrder?.customer?.email,
    'Phone number': salesOrder?.customer_snapshot
      ? salesOrder?.customer_snapshot?.phone_number
      : salesOrder?.customer?.phone,
  };

  const showMoreData = {
    Name:
      salesOrder?.customer_snapshot?.shipping_data?.first_name +
      ' ' +
      salesOrder?.customer_snapshot?.shipping_data?.last_name,
    'E-mail': salesOrder?.customer_snapshot?.shipping_data?.email,
    Address: `${salesOrder?.customer_snapshot?.shipping_data?.address?.full_address}`,
    'Phone number': salesOrder?.customer_snapshot?.shipping_data?.phone,
  };

  const { historyItems, isFetchedHistoryItems } =
    useGetHistoryItems(salesOrder);

  return (
    <Container className="single-sales-order-step-5">
      <CardContainer>
        {salesOrder && (
          <SimpleWideCard
            cardTitle={t('Customer details')}
            leftSubtitle={t('GENERAL')}
            rightSubtitle={t('CONTACT')}
            leftData={customerDetailsCardLeftData}
            rightData={customerDetailsCardRightData}
            showMoreData={showMoreData}
            isLoading={isLoading}
            editable={canEditSalesOrder}
            onEditClick={
              salesOrder?.status?.name !== CLOSED_STATUS
                ? () =>
                    linkedAppointments() || linkedInvoices()
                      ? setIsEditingCustomerDetailsFailedModalOpen(true)
                      : setIsEditCustomerDetailsModalOpen(true)
                : undefined
            }
          />
        )}

        <SimpleWideCard
          cardTitle={t('Order details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('PRICE')}
          leftData={orderDetailsCardLeftData}
          rightData={orderDetailsCardRightData}
          isLoading={isLoading}
          editable={canEditSalesOrder}
          onEditClick={
            salesOrder?.status?.name !== CLOSED_STATUS
              ? () => setIsEditOrderDetailsModalOpen(true)
              : undefined
          }
        />
        <TimelineCard>
          <BackgroundColorWrapper>
            <Heading>{t('Timeline')}</Heading>

            {isFetchedHistoryItems && historyItems?.length && (
              <SwiperWrapper>
                <CustomSwiper historyItems={historyItems} />
              </SwiperWrapper>
            )}
          </BackgroundColorWrapper>
        </TimelineCard>
      </CardContainer>
      {salesOrder && isEditOrderDetailsModalOpen && (
        <EditOrderDetailsModal
          isOpen={isEditOrderDetailsModalOpen}
          setIsOpen={setIsEditOrderDetailsModalOpen}
          onCancel={() => setIsEditOrderDetailsModalOpen(false)}
          salesOrderData={salesOrder}
        />
      )}
      {salesOrder && isEditCustomerDetailsModalOpen && (
        <EditCustomerDetailsModal
          data={salesOrder}
          setIsOpen={setIsEditCustomerDetailsModalOpen}
          isOpen={isEditCustomerDetailsModalOpen}
          onCancel={() => setIsEditCustomerDetailsModalOpen(false)}
          type={EditCustomerDetailsType.SALES_ORDER}
        />
      )}
      <OkModal
        isOpen={isEditingCustomerDetailsFailedModalOpen}
        setIsOpen={setIsEditingCustomerDetailsFailedModalOpen}
        description={t(
          'It is not possible to edit customer details when there is an active sales invoice or appointment linked to the sales order'
        )}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsEditingCustomerDetailsFailedModalOpen(false)}
      />
    </Container>
  );
};

export default SingleSalesOrderGeneralPage;

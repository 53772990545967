import { subMenuItems } from './constants';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import {
  TitleContainer,
  ButtonContainer,
  Wrapper,
  Container,
  LeftSide,
  H3,
  StatusContainer,
  StatusOptionsContainer,
  PenImg,
  Option,
  P,
  HeaderWrapper,
  RouterWrapper,
  LabelContainer,
  LabelWrapper,
  AddLabelDropdownWrapper,
  SpinnerWrapper,
} from './SinglePurchaseInvoicePage.styled';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useChangePurchaseInvoiceStatus,
  useExportPurchaseInvoice,
  useGetSinglePurchaseInvoice,
  useGetSinglePurchaseInvoiceInfo,
} from './hooks';
import SinglePurchaseInvoiceRouter from 'navigation/SingleInvoice/PurchaseInvoice/SinglePurchaseInvoiceRouter';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { useGetPurchaseInvoiceStatuses } from 'utils/hooks/useGetPurchaseInvoiceStatuses';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import { CaretLeft, PencilSimple } from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { ILabelDataDTO, LabelEntityTypeId } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import {
  useDeleteLabelFromEntity,
  useGetEntityLabels,
} from 'pages/Manager/LabelsPage/hooks';
import { formatLabelsData } from 'pages/Manager/LabelsPage/helpers';
import Spinner from 'components/Spinner/Spinner';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { useCalculateHeaderHeight } from 'utils/hooks/useCalculateHeaderHeight';

const SinglePurchaseInvoicePage = () => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { isError: isErrorPurchaseInvoice, data } = useGetSinglePurchaseInvoice(
    typeof id === 'string' ? id : ''
  );

  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  const statusOptions = useGetPurchaseInvoiceStatuses();
  const { mutate: exportPurchaseInvoice } = useExportPurchaseInvoice();
  const { mutate: changePurchaseInvoiceStatus } =
    useChangePurchaseInvoiceStatus();

  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditPurchaseInvoice && setIsStatusModalOpen(false)
  );

  const { data: infoData } = useGetSinglePurchaseInvoiceInfo(id!);

  const { data: labels, isLoading: isLoadingLabels } = useGetEntityLabels(
    id!,
    LabelEntityTypeId.PURCHASE_INVOICE
  );

  let formattedLabels: ILabelDataDTO[] = [];
  if (data) {
    formattedLabels = formatLabelsData(labels);
  }

  const { mutate: deleteLabelFromEntity } = useDeleteLabelFromEntity(
    LabelEntityTypeId.PURCHASE_INVOICE,
    Number(id)
  );

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet } = useBreakpointFlag();
  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  const { headerHeight, headerWrapperRef } = useCalculateHeaderHeight();

  return !isErrorPurchaseInvoice ? (
    <Container>
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(
          infoData?.purchase_invoice_info
        )}
      />
      <Wrapper>
        <HeaderWrapper
          ref={headerWrapperRef}
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.Invoices.fullPath)}
              />
              <H3>
                {t('Invoice')}&nbsp;
                {data?.purchase_invoice?.invoice_number}
              </H3>
              <StatusContainer ref={statusContainerWrapperRef}>
                <P
                  onClick={() =>
                    canEditPurchaseInvoice &&
                    setIsStatusModalOpen(!isStatusModalOpen)
                  }
                >
                  {capitalizeFirstLetter(
                    t(data?.purchase_invoice?.payment_status?.name)
                  )}
                </P>
                {canEditPurchaseInvoice && (
                  <PenImg>
                    <Icon
                      onClick={() => setIsStatusModalOpen(!isStatusModalOpen)}
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                    />
                  </PenImg>
                )}
                {isStatusModalOpen && (
                  <StatusOptionsContainer>
                    {statusOptions.map((option) => {
                      return (
                        <Option
                          isSelected={
                            option.value ===
                            data?.purchase_invoice?.payment_status?.name
                          }
                          key={option.value}
                          onClick={() => {
                            changePurchaseInvoiceStatus({
                              id: data?.purchase_invoice?.id || '',
                              status_id: option.id,
                            });
                            setIsStatusModalOpen(false);
                          }}
                        >
                          {capitalizeFirstLetter(t(option.label))}
                        </Option>
                      );
                    })}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>
            </LeftSide>
          </TitleContainer>

          <LabelContainer $noLabels={formattedLabels?.length === 0}>
            <LabelWrapper>
              {isLoadingLabels ? (
                <SpinnerWrapper>
                  <Spinner size={15} />
                </SpinnerWrapper>
              ) : (
                <>
                  {formattedLabels.map((labelObj: ILabelDataDTO) => {
                    return (
                      <EntityLabel
                        key={labelObj.label.id}
                        label={labelObj.label}
                        tooltipContent={
                          labelObj.label.name.length > 15
                            ? labelObj.label.name
                            : labelObj.label.description
                        }
                        onDelete={() => {
                          deleteLabelFromEntity(labelObj.label.id);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </LabelWrapper>
            <AddLabelDropdownWrapper
              $withoutMargin={formattedLabels?.length === 0}
            >
              <AddLabelDropdown
                entityType={LabelEntityTypeId.PURCHASE_INVOICE}
                entityId={id!}
              />
            </AddLabelDropdownWrapper>
          </LabelContainer>

          <ButtonContainer>
            <Button
              label={t('Add Payment')}
              secondary
              disabled={
                !canEditPurchaseInvoice || !data?.purchase_invoice.open_amount
              }
              width="200rem"
              onClick={() => setIsPaymentModalOpen(true)}
            />
            {/* <Button       Temporarily removed
            onClick={() =>
              navigate(SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceNewCreditInvoice.path, {
                state: { prepopulateCreditInvoiceData: data },
              })
            }
            label={t('New credit invoice')}
            width="200rem"
            secondary
          /> */}
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper
          headerHeight={headerHeight}
          routerWithoutPaddingBottom={routerWithoutPaddingBottom}
        >
          <SinglePurchaseInvoiceRouter />
        </RouterWrapper>
      </Wrapper>
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.purchase_invoice?.id}
        invoiceType={InvoiceType.PURCHASE_INVOICE}
        initialPaymentAmount={
          data?.purchase_invoice?.total_amount -
          data?.purchase_invoice?.total_amount_payed
        }
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SinglePurchaseInvoicePage;

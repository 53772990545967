export enum SortDirection {
  NO_SORT = '',
  DESC = 'desc',
  ASC = 'asc',
}

export enum CellType {
  PERMISSIONS = 'permissions',
  ACCOUNTING = 'accounting',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
}

// Keys existing in tableData that will not be displayed as a column
export const excludedTableDataKeys: string[] = [
  'navigationID',
  'previewUrl',
  'customerID',
  'supplierID',
  'entityType',
  'entityId',
  'entity',
  'quotationOrSalesOrder',
  'userId',
  'invoiceId',
  'invoiceOpenAmount',
  'customerNumber',
  'fileFullName',
  'labels',
  'todoId',
  'relatedTypeId',
  'relatedToId',
  'excludedIsCredit',
];

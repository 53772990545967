export const mapSubSideMenuItemsInfo = (data: { [key: string]: number }) => {
  const subSideMenuLabels = (key: string) => {
    switch (key) {
      case 'comment_count':
        return 'Comments';
      case 'file_count':
        return 'Files';
      case 'appointment_count':
        return 'Appointments';
      case 'quotation_count':
        return 'Quotations';
      case 'report_count':
        return 'Reports';
      case 'line_count':
        return 'Products & Services';
      case 'sales_order_count':
        return 'Sales orders';
      case 'purchase_order_count':
        return 'Purchase orders';
      case 'invoice_count':
        return 'Invoices';
      case 'credit_invoice_count':
        return 'Credit invoices';
      case 'payment_count':
        return 'Payments';
      default:
        return key;
    }
  };

  return data
    ? Object.keys(data).reduce((subSideMenuItemsInfo, key) => {
        subSideMenuItemsInfo[subSideMenuLabels(key)] = data[key];
        return subSideMenuItemsInfo;
      }, {})
    : [];
};

import { useTranslation } from 'react-i18next';
import { IPurchaseInvoice, ISalesInvoice } from 'types/Invoice.types';
import { formatNumber } from 'utils/numberFormatter';

export interface IAddPaymentsInvoiceTableDTO {
  number: string;
  customer: string;
  reference: string;
  status: string;
  openAmount: string;
  invoiceId: string;
  invoiceOpenAmount: string;
  excludedIsCredit: boolean;
}

export const formatInvoicesTableData = (
  invoiceData: ISalesInvoice[] | IPurchaseInvoice[],
  currencySymbol: string
): IAddPaymentsInvoiceTableDTO[] => {
  const { t } = useTranslation();
  return invoiceData?.map((invoice: ISalesInvoice | IPurchaseInvoice) => {
    const invoiceTableDTO: any = {
      number: invoice?.invoice_number,
      customer: invoice?.customer
        ? `${invoice?.customer.name} ${invoice?.customer.last_name}`
        : '',
      customerNumber: invoice?.customer?.number,
      reference: invoice?.external_id,
      status: t(invoice?.payment_status.name),
      openAmount: `${currencySymbol}${formatNumber(invoice?.open_amount)}`,
      invoiceId: invoice?.id,
      invoiceOpenAmount: invoice?.open_amount,
      excludedIsCredit: invoice?.is_credit,
    };
    return invoiceTableDTO;
  });
};

import { ICustomer, ICustomerSnapshot } from './Customer.types';
import { IDiscount } from './Discount.types';
import { ILabel } from './EntityLabel.types';
import { IProduct } from './Product.types';
import { IQuotation, IShippingData } from './Quotations.types';
import { IStockItem } from './StockItem.types';
import { ISupplier } from './Supplier.types';
import { IUser } from './User.types';

export interface ISalesOrdersResponseDTO {
  id: string;
  number: string;
  payment_status: ISalesOrderPaymentStatus;
  status: ISalesOrderStatus;
  date: string;
  quotation: IQuotation; // For accessing reference
  customer: ICustomer;
  total_amount: string;
  reference?: any;
  sub_status: ISalesOrderStatus;
  unbilled_amount: number;
  labels: ILabel[];
}

export interface ISalesOrderStatus {
  id: string;
  name: string;
  value?: string; // Injected on fe
}

export interface ISalesOrderPaymentStatus {
  id: string;
  name: string;
}

export enum SalesOrderPaymentStatusIdEnum {
  NEW = 1,
  SENT = 2,
  PARTLY_PAID = 3,
  FULLY_PAID = 4,
  CANCELLED = 5,
  CLOSED = 6,
}

export interface ISalesOrderTableDTO {
  navigationID: string;
  customerID: number;
  number: string;
  payment: string;
  status: string;
  date: string;
  reference: string;
  customer: string;
  amount: string;
  sub_status: string;
  unbilledAmount: string;
  labels: ILabel[];
}

export interface ISalesOrderLine {
  already_purchased: boolean; // Visible when fetching lines-availability
  sub_status: ISubStatus;
  description: string;
  discount_amount: string;
  total_amount: string;
  delivery_status: ISalesOrderStatus;
  discount: IDiscount;
  id: string;
  payment_status: ISalesOrderStatus;
  placement: string;
  product?: IProduct;
  quantity: string;
  supplier: ISupplier;
  supplier_id: string;
  working_hours_line?: boolean;
  working_hours_rate?: string;
  product_sales_price: string;
  product_purchase_price: string;
  stock_item: IStockItem;
  stock_item_line: boolean;
  in_stock?: number;
  invoiced: boolean;
  service: IService;
  is_linked_invoice?: boolean;
  is_linked_purchase?: boolean;
  pending_purchase_amount: number;
  received_purchase_amount: number;
  take_from_stock: boolean;
  is_delivered: false;
  is_normal_stock_item: false;
  is_planned: false;
  is_service_stock_item: false;
  index: number;
}

export interface ISalesOrder {
  date: Date;
  user_id: number;
  delivery_status: string;
  status: ISalesOrderStatus;
  id: string;
  number: string;
  sales_order_lines: ISalesOrderLine[];
  total_amount: number;
  user: IUser;
  customer_id: number;
  customer: ICustomer;
  customer_snapshot: ICustomerSnapshot;
  quotation?: IQuotation;
  discount?: IDiscount;
  payment_status: ISalesOrderStatus;
  shipping_data: IShippingData;
  reference: string;
  downpayment: number;
  downpayment_invoice_created: boolean;
  quotation_id: string;
  different_shipping_address: boolean;
  unbilled_amount: number;
  labels: ILabel[];
}

export interface ISalesOrderStatus {
  id: string;
  name: string;
}

export interface ISubStatus {
  name: string;
  id: string;
}

export interface ISalesOrderStatuses {
  statuses?: ISalesOrderStatus[];
  sub_statuses?: ISalesOrderStatus[];
}

export interface IAddSalesOrderLineData {
  product_id?: string;
  quantity: string;
  product_attributes?: object;
  placement?: string;
  description?: string;
  product_purchase_price?: string;
  product_sales_price?: string;
  is_stock_item?: boolean;
  stock_item_id?: number;
  supplier_id?: number;
  discount: IDiscount;
  deduct_stock?: boolean;
  include_in_appointment_id: number | null;
}

export interface IEditSalesOrderLine {
  product_id?: string;
  quantity: string;
  product_attributes?: object;
  placement?: string;
  description?: string;
  product_purchase_price?: string;
  product_sales_price?: string;
  is_stock_item?: boolean;
  discount: IDiscount;
}

export interface IService {
  id: number;
  purpose: string;
  description: string;
  amount: number;
}

export interface ICreateSalesOrderData {
  payload: ICreateSalesOrderPayload;
  queryParams: ICreateSalesOrderQueryParamsForCustomer;
}

export interface ICreateSalesOrderQueryParamsForCustomer {
  force_create_customer: boolean;
}

export interface ICreateSalesOrderPayload {
  description: string;
  payment_condition: string;
  reference: string;
  sales_order_lines: ICreateSalesOrderLineDTO[];
  discount: IDiscount;
  customer_id?: string | null;
  customer: object;
  parent_quotation_id?: number;
  downpayment?: number;
  shipping_data: ICreateSalesOrderShippingData;
  label_ids?: number[];
}

export interface ICreateSalesOrderShippingData {
  first_name: string;
  last_name: string;
  phone: string;
  company_name: string;
  street: string;
  city: string;
  zip_code: string;
  house_number: string;
  country: string;
  email: string;
  title: string;
}

export interface ICreateSalesOrderLineDTO {
  supplier_id?: number;
  product_id?: string;
  stock_item_id?: number;
  quantity: number;
  discount: IDiscount;
  product_attributes?: object;
  logyx_configuration_intent_uuid?: string;
  placement?: string;
  working_hours_line?: boolean;
  working_hours_rate?: string;
  description?: string;
  product_purchase_price?: number;
  product_sales_price?: number;
  is_stock_item?: boolean;
}

import { Option } from 'components/Select/type';

export const useGetRecurringTypeOptions = () => {
  const options: Option[] = [
    { value: '1', label: 'Quotation only', key: 'QUOTATION_ONLY' },
    { value: '2', label: 'With sales order', key: 'WITH_SALES_ORDER' },
    { value: '3', label: 'With invoice', key: 'WITH_INVOICE' },
    { value: '4', label: 'With payment', key: 'WITH_PAYMENT' },
  ];
  return options;
};

export const useGetRecurringIntervalOptions = () => {
  const options: Option[] = [
    { value: '1', label: 'Weekly', key: 'weekly' },
    { value: '2', label: '4-Weekly', key: '4-weekly' },
    { value: '3', label: 'Monthly', key: 'monthly' },
    { value: '4', label: 'Half-Yearly', key: 'half-yearly' },
    { value: '5', label: 'Yearly', key: 'yearly' },
  ];
  return options;
};

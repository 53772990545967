import * as Yup from 'yup';

export const addNewLabelValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(1, 'Name must be at least 1 character')
    .max(50, 'Name must not exceed 50 characters'),
  group: Yup.number().required('Group is required'),
  color: Yup.string()
    .required('Color is required')
    .matches(
      /^#[0-9A-Fa-f]{6}$/,
      'Color must be a hex code in the format #RRGGBB.'
    ),
  description: Yup.string().max(
    250,
    'Description must not exceed 250 characters'
  ),
});

import { DatePicker as Calendar } from '@mui/x-date-pickers';
import moment from 'moment';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IDatePickerProps {
  pwId?: string;
  width?: string;
  date: any;
  setDate: any;
  onAccept?: any;
  label?: string;
}

const DatePicker = ({
  pwId,
  width,
  date,
  setDate,
  onAccept,
  label,
}: IDatePickerProps) => {
  const { isDesktop } = useBreakpointFlag();

  return (
    <Calendar
      data-testid={pwId}
      value={moment(date)}
      onChange={(newValue) => setDate(newValue)}
      onAccept={onAccept}
      sx={{
        width: width ? width : '300rem',
        '& .MuiInputBase-input': {
          height: isDesktop ? '20rem' : '15rem',
          fontSize: isDesktop ? '14px' : '13px',
        },
        '& svg': {
          fontSize: isDesktop ? '25rem' : '20rem',
        },
      }}
      slotProps={{
        actionBar: { actions: ['clear', 'today'] },
        textField: {
          size: 'small',
          label: label || '',
        },
      }}
    />
  );
};
export default DatePicker;

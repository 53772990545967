import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginSm, marginXs } from 'assets/styled';
import { NavLink } from 'react-router-dom';

interface StyledProps {
  isSelected: boolean;
  isSideMenuCollapsed: boolean;
  $isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.ERP_BLACK};
  width: 265rem;
  height: 100%;
  padding-top: 15rem;
  padding-bottom: 30rem;
  position: fixed;
  overflow: hidden;
  transition: all 0.3s;
  left: 0;
  top: 0;
  z-index: 3;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      width: 115rem;
    `}
`;

export const ButtonContainer = styled.div`
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
  overflow: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const ByCodeFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

export const ItemContainer = styled(NavLink)`
  width: ${(props: any) => (props.$isSideMenuCollapsed ? '115rem' : '265rem')};
  height: 50rem;
  user-select: none;
  background-color: ${COLORS.ERP_BLACK};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s;
  margin: ${marginXs} 0rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.04);
    transition: ease 200ms, width 0s;
    border-radius: ${borderRadiusSm};
  }
  &:not(:hover) {
    transition: ease 200ms, width 0s;
  }

  &:active,
  &.active {
    background-color: ${COLORS.PRIMARY};
  }

  ${(props: StyledProps) =>
    props.$isActive &&
    css`
      background-color: ${COLORS.PRIMARY};
    `}
`;

export const ItemText = styled.span`
  text-align: left;
  color: ${COLORS.STRICT_WHITE};
  font-size: 16rem;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      display: none;
    `}
`;

export const ByText = styled.div`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.STRICT_WHITE};
  margin-bottom: 5rem;
  margin-top: ${marginSm};
`;

export const ByCodeFlexImg = styled.img`
  height: 30rem;
`;

export const LogoImg = styled.img`
  margin-top: 5rem;
  height: 50rem;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      width: 40rem;
    `}
`;

export const RowCollapse = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 5rem;
  width: 100%;
`;

import { AddEmailNoteModalType } from './constants';
import { useGetSalesInvoiceAttachmentsPreview } from 'pages/Manager/SingleInvoice/SingleSalesInvoice/hooks';
import { useGetSalesOrderAttachmentsPreview } from 'pages/Manager/SingleSalesOrder/hooks';
import { useGetQuotationAttachmentsPreview } from 'pages/Manager/SingleQuotation/hooks';

interface IProps {
  id: string;
  type: AddEmailNoteModalType;
  entityType?: AddEmailNoteModalType;
}

export const useGetAttachmentsPreview = ({ id, type, entityType }: IProps) => {
  const { data: salesOrderData, isLoading: isLoadingSalesOrderAttachments } =
    useGetSalesOrderAttachmentsPreview(id, type, entityType);
  const { data: quotationData, isLoading: isLoadingQuotationAttachments } =
    useGetQuotationAttachmentsPreview(id, type, entityType);
  const {
    data: salesInvoiceData,
    isLoading: isLoadingSalesInvoiceAttachments,
  } = useGetSalesInvoiceAttachmentsPreview(id, type);

  let data;
  const isLoadingAttachments =
    isLoadingSalesOrderAttachments ||
    isLoadingQuotationAttachments ||
    isLoadingSalesInvoiceAttachments;

  switch (type) {
    case AddEmailNoteModalType.SALES_INVOICE:
      data = salesInvoiceData;
      break;
    case AddEmailNoteModalType.SALES_ORDER:
      data = salesOrderData;
      break;
    case AddEmailNoteModalType.QUOTATION:
      data = quotationData;
      break;
  }

  return { attachmentsData: data, isLoadingAttachments };
};

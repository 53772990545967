import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginSm,
  marginXs,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  color: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const SupplierCompanyNameLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginSm};
`;

export const PurchaseOrderLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
  padding: ${marginXs};
  width: 320rem;
  border: 1px solid ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      border: 1px solid ${COLORS.PRIMARY};
    `}
`;

export const PurchaseOrderLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginMd};
`;

export const FormLabel = styled.div`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  width: 100%;
  margin-top: 20rem;
  margin-bottom: 10rem;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
  margin-bottom: ${marginSm};
`;

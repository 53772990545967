import { QuotationTypeIdsEnum } from 'types/Quotations.types';

export const parseQuotationTypeStringIntoId = (
  type: string | undefined
): QuotationTypeIdsEnum | undefined => {
  if (!type) return undefined;
  switch (type) {
    case 'DRAFT':
      return QuotationTypeIdsEnum.DRAFT;
    case 'DEFINITIVE':
      return QuotationTypeIdsEnum.DEFINITIVE;
    case 'RECURRING':
      return QuotationTypeIdsEnum.RECURRING;
    default:
      return undefined;
  }
};

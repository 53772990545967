import { SingleValueProps } from 'react-select';
import { components } from 'react-select';
import { OptionContainer } from './ColorLabel.styled';
import { ColorSquare } from './ColorLabel.styled';

export const ColorLabelSingleValue = (props: SingleValueProps<any>) => {
  return (
    <components.SingleValue {...props}>
      <OptionContainer>
        {props.data.label}
        <ColorSquare color={props.data.value} />
      </OptionContainer>
    </components.SingleValue>
  );
};

import { AxiosError } from 'axios';
import { queryClient } from 'index';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  createCart,
  getAvailableSupplierOrders,
  getReconfigureLogyxURL,
  moveToExistingOrder,
  getLogyxOrder,
  startLogyxValidationForPurchaseOrder,
} from 'services/PurchaseOrder/PurchaseOrderService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import { IRootReducerState } from 'store/store';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import {
  ILogyxOrderLine,
  ILogyxOrderLineTableDTO,
  LogyxOrderStatus,
} from './ProductValidationTable/types';
import { formatLogyxOrderLinesIntoTableDTOs } from './ProductValidationTable/helpers';
import { areAnyLogyxLinesInStatusValidationInProgress } from './helpers';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { Base64 } from 'js-base64';

export const useStartLogyxValidationForPurchaseOrder = (
  purchaseOrderId: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.START_LOGYX_VALIDATION_FOR_PURCHASE_ORDER,
      purchaseOrderId,
    ],
    queryFn: () => {
      return startLogyxValidationForPurchaseOrder(purchaseOrderId);
    },
  });

export const useGetLogyxOrder = (purchaseOrderId: string) => {
  return useMutation(
    (logyxOrderId: string) => getLogyxOrder(logyxOrderId, purchaseOrderId),
    {
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GET_LOGYX_ORDER,
        });
      },
      mutationKey: ReactMutationKeys.GET_LOGYX_ORDER,
    }
  );
};

export const useCreatePurchaseOrderCart = (purchaseOrderId: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation(() => createCart(purchaseOrderId), {
    onSuccess: () => {
      toast.success(
        t(
          'Order has been placed successfully. Order details will become visible in a short period of time'
        ),
        {
          className: ReactMutationKeys.CREATE_PURCHASE_ORDER_CART,
        }
      );
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER);
      navigate(
        RoutesConfig.SinglePurchaseOrder.fullPath.replace(
          ':id/*',
          purchaseOrderId.toString()
        )
      );
    },
    onError: (error: AxiosError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.CREATE_PURCHASE_ORDER_CART,
      });
    },
    mutationKey: ReactMutationKeys.CREATE_PURCHASE_ORDER_CART,
  });
};

export const useGetReconfigureLogyxURL = (purchaseOrderId: string) => {
  return useMutation(
    (lineId: string) => getReconfigureLogyxURL(lineId, purchaseOrderId),
    {
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.RECONFIGURE_LOGYX,
        });
      },
      mutationKey: ReactMutationKeys.RECONFIGURE_LOGYX,
    }
  );
};

export const useGetAvailableSupplierOrders = () => {
  return useMutation(
    (supplierId: string) => getAvailableSupplierOrders(supplierId),
    {
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GET_AVAILABLE_SUPPLIER_ORDERS,
        });
      },
      mutationKey: ReactMutationKeys.GET_AVAILABLE_SUPPLIER_ORDERS,
    }
  );
};

interface IMoveToExistingOrderProps {
  selectedOrderId: number;
  selectedOrderLineId: any;
}

export const useMoveToExistingOrder = () => {
  const { t } = useTranslation();
  return useMutation(
    ({ selectedOrderId, selectedOrderLineId }: IMoveToExistingOrderProps) =>
      moveToExistingOrder(selectedOrderId, selectedOrderLineId),
    {
      onSuccess: () => {
        toast.success(t('Order is moved to existing order successfully'), {
          className: ReactMutationKeys.MOVE_TO_EXISTING_ORDER,
        });
        queryClient.invalidateQueries(
          ReactQueryKeys.START_LOGYX_VALIDATION_FOR_PURCHASE_ORDER
        );
      },
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.MOVE_TO_EXISTING_ORDER,
        });
      },
      mutationKey: ReactMutationKeys.MOVE_TO_EXISTING_ORDER,
    }
  );
};

export const useLogyxReconfigureFlow = (id: string) => {
  const {
    data,
    mutate: getReconfigureLogyxURL,
    isLoading: isLoadingGetReconfigureLogyxURL,
    isSuccess: isSuccessGetReconfigureLogyxURL,
    reset: resetGetReconfigureLogyxURL,
  } = useGetReconfigureLogyxURL(id);

  const { isMobileApp, darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );

  useEffect(() => {
    if (isSuccessGetReconfigureLogyxURL) {
      const base64Metadata = Base64.encode(
        JSON.stringify({
          companyId: companyId,
        })
      );
      const queryParamsObj = {
        isDarkMode: darkMode,
        base64Metadata,
      };
      const fullUrl: string = getUrlWithQueryParams(
        data.redirect_url,
        queryParamsObj
      );
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.OPEN_LOGYX,
          payload: fullUrl,
        };
        postMobileMessage(mobileMessage);
        resetGetReconfigureLogyxURL();
      } else {
        window.open(fullUrl, 'LOGYX_FORMS');
        resetGetReconfigureLogyxURL();
      }
    }
  }, [isSuccessGetReconfigureLogyxURL]);

  return getReconfigureLogyxURL;
};

const useFormatPurchaseOrderLinesFromNavigationStateIntoTableDTOs = (
  logyxOrderLines: ILogyxOrderLine[] | undefined // While logyx order is being fetched, the initial lines are temporarily displayed, this param is used exclude lines, that have been moved to different purchase orders, from initial lines
): ILogyxOrderLineTableDTO[] => {
  const { state } = useLocation();
  const initialPurchaseOrderLines: IPurchaseOrderLine[] | null =
    state.initialPurchaseLines || null;

  const [
    previousLogyxOrderLinePurchaseOrderLineIds,
    setPreviousLogyxOrderLinePurchaseOrderLineIds,
  ] = useState<number[] | null>(null); // If null, display all initial lines, this is the case when the direct order page is initially opened

  useEffect(() => {
    // Ignore case when logyxOrderLines is undefined (occurs when the getLogyxOrder is in progress)
    if (logyxOrderLines) {
      setPreviousLogyxOrderLinePurchaseOrderLineIds(
        logyxOrderLines
          ? logyxOrderLines.map((line) => Number(line.purchase_order_line.id))
          : []
      );
    }
  }, [logyxOrderLines]);

  const initialLogyxOrderLineTableDTOs: ILogyxOrderLineTableDTO[] =
    initialPurchaseOrderLines
      ? initialPurchaseOrderLines
          .filter((line: IPurchaseOrderLine) =>
            previousLogyxOrderLinePurchaseOrderLineIds === null
              ? true
              : previousLogyxOrderLinePurchaseOrderLineIds.includes(
                  Number(line.id)
                )
          )
          .map((line: IPurchaseOrderLine) => {
            const lineDto: ILogyxOrderLineTableDTO = {
              name: line.product.name,
              status: LogyxOrderStatus.VALIDATION_IN_PROGRESS,
              line: line,
            };
            return lineDto;
          })
      : [];
  return initialLogyxOrderLineTableDTOs;
};

const useHandleRetryLogyxOrderValidation = (
  retryLogyxOrderValidation: () => void,
  logyxOrderData: any
) => {
  const shouldRetryValidation = areAnyLogyxLinesInStatusValidationInProgress(
    logyxOrderData?.logyx_order?.logyx_order_lines
  );
  useEffect(() => {
    if (shouldRetryValidation) {
      const timeoutId = setTimeout(() => {
        retryLogyxOrderValidation();
      }, 5000); // Set a 5-second delay before retrying validation

      // Cleanup function to clear the timeout if the component unmounts or the data changes
      return () => clearTimeout(timeoutId);
    }
  }, [logyxOrderData, shouldRetryValidation]);
};

export default useHandleRetryLogyxOrderValidation;

// Create logyx order, run validation on its lines, and refetch the logyx order along with its lines after the validation is complete
export const useGetLogyxOrderLineTableDTOs = () => {
  const { id } = useParams();

  const {
    data: logyxOrderData,
    isLoading: isLogyxOrderLoading,
    mutate: getLogyxOrderMutation,
  } = useGetLogyxOrder(id!);

  const initialLogyxOrderLineTableDTOs: ILogyxOrderLineTableDTO[] =
    useFormatPurchaseOrderLinesFromNavigationStateIntoTableDTOs(
      logyxOrderData?.logyx_order?.logyx_order_lines
    );

  // 1)
  // Create logyx order and obtain its id
  const {
    data: startValidationData,
    isSuccess: isSuccessStartLogyxValidation,
    isFetching: isFetchingStartLogyxValidation,
    refetch: retryLogyxOrderValidation,
  } = useStartLogyxValidationForPurchaseOrder(id!);

  useHandleRetryLogyxOrderValidation(retryLogyxOrderValidation, logyxOrderData);

  // 2)
  // After starting logyx validation (logyx order lines have been validated),
  // refetch the logyx order to obtain the new logyx order line statuses
  useEffect(() => {
    if (isSuccessStartLogyxValidation && !isFetchingStartLogyxValidation) {
      getLogyxOrderMutation(startValidationData.logyx_order.id);
    }
  }, [isSuccessStartLogyxValidation, isFetchingStartLogyxValidation]);

  // 3)
  // Extract logyx order lines from the newly fetched logyx order
  // If there is no logyx order data, use the formatted initial purchase order lines passed through navigation state
  const logyxOrderLines: ILogyxOrderLine[] =
    logyxOrderData?.logyx_order?.logyx_order_lines;

  // 4)
  // Format logyx order lines into logyx order line table dtos
  const logyxOrderLineTableDTOs: ILogyxOrderLineTableDTO[] = useMemo(() => {
    if (!logyxOrderLines) {
      return initialLogyxOrderLineTableDTOs;
    } else {
      return formatLogyxOrderLinesIntoTableDTOs(logyxOrderLines);
    }
  }, [logyxOrderLines, initialLogyxOrderLineTableDTOs]);

  return {
    isLogyxOrderLoading,
    isFetchingStartLogyxValidation,
    logyxOrderLineTableDTOs,
    logyxOrderStatus: startValidationData?.logyx_order.status_id,
    retryLogyxOrderValidation,
  };
};

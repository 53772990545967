import { useMemo } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getQuotations,
  getQuotationsSummary,
} from 'services/Quotation/QuotationService';

export const useGetQuotationsInfinite = (
  perPage: number,
  searchBy?: string,
  isEnabled?: boolean,
  sortBy?: string,
  sortDirection?: string
) => {
  return useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATIONS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getQuotations(
        pageParam, // page
        perPage, // perPage
        undefined, // quotationStatuses
        undefined, // dateInterval
        undefined, // activeFilter
        undefined, // type_id
        searchBy, // searchBy,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });
};

export const useGetQuotationsSummaryInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATIONS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getQuotationsSummary(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetQuotationOptions = (
  quotationsData: InfiniteData<any> | undefined
) => {
  const quotationOptions = useMemo(() => {
    if (quotationsData?.pages?.length) {
      return quotationsData.pages
        .map((page: any) => page.quotations)
        .flat()
        .map((quotation: any) => {
          return {
            value: quotation.id,
            label: `${quotation.number}`,
            customer_id: `${quotation?.customer?.id}`,
          };
        });
    }
    return [];
  }, [quotationsData]);
  return quotationOptions;
};

import * as Yup from 'yup';

export const addToDoSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .min(1, 'Title should be between 1 and 100 characters')
      .max(100, 'Title should be between 1 and 100 characters'),
    description: Yup.string()
      .nullable()
      .max(300, 'Description should have maximum 300 characters'),
    priority_id: Yup.number().nullable().required('Priority is required'),
    type_id: Yup.number().nullable().required('Type is required'),
  });
};

export const editToDoSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .nullable()
      .min(1, 'Title should be between 1 and 100 characters')
      .max(100, 'Title should be between 1 and 100 characters'),
    description: Yup.string()
      .nullable()
      .max(300, 'Description should have maximum 300 characters'),
  });
};

import { Option } from 'components/Select/type';
import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Status',
    value: 'status',
    type: 'dropdown',
  },
  {
    label: 'Date',
    value: 'date',
    type: 'date',
  },
  {
    label: 'Reference',
    value: 'reference',
    type: 'string',
  },
  {
    label: 'Customer',
    value: 'customer',
    type: 'dropdown',
  },
  {
    label: 'Measuring date',
    value: 'measuring_date',
    type: 'date',
  },
  {
    label: 'Type',
    value: 'type',
    type: 'dropdown',
  },
  {
    label: 'Amount',
    value: 'amount',
    type: 'string',
  },
];

export const useGetQuotationsAdvancedFilters = (statusOptions: Option[]) => {
  const { recurring_quotations_enabled: recurringEnabled } = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const quotationTypeOptions = useMemo(() => {
    const options: Option[] = [
      { value: '1', label: 'Definitive' },
      { value: '2', label: 'Draft' },
    ];

    if (recurringEnabled) {
      options.push({ value: '3', label: 'Recurring' });
    }

    return options;
  }, [recurringEnabled]);

  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'status') {
      return { ...filter, options: statusOptions };
    } else if (filter.value === 'type') {
      return { ...filter, options: quotationTypeOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};

import apiClient from '../api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IEditResourceDTO } from 'pages/Manager/PlanningPage/ResourcesModal/type';
import { IAddResourceDTO } from 'pages/Manager/PlanningPage/ResourcesModal/AddResourcesForm/hooks';

const BASE_URL = '/v1/resources/';

export const getResources = async (
  page?: number,
  perPage?: number,
  searchBy?: string,
  dateFrom?: string,
  dateTo?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    date_from: dateFrom,
    date_to: dateTo,
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addResource = async (dto: IAddResourceDTO): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, {
    resources: [dto],
  });

  return data.data;
};

export const deleteResource = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const editResource = async (
  id: string,
  editedResource: IEditResourceDTO
): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.patch<any>(url, editedResource);

  return data.data;
};

export const getResourcesById = async (ids: string[]): Promise<any> => {
  const queryParamsObj = {
    resource_ids: ids,
  };
  const url = `${BASE_URL}by-ids`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getResourcesInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';
import { SortDirection } from 'components/Table/constants';
import { ICreateCreditInvoiceDTO } from 'pages/Manager/NewCreditInvoice/constants';
import i18n from 'providers/i18n/i18n';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import openApiClient from 'services/api/openApiService';
import {
  ICreateDownpaymentInvoiceDTO,
  ICreateSalesInvoiceDTO,
} from 'types/Invoice.types';
import { downloadBlob } from 'utils/downloadBlob';
import { openFileInNewTab } from 'utils/openFileInNewTab';

const BASE_URL = '/v1/sales-invoices/';

export const getSalesInvoices = async (
  page: number,
  perPage: number,
  searchBy?: string,
  filterNotPaid?: boolean,
  statusOptions?: string,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(searchBy && { search: searchBy }),
    ...(statusOptions && { invoice_statuses: statusOptions }),
    ...(dateInterval && { date_interval: dateInterval }),
    ...(sortBy &&
      sortDirection &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection &&
      sortDirection != SortDirection.NO_SORT && {
        sort_direction: sortDirection,
      }),
    ...(filterNotPaid && { filter_not_paid: filterNotPaid }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
    ...(selectedLabelIds && { label_ids: selectedLabelIds.join(',') }),
  };

  const fullUrl = getUrlWithQueryParams(BASE_URL, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSingleSalesInvoice = async (
  invoiceId: string
): Promise<any> => {
  const url = `${BASE_URL}${invoiceId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const createSalesInvoice = async (
  createInvoiceData: ICreateSalesInvoiceDTO | ICreateDownpaymentInvoiceDTO
): Promise<any> => {
  const { data } = await apiClient.post<any>(BASE_URL, createInvoiceData);

  return data.data;
};

export const getSalesInvoiceFiles = async (
  salesInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${salesInvoiceId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadSalesInvoiceFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deleteSalesInvoiceFile = async (
  salesInvoiceId: string,
  salesInvoiceFileId: string
) => {
  const url = BASE_URL + `${salesInvoiceId}/files/${salesInvoiceFileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const exportSalesInvoice = async (
  salesInvoiceId: string
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${salesInvoiceId}/export`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl, salesInvoiceId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const changeSalesInvoiceStatus = async (
  id: string,
  status_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/status`;
  const { data } = await apiClient.patch<any>(url, {
    new_status_id: status_id,
  });

  return data.data;
};

export const getSingleSalesInvoicePayments = async (
  salesInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${salesInvoiceId}/payments`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const createSalesCreditInvoice = async (
  createInvoiceData: ICreateCreditInvoiceDTO
): Promise<any> => {
  delete createInvoiceData.isForSalesInvoice;
  const { data } = await apiClient.post<any>(BASE_URL, createInvoiceData);

  return data.data;
};

export const getSingleSalesInvoiceCreditInvoices = async (
  salesInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${salesInvoiceId}/credit-invoices`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const sendSalesInvoiceEmail = async (
  salesInvoiceId: string,
  isPaymentReminder: boolean,
  note: string,
  files: any
) => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
    ...(isPaymentReminder && { payment_reminder: true }),
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${salesInvoiceId}/email-customer`,
    queryParamsObj
  );

  const formData = new FormData();
  const defaultAttachmentIds: string[] = [];

  if (note) formData.append('note', note);

  if (files && files.length) {
    files.forEach((file: any) => {
      if (file.id) {
        defaultAttachmentIds.push(file.id);
      } else {
        formData.append('files', file);
      }
    });
  }

  if (defaultAttachmentIds.length > 0) {
    formData.append(
      'default_attachment_ids',
      JSON.stringify(defaultAttachmentIds)
    );
  }

  const { data } = await apiClient.post<any>(fullUrl, formData);
  return data.data;
};

export const changeSalesInvoiceExternalId = async (
  id: string,
  external_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.patch<any>(url, {
    external_id: external_id,
  });

  return data.data;
};

export const getSuccessfulPaymentInfo = async (
  payment_intent_id: string,
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    payment_intent_id: payment_intent_id,
    token: token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}payment-intent`,
    queryParamsObj
  );

  const { data } = await openApiClient.get<any>(fullUrl);

  return data;
};

export const editSalesInvoiceFile = async (
  salesInvoiceId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${salesInvoiceId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const getSalesInvoiceInfoById = async (
  salesInvoiceId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesInvoiceId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const exportSalesInvoicesExcel = async (
  fileName: string
): Promise<any> => {
  const url = `${BASE_URL}export-excel`;

  const { data } = await apiClient.get<Blob>(url, {
    responseType: 'blob',
  });

  downloadBlob(data, fileName);

  return data;
};

export const getSalesInvoiceLatestEmailStatus = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/emails/latest-status`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSalesInvoiceAttachmentsPreview = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/attachments-preview`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSalesInvoiceCreationInfo = async (
  createInvoiceData:
    | ICreateSalesInvoiceDTO
    | ICreateDownpaymentInvoiceDTO
    | ICreateCreditInvoiceDTO
): Promise<any> => {
  const { data } = await apiClient.post<any>(
    `${BASE_URL}info`,
    createInvoiceData
  );

  return data.data;
};

export const connectToThirdParty = async (
  salesInvoiceId: number
): Promise<any> => {
  const { data } = await apiClient.post<any>(
    `${BASE_URL}${salesInvoiceId}/connect-to-third-party`
  );

  return data.data;
};

export const reorderSalesInvoiceLines = async (
  salesInvoiceId: number,
  dto: IReorderLinesRequestDTO
) => {
  const url = BASE_URL + `${salesInvoiceId}/reorder-lines`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};

export const markAsConnected = async (
  salesInvoiceId: number | string | null,
  {
    twinfieldTransactionNumber,
    twinfieldTransactionStatus,
  }: { twinfieldTransactionNumber: string; twinfieldTransactionStatus: number }
): Promise<any> => {
  if (salesInvoiceId == null) {
    return;
  }
  const { data } = await apiClient.patch<any>(
    `${BASE_URL}${salesInvoiceId}/mark-as-connected`,
    {
      twinfield_transaction_no: twinfieldTransactionNumber,
      twinfield_transaction_status: twinfieldTransactionStatus,
    }
  );

  return data.data;
};

import { CSSProperties } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { paddingXs, gapSm, borderRadiusSm } from 'assets/styled';

interface StyledProps {
  search: boolean;
  height: string;
  width: string;
  wrapperStyles?: CSSProperties;
  secure: boolean;
  $hasIcon?: boolean;
  maxWidth?: string;
}

interface IInputLabel {
  errormessage: boolean;
  type: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ wrapperStyles }: StyledProps) => wrapperStyles}
`;

export const InputLabel = styled.label`
  margin-bottom: 10rem;
  align-self: flex-start;
  font-weight: 700;
  font-size: 16rem;
  line-height: 16rem;
  color: ${COLORS.BLACK_200};

  ${({ type }: IInputLabel) =>
    type === 'file' &&
    css`
      margin-bottom: 0;
      display: flex;
      align-items: center;
      border: 1rem solid ${COLORS.GREY_300};
      background-color: ${COLORS.GREY_100};
      height: 100%;
      padding: 10rem;
      cursor: pointer;
    `}
`;

export const StyledInput = styled.input`
  height: ${(props: StyledProps) => props.height || '40rem'};
  border: none;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  border-radius: 8rem;
  flex-grow: 0;
  width: ${(props: StyledProps) => props.width};
  padding-left: 15rem;
  outline: none !important;
  transition: all 0.3s;
  padding-right: ${(props: StyledProps) => (props.$hasIcon ? '50rem' : '5rem')};
  max-width: ${(props: StyledProps) => (props.maxWidth ? props.maxWidth : '')};

  ::placeholder {
    font-size: 14rem;
    line-height: 21rem;
  }

  :hover {
    border-color: ${COLORS.BLACK};
    box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
  }

  :focus {
    border-color: ${COLORS.PRIMARY};
    box-shadow: 0 0 0 0.5rem ${COLORS.PRIMARY};
  }

  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}

  ${({ errormessage }: IInputLabel) =>
    errormessage && `border: 1rem solid ${COLORS.RED_100};`}

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}

  &:disabled {
    background-color: ${COLORS.GREY_200};
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      border: 1rem solid ${COLORS.GREY_1000};
    }
  }

  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
`;

export const StyledTextareaInput = styled.textarea`
  height: ${(props: StyledProps) => props.height || '150rem'};
  font-family: inherit;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  color: ${COLORS.BLACK};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}

  :hover {
    border-color: ${COLORS.BLACK};
    box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
  }

  :focus {
    border-color: ${COLORS.PRIMARY};
    box-shadow: 0 0 0 0.5rem ${COLORS.PRIMARY};
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}

  ${({ errormessage }: IInputLabel) =>
    errormessage && `border: 1rem solid ${COLORS.RED_100};`}

  &:disabled {
    background-color: ${COLORS.GREY_200};
    cursor: not-allowed;
  }

  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
`;

export const ErrorMessage = styled.div`
  position: absolute;
  left: 2rem;
  bottom: -15rem;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  margin-top: 5rem;
`;

export const IconWrap = styled.div`
  width: 23rem;
  height: 23rem;
  position: absolute;
  right: 10rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const SymbolWrap = styled.div`
  position: absolute;
  right: 10rem;
  top: 50%;
  transform: translateY(-50%);
`;
export const SymbolSpan = styled.span`
  font-size: 20rem;
  color: ${COLORS.GREY_1000};
`;

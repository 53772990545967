import { Close } from 'components/Close/Close';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import TableAlt from 'components/TableAlt/TableAlt';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPurchaseInvoices, useGetSalesInvoices } from '../hooks';
import {
  CloseContainer,
  Container,
  Description,
  RowSpaceBetween,
  SearchContainer,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './AddPaymentsModal.styled';
import {
  IAddPaymentsInvoiceTableDTO,
  formatInvoicesTableData,
} from './helpers';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useGetInvoiceTypeOptions } from './hooks';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { INVOICES_PER_PAGE, tableConfig } from './constants';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { MagnifyingGlass } from '@phosphor-icons/react';

interface IAddPaymentsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
}
export const AddPaymentsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
}: IAddPaymentsModalProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const invoiceOptions = useGetInvoiceTypeOptions();
  const [selectedOption, setSelectedOption] = useState<Option>(
    invoiceOptions[0]
  );
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>();
  const [selectedInvoiceOpenAmount, setSelectedInvoiceOpenAmount] = useState<
    number | null
  >();
  const [isSelectedInvoiceCreditInvoice, setIsSelectedInvoiceCreditInvoice] =
    useState<boolean>(true);

  const { data: salesInvoicesData, isLoading: isLoadingSalesInvoices } =
    useGetSalesInvoices(page, INVOICES_PER_PAGE, isOpen, searchBy, true);
  const { data: purchaseInvoicesData, isLoading: isLoadingPurchaseInvoices } =
    useGetPurchaseInvoices(page, INVOICES_PER_PAGE, isOpen, searchBy, true);

  let salesInvoicesCurrentData: any[] = [];
  let purchaseInvoicesCurrentData: any[] = [];

  salesInvoicesCurrentData = formatInvoicesTableData(
    salesInvoicesData?.sales_invoices
      ?.map((invoice: any[]) => {
        return invoice;
      })
      .flat(),
    currencySymbol
  );

  purchaseInvoicesCurrentData = formatInvoicesTableData(
    purchaseInvoicesData?.purchase_invoices
      ?.map((invoice: any[]) => {
        return invoice;
      })
      .flat(),
    currencySymbol
  );
  const handleButtonClick = (rowData: IAddPaymentsInvoiceTableDTO) => {
    setSelectedInvoiceId(Number(rowData?.invoiceId));
    setSelectedInvoiceOpenAmount(Number(rowData?.invoiceOpenAmount));
    setIsSelectedInvoiceCreditInvoice(rowData?.excludedIsCredit);
    setIsPaymentModalOpen(true);
  };

  const resetFields = () => {
    setPage(1);
    setSearchBy('');
    setSelectedInvoiceId(null);
    setIsSelectedInvoiceCreditInvoice(false);
    setSelectedInvoiceOpenAmount(null);
  };

  useEffect(() => {
    if (!isOpen && isPaymentModalOpen) {
      setIsPaymentModalOpen(false);
    }
  }, [isOpen, isPaymentModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={() => {
        resetFields();
        setSelectedOption(invoiceOptions[0]);
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        minWidth: '950rem',
      }}
    >
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              resetFields();
              setSelectedOption(invoiceOptions[0]);
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Add Payment')}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>
            {t('Select the invoice you wish to add the payment for')}.
          </Description>
        </TitleContainer>

        <RowSpaceBetween>
          <SearchContainer>
            <Input
              icon={MagnifyingGlass}
              width={'300rem'}
              placeholder={t('Search')}
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </SearchContainer>

          <SelectInputWrapper>
            <Select
              defaultValue={selectedOption ? selectedOption : undefined}
              name="type"
              placeholder={t('Type')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => {
                resetFields();
                setSelectedOption(e);
              }}
              options={invoiceOptions}
            />
          </SelectInputWrapper>
        </RowSpaceBetween>

        {isLoadingSalesInvoices || isLoadingPurchaseInvoices ? (
          <Loader />
        ) : (
          <TableAlt
            page={page}
            perPage={INVOICES_PER_PAGE}
            total={
              selectedOption?.value === InvoiceType.PURCHASE_INVOICE
                ? purchaseInvoicesData?.total
                : salesInvoicesData?.total
            }
            tableData={
              selectedOption?.value === InvoiceType.PURCHASE_INVOICE
                ? purchaseInvoicesCurrentData
                : salesInvoicesCurrentData
            }
            tableConfig={tableConfig}
            hasExtraAction={true}
            loadPage={(newPage) => setPage(newPage)}
            onButtonClick={handleButtonClick}
          />
        )}
      </Container>
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={selectedInvoiceId!}
        invoiceType={
          selectedOption?.value === InvoiceType.PURCHASE_INVOICE
            ? isSelectedInvoiceCreditInvoice
              ? InvoiceType.CREDIT_PURCHASE_INVOICE
              : InvoiceType.PURCHASE_INVOICE
            : isSelectedInvoiceCreditInvoice
            ? InvoiceType.CREDIT_SALES_INVOICE
            : InvoiceType.SALES_INVOICE
        }
        initialPaymentAmount={selectedInvoiceOpenAmount!}
        fromOverview
      />
    </Modal>
  );
};

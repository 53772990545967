import { useQuery, useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getWorkers,
  deleteWorker,
  editWorker,
} from 'services/Workers/WorkersService';
import { queryClient } from 'index';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IEditWorkerDTO } from './type';
import { useTranslation } from 'react-i18next';

export const useGetWorkers = (
  page?: number,
  perPage?: number,
  searchBy?: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean
) => {
  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_WORKERS,
      page,
      perPage,
      searchBy,
      dateFrom,
      dateTo,
    ],
    queryFn: () => {
      return getWorkers(page, perPage, searchBy, dateFrom, dateTo);
    },
    enabled: isEnabled,
  });
};

export const useDeleteWorkers = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteWorker(id), {
    onSuccess: () => toast.success(t('Worker deleted successfully')),
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_WORKER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_WORKERS);
    },
    mutationKey: ReactMutationKeys.DELETE_WORKER,
  });
};

export const useEditWorker = (id: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editWorkerData: IEditWorkerDTO) => editWorker(id, editWorkerData),
    {
      onSuccess: () => toast.success(t('Worker edited successfully')),
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_WORKER,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_WORKERS);
      },
      mutationKey: ReactMutationKeys.EDIT_WORKER,
    }
  );
};

import apiClient from '../api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IEditWorkerDTO } from 'pages/Manager/PlanningPage/WorkersModal/type';
import { IAddWorkerDTO } from 'pages/Manager/PlanningPage/WorkersModal/AddWorkerForm/hooks';

const BASE_URL = '/v1/workers/';

export const getWorkers = async (
  page?: number,
  perPage?: number,
  searchBy?: string,
  dateFrom?: string,
  dateTo?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    date_from: dateFrom,
    date_to: dateTo,
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addWorker = async (dto: IAddWorkerDTO): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, { workers: [dto] });

  return data.data;
};

export const deleteWorker = async (id: string): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const editWorker = async (
  id: string,
  editedWorker: IEditWorkerDTO
): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.patch<any>(url, editedWorker);

  return data.data;
};

export const getWorkersById = async (ids: string[]): Promise<any> => {
  const queryParamsObj = {
    worker_ids: ids,
  };
  const url = `${BASE_URL}by-ids`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getWorkersInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

import {
  COLORS,
  borderRadiusSm,
  gapLg,
  gapMd,
  gapSm,
  gapXs,
  marginLg,
  marginMd,
  marginSm,
  paddingMd,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop: string;
}

export const Container = styled.div`
  height: 100%;
  width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};
  margin-bottom: ${marginLg};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
`;

export const Label = styled.label`
  margin-bottom: ${marginSm};
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 30%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 50rem;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const EmailConfigurationWrapper = styled.div`
  margin-top: ${marginSm};
  display: flex;
  justify-content: space-between;
  gap: ${gapLg};
  width: 100%;
`;

export const LabelSecondary = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const DefaultAttachmentLabelsWrapper = styled.div`
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
  display: flex;
  flex-direction: column;
  gap: ${gapXs};
`;

export const DefaultAttachmentNameLinkDeleteIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gapSm};
  align-items: center;
`;

export const DefaultAttachmentNameLink = styled.a`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
`;

export const LoaderWrapper = styled.div`
  width: 200rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

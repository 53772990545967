import {
  CheckboxContainer,
  Row,
  RightAlign,
  Container,
} from './SingleSalesOrderProductsPage.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSingleSalesOrder } from '../hooks';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import { useEffect, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  useDeleteSalesOrderLine,
  useEditSalesOrderProductsDefinitive,
} from './hooks';
import { EditSalesOrderServiceLineModal } from './EditSalesOrderServiceLineModal/EditSalesOrderServiceLineModal';
import { CLOSED_STATUS } from '../constants';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { EditSalesOrderWorkingHoursLineModal } from './EditSalesOrderWorkingHoursLineModal/EditSalesOrderWorkingHoursLineModal';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useCreateSalesOrder } from 'pages/Manager/SingleQuotation/hooks';
import { RoutesConfig } from 'navigation/routes';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Button from 'components/Button/Button';
import DraggableLines, {
  DraggableLineType,
  IDraggableLine,
} from 'components/DragAndDrop/DraggableLines/DraggableLines';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const SingleSalesOrderProductsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data,
    isFetching: isFetchingSingleSalesOrder,
    isFetched,
    isLoading: isLoadingSingleSalesOrder,
  } = useGetSingleSalesOrder(id!);
  const [salesOrderLineForDeletion, setSalesOrderLineForDeletion] =
    useState<ISalesOrderLine | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [serviceLineForEditing, setServiceLineForEditing] =
    useState<ISalesOrderLine | null>(null);
  const [workingHoursLineForEditing, setWorkingHoursLineForEditing] =
    useState<ISalesOrderLine | null>(null);
  const [isEditServiceLineModalOpen, setIsEditServiceLineModalOpen] =
    useState<boolean>(false);
  const [isEditWorkingHoursLineModalOpen, setIsEditWorkingHoursLineModalOpen] =
    useState<boolean>(false);
  const [isEditSalesLineFailedModalOpen, setIsEditSalesLineFailedModalOpen] =
    useState<boolean>(false);
  const [editSalesLineFailedModalText, setEditSalesLineFailedModalText] =
    useState<string>('');
  const canEditSalesOrder = useCan(Actions.EDIT_SALES_ORDER);
  const [
    isDeleteSalesLineFailedModalOpen,
    setIsDeleteSalesLineFailedModalOpen,
  ] = useState<boolean>(false);

  const {
    mutate: deleteSalesOrderLine,
    isLoading: deleteSalesOrderLineIsLoading,
  } = useDeleteSalesOrderLine(id!);

  const {
    mutate: editProductsDefinitive,
    isLoading: isLoadingEditProductsDefinitive,
  } = useEditSalesOrderProductsDefinitive(id!);

  const {
    mutate: createSalesOrder,
    isLoading: createSalesOrderIsLoading,
    data: salesOrderData,
    isSuccess: isSuccessCreateSalesOrder,
  } = useCreateSalesOrder();

  useEffect(() => {
    if (isSuccessCreateSalesOrder) {
      navigate(`/sales/${salesOrderData?.sales_order?.id}`, {
        state: {
          createdFromQuotation: true,
        },
      });
    }
  }, [isSuccessCreateSalesOrder]);

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER_PRODUCTS,
    id,
    isFetched
  );

  return (
    <Container className="single-sales-order-step-6">
      <Row>
        <CheckboxContainer>
          <Checkbox
            disabled={isLoadingEditProductsDefinitive || !canEditSalesOrder}
            isChecked={data?.products_definitive}
            onChange={() => {
              editProductsDefinitive({
                all_products_definitive: !data?.products_definitive,
              });
            }}
            label={t('All product details are definitive')}
            style={{ fontSize: '18rem' }}
          />
        </CheckboxContainer>
        {data?.status?.name !== CLOSED_STATUS && canEditSalesOrder && (
          <RightAlign>
            <Button
              label={t('Add new product')}
              link
              icon={Plus}
              sizeIcon={20}
              weightIcon="regular"
              colorIcon={COLORS.PRIMARY}
              onClick={() =>
                navigate(
                  RoutesConfig.NewSalesOrderLine.fullPath.replace(':id', id!)
                )
              }
              fontSize="18rem"
            />
          </RightAlign>
        )}
      </Row>
      <DraggableLines
        lines={data?.sales_order_lines}
        lineType={DraggableLineType.SALES_ORDER}
        withCssTransition={!isFetchingSingleSalesOrder} // This prevents transition animation that occurrs after adjusting lines through field worker appointment and coming back to the sor products page
        onEditClick={(line: IDraggableLine) => {
          const salesOrderLine = line as ISalesOrderLine;
          if (salesOrderLine?.service) {
            // SERVICE
            if (salesOrderLine?.is_linked_invoice) {
              setEditSalesLineFailedModalText(
                'It is not possible to edit service line when there is an active sales invoice linked to the sales order'
              );
              setIsEditSalesLineFailedModalOpen(true);
            } else {
              setServiceLineForEditing(salesOrderLine);
              setIsEditServiceLineModalOpen(true);
            }
          } else if (salesOrderLine?.working_hours_line) {
            // WORKING HOURS
            if (salesOrderLine?.is_linked_invoice) {
              setEditSalesLineFailedModalText(
                'It is not possible to edit service line when there is an active sales invoice linked to the sales order'
              );
              setIsEditSalesLineFailedModalOpen(true);
            } else {
              setWorkingHoursLineForEditing(salesOrderLine);
              setIsEditWorkingHoursLineModalOpen(true);
            }
          } else {
            // PRODUCT/STOCK
            if (
              salesOrderLine?.is_linked_invoice ||
              salesOrderLine?.is_linked_purchase
            ) {
              setEditSalesLineFailedModalText(
                'It is not possible to edit a stock item or product on the sales order when there is an active sales invoice or purchase order linked to the sales order'
              );
              setIsEditSalesLineFailedModalOpen(true);
            } else {
              navigate(
                RoutesConfig.NewSalesOrderLine.fullPath.replace(':id', id!),
                {
                  state: { salesOrderLine: salesOrderLine },
                }
              );
            }
          }
        }}
        onDeleteClick={
          data?.status?.name !== CLOSED_STATUS
            ? (line: IDraggableLine) => {
                const salesOrderLine = line as ISalesOrderLine;
                if (
                  salesOrderLine?.is_linked_invoice ||
                  salesOrderLine?.is_linked_purchase
                )
                  setIsDeleteSalesLineFailedModalOpen(true);
                else {
                  setSalesOrderLineForDeletion(salesOrderLine);
                  setIsDeleteModalOpen(true);
                }
              }
            : undefined
        }
        isDragDisabled={isLoadingSingleSalesOrder || !canEditSalesOrder}
      />
      {isEditServiceLineModalOpen && serviceLineForEditing && (
        <EditSalesOrderServiceLineModal
          isOpen={isEditServiceLineModalOpen}
          setIsOpen={setIsEditServiceLineModalOpen}
          onCancel={() => setIsEditServiceLineModalOpen(false)}
          salesOrderLine={serviceLineForEditing}
        />
      )}
      {isEditWorkingHoursLineModalOpen && workingHoursLineForEditing && (
        <EditSalesOrderWorkingHoursLineModal
          isOpen={isEditWorkingHoursLineModalOpen}
          setIsOpen={setIsEditWorkingHoursLineModalOpen}
          onCancel={() => setIsEditWorkingHoursLineModalOpen(false)}
          salesOrderLine={workingHoursLineForEditing}
        />
      )}
      <OkModal
        isOpen={isEditSalesLineFailedModalOpen}
        setIsOpen={setIsEditSalesLineFailedModalOpen}
        description={t(editSalesLineFailedModalText)}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsEditSalesLineFailedModalOpen(false)}
      />
      <OkModal
        isOpen={isDeleteSalesLineFailedModalOpen}
        setIsOpen={setIsDeleteSalesLineFailedModalOpen}
        description={t(
          'It is not possible to delete sales order line when there is an active sales invoice or purchase order linked to the sales order'
        )}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsDeleteSalesLineFailedModalOpen(false)}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (salesOrderLineForDeletion !== null) {
                deleteSalesOrderLine(salesOrderLineForDeletion.id);
                setSalesOrderLineForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: deleteSalesOrderLineIsLoading,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={
            t('Delete') +
            ' ' +
            (salesOrderLineForDeletion?.service
              ? t('Service')
              : salesOrderLineForDeletion?.stock_item_line
              ? t('Stock item')
              : t('Product'))
          }
          description={t(
            'Are you sure you want to delete this line? This will also delete the line from the sales order.'
          )}
        />
      </Modal>
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesOrderProductsPage;

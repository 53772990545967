import {
  Container,
  RightContainer,
  ImageAvatar,
  LeftContainer,
  FwBlueprintLogoImg,
  ImageAvatarWrapper,
  IconWrapper,
  StyledBadge,
} from './Header.styled';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import FwBlueprintLogo from 'assets/icons/FwBlueprintLogo.svg';
import { useIsFieldWorker } from 'components/Routes/FieldWorkerRoute/hooks';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import BlueprintLogoBig from 'assets/images/blueprintLogoBig.png';
import RippleEffect from 'components/RippleEffect/RippleEffect';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { GearSix, Bell, User } from '@phosphor-icons/react';
import EnvironmentBanner from 'components/EnvironmentBanner/EnvironmentBanner';
import { useGetUnreadNotificationsCount } from './hooks';

const Header = () => {
  const canEditSettings = useCan(Actions.EDIT_USER_PERMISSION);
  const { darkMode, isSideMenuCollapsed } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const navigate = useNavigate();

  const { image_url: imageUrl } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const [showProfileDropdown, setShowProfileDropdown] =
    useState<boolean>(false);

  const profileDropdownWrapperRef = useRef(null);
  useOutsideAlerter(profileDropdownWrapperRef, () =>
    setShowProfileDropdown(false)
  );

  const isFieldWorkerView = useIsFieldWorker();

  const { data: unreadNotificationsCount } = useGetUnreadNotificationsCount();

  return (
    <Container
      isSideMenuCollapsed={isSideMenuCollapsed}
      isFieldWorker={isFieldWorkerView}
    >
      <LeftContainer>
        {isFieldWorkerView ? (
          <FwBlueprintLogoImg
            src={darkMode ? BlueprintLogoBig : FwBlueprintLogo}
            alt=""
            onClick={() => navigate(RoutesConfig.FwAppointments.fullPath)}
          />
        ) : null}
        <Breadcrumbs />
      </LeftContainer>
      <RightContainer>
        {!isFieldWorkerView && canEditSettings ? (
          <Icon
            svg={GearSix}
            marginLeft="50rem"
            size={30}
            color={COLORS.PRIMARY}
            rippleSize={40}
            onClick={() => navigate(RoutesConfig.Settings.fullPath)}
            className="home-step-5"
          />
        ) : null}
        <StyledBadge
          max={999}
          badgeContent={unreadNotificationsCount}
          color="primary"
          onClick={() => {
            isFieldWorkerView
              ? navigate(RoutesConfig.FwNotifications.fullPath)
              : navigate(RoutesConfig.Notifications.fullPath);
          }}
        >
          <Icon
            svg={Bell}
            color={COLORS.PRIMARY}
            size={30}
            rippleSize={40}
            className="home-step-4"
            onClick={() => null} // Show ripple
          />
        </StyledBadge>
        <IconWrapper className="home-step-3">
          <LanguageSwitcher />
        </IconWrapper>
        <div ref={profileDropdownWrapperRef}>
          <ImageAvatarWrapper
            onClick={() => setShowProfileDropdown(!showProfileDropdown)}
            className="home-step-2 planning-step-6"
          >
            {imageUrl ? (
              <RippleEffect size={47}>
                <ImageAvatar alt="" src={imageUrl} />
              </RippleEffect>
            ) : (
              <Icon
                svg={User}
                color={COLORS.PRIMARY}
                size={30}
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
              />
            )}
          </ImageAvatarWrapper>
          {showProfileDropdown && <ProfileDropdown />}
        </div>
      </RightContainer>
      <EnvironmentBanner />
    </Container>
  );
};

export default Header;

import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  useGetResourcesInfinite,
  useGetSingleAppointment,
  useGetUsersSummaryInfinite,
  useGetWorkersInfinite,
} from '../PlanningTab/hooks';
import {
  AltTimeWrapper,
  ApplyWrapper,
  ArrivalTime,
  ArrivalTimeInner,
  ArrivalTimeText,
  ArrivalTimeWrapper,
  CloseContainer,
  ContBig,
  Container,
  DateLabel,
  DateTimeWrapper,
  ErrorMessage,
  ErrorMessageCont,
  HalfCont,
  Heading,
  HorizontalLine,
  ProductErrorLabel,
  StyledSelect,
  StyledSelectBig,
  TimeWrapper,
  Title,
  TitleContainer,
  UsersContainer,
  UsersHeading,
  WorkersAndResources,
  WorkersAndResourcesHeading,
} from './EditAppointmentModal.styled';
import { COLORS, H5 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import CustomSelect from 'components/Select/FormikSelect';
import Button from 'components/Button/Button';
import { Option } from 'components/Select/type';
import moment, { Moment } from 'moment';
import {
  IEditAppointmentDTO,
  useEditAppointment,
  useGetResourceOptions,
  useGetUserOptions,
  useGetWorkerOptions,
} from './hooks';
import {
  RESOURCES_PER_PAGE,
  USERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from './constants';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { SalesOrderLines } from '../SalesOrderLines/SalesOrderLines';
import { useDebounce } from 'utils/hooks/useDebounce';
import { AppointmentType } from 'types/Appointment.types';
import DatePicker from 'components/DatePicker/DatePicker';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from './validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { useHandleSelectOptions } from '../NewAppointmentModal/hooks';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { Minus, WarningCircle } from '@phosphor-icons/react';

interface IEditAppointmentModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  appointmentId: string;
}
export const EditAppointmentModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  appointmentId,
}: IEditAppointmentModalProps) => {
  const { t } = useTranslation();
  const { data } = useGetSingleAppointment(appointmentId!, isOpen);

  const isDateInPast = (): boolean =>
    moment(data?.appointment?.date_to) < moment();

  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);
  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);
  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(USERS_PER_PAGE, debouncedUserSearchBy, isOpen);

  const selectedUsersOptions: Option[] = useGetUserOptions(data, usersData);

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined,
    undefined,
    isOpen
  );
  const selectedWorkersOptions: Option[] = useGetWorkerOptions(
    data,
    workersData
  );
  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined,
    undefined,
    isOpen
  );
  const selectedResourcesOptions: Option[] = useGetResourceOptions(
    data,
    resourcesData
  );

  const {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    usersData,
    workersData,
    resourcesData,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  const {
    mutate: editAppointment,
    isSuccess: isSuccessEditAppointment,
    isLoading: isLoadingEditAppointment,
    reset: resetEditAppointment,
  } = useEditAppointment(appointmentId!);
  useEffect(() => {
    if (isSuccessEditAppointment) {
      setIsOpen(false);
      resetEditAppointment();
    }
  }, [isSuccessEditAppointment]);

  const [timeIntervalErrorMessage, setTimeIntervalErrorMessage] =
    useState<string>('');
  const [timeArrivalErrorMessage, setTimeArrivalErrorMessage] =
    useState<string>('');

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);
  const [showProductsErrorMessage, setShowProductsErrorMessage] =
    useState<boolean>(false);

  const [selectedSalesOrderLineIds, setSelectedSalesOrderLineIds] = useState<
    number[]
  >([]);

  const handleEditAppointment = (values: any) => {
    const editAppointmentDTO: IEditAppointmentDTO = {
      date_from: moment(values.date_from)
        .set('hour', +values.time_from.split(':')[0])
        .set('minute', +values.time_from.split(':')[1])
        .format('YYYY-MM-DDTHH:mm:ss'),
      date_to: moment(values.date_to)
        .set('hour', +values.time_to.split(':')[0])
        .set('minute', +values.time_to.split(':')[1])
        .format('YYYY-MM-DDTHH:mm:ss'),
      users_ids: values.users_ids,
      workers_ids: values.workers_ids,
      resources_ids: values.resources_ids,
      arrival_from: `${values.date_from} ${values.arrival_from}`,
      arrival_to: `${values.date_from} ${values.arrival_to}`,
    };
    if (isInstallation || isService) {
      if (!selectedSalesOrderLineIds?.length) {
        setShowProductsErrorMessage(true);
        return;
      } else {
        editAppointmentDTO['sales_order_lines_ids'] = selectedSalesOrderLineIds;
      }
    }
    if (isGeneral) {
      editAppointmentDTO['purpose'] = values.purpose;
    }
    if (isGeneral || isService) {
      editAppointmentDTO['description'] = values.description;
    }
    editAppointment(editAppointmentDTO);
  };

  const getInitialValues = () => {
    const initialData = {
      date_from: moment(data?.appointment?.date_from).format('YYYY-MM-DD'),
      date_to: moment(data?.appointment?.date_to).format('YYYY-MM-DD'),
      time_from:
        data?.appointment?.date_from?.split('T')[1].split('+')[0] || '',
      time_to: data?.appointment?.date_to?.split('T')[1].split('+')[0] || '',
      arrival_from:
        data?.appointment?.arrival_from?.split('T')[1].split('+')[0] || '',
      arrival_to:
        data?.appointment?.arrival_to?.split('T')[1].split('+')[0] || '',
      appointment_id: data?.appointment?.id,
      users_ids: data?.appointment?.users?.map((user: any) => {
        return user.id;
      }),
      workers_ids: data?.appointment?.workers?.map((worker: any) => {
        return worker.id;
      }),
      resources_ids: data?.appointment?.resources?.map((resource: any) => {
        return resource.id;
      }),
    };

    if (isGeneral) {
      initialData['purpose'] = data?.appointment?.purpose;
    }
    if (isGeneral || isService) {
      initialData['description'] = data?.appointment?.description;
    }
    if (isInstallation) {
      initialData['appointment_sales_order_lines'] =
        data?.appointment?.appointment_sales_order_lines;
    }

    return initialData;
  };

  const isInstallation =
    data?.appointment?.type?.name === AppointmentType.INSTALLATION;

  const isService = data?.appointment?.type?.name === AppointmentType.SERVICE;

  const isGeneral = data?.appointment?.type?.name === AppointmentType.GENERAL;

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{data?.appointment?.type.name}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={(values) => {
            handleEditAppointment(values);
          }}
          initialValues={getInitialValues()}
        >
          {({ errors, touched, setFieldValue, values, handleBlur }) => {
            if (isDateInPast()) {
              {
                /* Only users can be edited in past appointments. General appointments dont have users for editing */
              }
              return (
                <Container>
                  {!isGeneral && (
                    <>
                      <UsersHeading>
                        <H5>{t('USERS')}</H5>
                      </UsersHeading>
                      <UsersContainer>
                        <ContBig>
                          <StyledSelectBig
                            name="users_ids"
                            options={userOptions}
                            onInputChange={(e: string) => setUserSearchBy(e)}
                            component={CustomSelect}
                            placeholder={t('Users')}
                            isMulti={true}
                            onSelect={(value: Option[]) =>
                              setSelectedUserOptions(value)
                            }
                            onMenuScrollToBottom={() =>
                              hasNextPageUsers && fetchNextPageUsers()
                            }
                            isLoading={isLoadingUsers}
                            translate={false}
                            menuHeight="150rem"
                          />
                        </ContBig>
                      </UsersContainer>
                    </>
                  )}
                  <ApplyWrapper>
                    <Button
                      onClick={onCancel}
                      secondary
                      width="200rem"
                      label={t('Cancel')}
                      disabled={isLoadingEditAppointment}
                    />
                    <Button
                      disabled={
                        !values.time_from ||
                        !values.time_to ||
                        !!timeIntervalErrorMessage ||
                        isLoadingEditAppointment
                      }
                      onClick={() => {
                        handleEditAppointment(values); // Handle form submission
                      }}
                      primary
                      width="200rem"
                      label={t('Save')}
                    />
                  </ApplyWrapper>
                </Container>
              );
            }
            return (
              <Container>
                {isGeneral && (
                  <>
                    <Heading>
                      <H5>{t('PURPOSE')}</H5>
                    </Heading>
                    <FormikInput
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('purpose', value);
                        handleBlur(e);
                      }}
                      errorMessage={touched['purpose'] ? errors['purpose'] : ''}
                      height={'41rem'}
                      name="purpose"
                      wrapperStyles={{
                        marginTop: '20rem',
                      }}
                      placeholder={t('Purpose of the appointment')}
                    />
                    <HorizontalLine />
                  </>
                )}
                <Heading>
                  <H5>{t('DATE & TIME')}</H5>
                </Heading>
                <DateLabel>{t('From')}</DateLabel>
                <DateTimeWrapper>
                  <DatePicker
                    pwId="date-field"
                    width="50%"
                    date={moment(values?.date_from)}
                    setDate={(newValue: any) => {
                      setFieldValue(
                        'date_from',
                        newValue?.format('YYYY-MM-DD')
                      );
                      setTimeIntervalErrorMessage('');
                      setTimeout(() => {
                        validateTimeFields(
                          {
                            ...values,
                            date_from: newValue?.format('YYYY-MM-DD'),
                          },
                          setTimeIntervalErrorMessage,
                          t
                        );
                      }, 0);
                    }}
                  />
                  <TimeWrapper>
                    <TimePicker
                      pwId="time-from-select"
                      time={moment(values?.time_from, 'HH:mm:ss')}
                      width="100%"
                      setTime={(newValue: Moment) => {
                        validateDateTimeInterval(
                          moment(values?.date_from).format('YYYY-MM-DD'),
                          newValue?.format('HH:mm:ss'),
                          moment(values?.date_to).format('YYYY-MM-DD'),
                          values.time_to,
                          setTimeIntervalErrorMessage,
                          t
                        );
                        setFieldValue(
                          'time_from',
                          newValue?.format('HH:mm:ss')
                        );
                      }}
                    />
                  </TimeWrapper>
                </DateTimeWrapper>
                <DateLabel>{t('To')}</DateLabel>
                <DateTimeWrapper>
                  <DatePicker
                    pwId="date-field"
                    width="50%"
                    date={moment(values?.date_to)}
                    setDate={(newValue: any) => {
                      setFieldValue('date_to', newValue?.format('YYYY-MM-DD'));
                      setTimeIntervalErrorMessage('');
                      setTimeout(() => {
                        validateTimeFields(
                          {
                            ...values,
                            date_to: newValue?.format('YYYY-MM-DD'),
                          },
                          setTimeIntervalErrorMessage,
                          t
                        );
                      }, 0);
                    }}
                  />
                  <TimeWrapper>
                    <TimePicker
                      pwId="time-to-select"
                      time={moment(values?.time_to, 'HH:mm:ss')}
                      width="100%"
                      setTime={(newValue: Moment) => {
                        validateDateTimeInterval(
                          moment(values?.date_from).format('YYYY-MM-DD'),
                          values.time_from,
                          moment(values?.date_to).format('YYYY-MM-DD'),
                          newValue?.format('HH:mm:ss'),
                          setTimeIntervalErrorMessage,
                          t
                        );
                        setFieldValue('time_to', newValue?.format('HH:mm:ss'));
                      }}
                    />
                  </TimeWrapper>
                </DateTimeWrapper>
                <ErrorMessageCont>
                  <ErrorMessage>{timeIntervalErrorMessage}</ErrorMessage>
                </ErrorMessageCont>
                <HorizontalLine />
                <ArrivalTimeWrapper>
                  <ArrivalTimeInner>
                    <ArrivalTime>
                      <ArrivalTimeText onClick={() => setShowArrivalTime(true)}>
                        {t('Arrival time')}
                      </ArrivalTimeText>
                      <Tooltip
                        content={
                          t(
                            'This is the expected arrival time that will be communicated with the customer via the appointment email'
                          ) + '.'
                        }
                      >
                        <Icon
                          svg={WarningCircle}
                          color={COLORS.PRIMARY}
                          size={20}
                          weight="regular"
                          marginLeft={'10rem'}
                          pointer={false}
                        />
                      </Tooltip>
                    </ArrivalTime>

                    {(data?.appointment?.arrival_from || showArrivalTime) && (
                      <>
                        <AltTimeWrapper>
                          <TimePicker
                            pwId="arrival-from-select"
                            time={moment(values?.arrival_from, 'HH:mm:ss')}
                            width="100%"
                            setTime={(newValue: Moment) => {
                              setFieldValue(
                                'arrival_from',
                                newValue?.format('HH:mm:ss')
                              );
                              validateTimeOnlyInterval(
                                newValue?.format('HH:mm:ss'),
                                values.arrival_to,
                                setTimeArrivalErrorMessage,
                                t
                              );
                            }}
                          />
                          <Icon
                            svg={Minus}
                            size={18}
                            weight="bold"
                            color={COLORS.BLACK}
                            marginLeft="5rem"
                            marginRight="5rem"
                          />
                          <TimePicker
                            pwId="arrival-to-select"
                            time={moment(values?.arrival_to, 'HH:mm:ss')}
                            width="100%"
                            setTime={(newValue: Moment) => {
                              setFieldValue(
                                'arrival_to',
                                newValue?.format('HH:mm:ss')
                              );
                              validateTimeOnlyInterval(
                                values.arrival_from,
                                newValue?.format('HH:mm:ss'),
                                setTimeArrivalErrorMessage,
                                t
                              );
                            }}
                          />
                        </AltTimeWrapper>
                      </>
                    )}
                  </ArrivalTimeInner>
                  <ErrorMessageCont>
                    <ErrorMessage>{timeArrivalErrorMessage}</ErrorMessage>
                  </ErrorMessageCont>
                </ArrivalTimeWrapper>
                {(isInstallation || isService) && (
                  <>
                    <HorizontalLine />
                    <Heading>
                      <H5>{t('PRODUCTS')}</H5>
                    </Heading>
                    <SalesOrderLines
                      selectedSalesOrderId={data?.appointment?.sales_order_id}
                      selectedSalesOrderLineIds={data?.appointment?.appointment_sales_order_lines?.map(
                        (sales_order_line: any) => {
                          return +sales_order_line.id;
                        }
                      )}
                      onSubmit={(lines) => {
                        setSelectedSalesOrderLineIds(
                          lines.map((sales_order_line) => {
                            return +sales_order_line.id;
                          })
                        );
                      }}
                    />
                    {showProductsErrorMessage && (
                      <ProductErrorLabel>
                        {t('Please choose at least one product')}.
                      </ProductErrorLabel>
                    )}
                  </>
                )}
                {(isGeneral || isService) && (
                  <>
                    <HorizontalLine />
                    <Heading>
                      <H5>{t('DESCRIPTION')}</H5>
                    </Heading>
                    <FormikInput
                      isTextArea
                      height={'81rem'}
                      name="description"
                      placeholder={t('Details about the appointment')}
                      wrapperStyles={{
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('description', value);
                        handleBlur(e);
                      }}
                    />
                  </>
                )}
                {!isGeneral && (
                  <>
                    <HorizontalLine />
                    <UsersHeading>
                      <H5>{t('USERS')}</H5>
                    </UsersHeading>
                    <UsersContainer>
                      <HalfCont>
                        <StyledSelectBig
                          name="users_ids"
                          options={userOptions}
                          onInputChange={(e: string) => setUserSearchBy(e)}
                          component={CustomSelect}
                          placeholder={t('Users')}
                          isMulti={true}
                          onSelect={(value: Option[]) =>
                            setSelectedUserOptions(value)
                          }
                          onMenuScrollToBottom={() =>
                            hasNextPageUsers && fetchNextPageUsers()
                          }
                          isLoading={isLoadingUsers}
                          translate={false}
                        />
                      </HalfCont>
                    </UsersContainer>
                  </>
                )}
                <HorizontalLine />
                <WorkersAndResourcesHeading>
                  <H5>{t('WORKERS & RESOURCES')}</H5>
                </WorkersAndResourcesHeading>
                <WorkersAndResources>
                  <HalfCont>
                    <StyledSelect
                      pwId="worker-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => setWorkerSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti
                      onSelect={(value: Option[]) =>
                        setSelectedWorkerOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageWorkers && fetchNextPageWorkers()
                      }
                      isLoading={isLoadingWorkers}
                      translate={false}
                    />
                  </HalfCont>
                  <HalfCont>
                    <StyledSelect
                      pwId="resource-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => setResourceSearchBy(e)}
                      onSelect={(value: Option[]) =>
                        setSelectedResourceOptions(value)
                      }
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onMenuScrollToBottom={() =>
                        hasNextPageResources && fetchNextPageResources()
                      }
                      isLoading={isLoadingResources}
                      translate={false}
                    />
                  </HalfCont>
                </WorkersAndResources>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                    disabled={isLoadingEditAppointment}
                  />
                  <Button
                    disabled={
                      !values.time_from ||
                      !values.time_to ||
                      !!timeIntervalErrorMessage ||
                      isLoadingEditAppointment
                    }
                    onClick={() => {
                      setIsConfirmModalOpen(true);
                    }}
                    primary
                    width="200rem"
                    label={t('Save')}
                  />
                </ApplyWrapper>
                <ConfirmModal
                  level="SECOND"
                  title={t('Edit Appointment')}
                  description={`${t(
                    'Are you sure you want to edit this appointment'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => {
                    handleEditAppointment(values);
                    setIsConfirmModalOpen(false);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

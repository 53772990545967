import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addLabelToEntity,
  deleteLabel,
  deleteLabelFromEntity,
  editLabel,
  getLabels,
  getLabelsByEntity,
  getLabelsGroups,
  IAddEditLabelDTO,
  reorderLabels,
} from 'services/Label/LabelService';
import { LabelEntityTypeId } from 'types/EntityLabel.types';

export const useGetLabelsInfinite = (
  perPage: number,
  searchBy?: string,
  entityType?: number
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_LABELS_INFINITE,
      perPage,
      searchBy,
      entityType,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getLabels(pageParam, perPage, searchBy, entityType),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useGetLabelGroupOptions = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_LABEL_GROUP_OPTIONS,
    queryFn: async () => {
      const data = await getLabelsGroups();
      return data.map(({ name, value }: any) => ({
        value,
        label: name,
      }));
    },
  });

export const useEditLabel = () => {
  const { t } = useTranslation();
  return useMutation((dto: IAddEditLabelDTO) => editLabel(dto), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Label edited'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_LABELS_INFINITE]);
    },
    mutationKey: ReactMutationKeys.EDIT_LABEL,
  });
};

export const useDeleteLabel = () => {
  const { t } = useTranslation();
  return useMutation((labelId: number) => deleteLabel(labelId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GET_LABELS_INFINITE]);
      toast.success(t('Label deleted'));
    },
    mutationKey: ReactMutationKeys.DELETE_LABEL,
  });
};

export interface IReorderLabelsRequestDTO {
  labels: IReorderLabelDTO[];
}

export interface IReorderLabelDTO {
  labelId: number;
  labelIndex: number;
}

export const useReorderLabels = () => {
  const { t } = useTranslation();
  return useMutation((dto: IReorderLabelsRequestDTO) => reorderLabels(dto), {
    onSuccess: () => {
      toast.success(t('Labels reordered'));
      queryClient.invalidateQueries(ReactQueryKeys.GET_LABELS_INFINITE);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.REORDER_LABELS,
      });
    },
    mutationKey: ReactMutationKeys.REORDER_LABELS,
  });
};

export const useGetEntityLabels = (
  entityId: string,
  entityType: LabelEntityTypeId,
  page?: number,
  perPage?: number,
  searchBy?: string
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_ENTITY_LABELS, entityType, entityId],
    queryFn: () => {
      return getLabelsByEntity(entityId, entityType, page, perPage, searchBy);
    },
    enabled: !!entityId,
  });

export const useAddLabelToEntity = (
  entityType: LabelEntityTypeId,
  entityId: number
) => {
  const { t } = useTranslation();
  return useMutation(
    (labelId: number) => addLabelToEntity(entityType, entityId, labelId),
    {
      onError: (error: ERPError) => {
        const errorMessage = getToastErrorMessage(error);
        if (errorMessage == 'Label already exists on entity') {
          toast.error(t(errorMessage));
        } else {
          toast.error(getToastErrorMessage(error));
        }
      },
      onSuccess: () => {
        toast.success(t('Added label'));
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_ENTITY_LABELS,
          entityType,
          entityId.toString(),
        ]);
        queryClient.invalidateQueries(ReactQueryKeys.GET_TODOS);
      },
      mutationKey: ReactMutationKeys.ADD_LABEL_TO_ENTITY,
    }
  );
};

export const useDeleteLabelFromEntity = (
  entityType: LabelEntityTypeId,
  entityId: number
) => {
  const { t } = useTranslation();
  return useMutation(
    (labelId: number) => deleteLabelFromEntity(entityType, entityId, labelId),
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        toast.success(t('Deleted label'));
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_ENTITY_LABELS,
          entityType,
          entityId.toString(),
        ]);
        queryClient.invalidateQueries(ReactQueryKeys.GET_TODOS);
      },
      mutationKey: ReactMutationKeys.DELETE_LABEL_FROM_ENTITY,
    }
  );
};

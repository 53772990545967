import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { Container, Label } from './MandateCreatedPage.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useEffect } from 'react';
import { changeLanguage } from 'utils/changeLanguage';
import { Check } from '@phosphor-icons/react';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

const MandateCreatedPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang');

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  useEffect(() => {
    queryClient.invalidateQueries(
      ReactQueryKeys.GET_SINGLE_MOLLIE_CUSTOMER_MANDATES
    );
  }, []);

  return (
    <Container>
      <Icon svg={Check} color={COLORS.BLACK} size={60} weight="bold" />
      <Label>{t('Mandate created successfully')}</Label>
    </Container>
  );
};

export default MandateCreatedPage;

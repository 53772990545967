import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  confirmAppointment,
  getAppointmentAttachmentsPreview,
  getAppointmentLatestEmailStatus,
  getSingleAppointmentIntervals,
  markAsFailedAppointment,
  markAsSuccessfulAppointment,
  sendAppointmentEmail,
  updateIsAppointmentProcessedInOffice,
} from 'services/Appointment/AppointmentService';
import { getSingleAppointmentReport } from 'services/Appointment/FwAppointmentService';
import {
  AppointmentEmailType,
  IAppointmentInterval,
} from 'types/Appointment.types';
import { EmailStatuses } from 'types/Email.types';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';

interface ISendAppointmentEmailDTO {
  appointmentId: string;
  appointmentEmailType: AppointmentEmailType;
  note: string;
  files: any;
}
export const useSendAppointmentEmail = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: ISendAppointmentEmailDTO) =>
      sendAppointmentEmail(
        params.appointmentId,
        params.appointmentEmailType,
        params.note,
        params.files
      ),
    {
      onSuccess: () => {
        toast.success(t('E-mail is being sent'), {
          autoClose: 7000,
          className: ReactMutationKeys.SEND_APPOINTMENT_EMAIL,
        });

        const appointmentData: any = queryClient.getQueryData([
          ReactQueryKeys.GET_SINGLE_APPOINTMENT,
          appointmentId.toString(),
        ]);

        if (appointmentData?.appointment?.sent_to_customer)
          handleEmailStatusFetching(appointmentId);
        else handleEmailStatusFirstTimeFetching(appointmentId);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SEND_APPOINTMENT_EMAIL,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SEND_QUOTATION_EMAIL,
    }
  );
};

const handleEmailStatusFetching = (appointmentId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_APPOINTMENT_EMAIL_STATUS,
      appointmentId.toString(),
    ]);
    const appointmentData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_APPOINTMENT,
      appointmentId.toString(),
    ]);

    //Check if status has changed and stop further intervals
    if (
      statusData?.id !== appointmentData?.appointment?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_APPOINTMENT]);
      clearInterval(intervalId);
    } else
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_APPOINTMENT_EMAIL_STATUS,
      ]);

    intervalCount++;
  }, 7000);
};

const handleEmailStatusFirstTimeFetching = (appointmentId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_APPOINTMENT_EMAIL_STATUS,
      appointmentId.toString(),
    ]);
    const appointmentData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_APPOINTMENT,
      appointmentId.toString(),
    ]);

    //Check if status exists and stop further intervals
    if (
      appointmentData?.appointment?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      clearInterval(intervalId); // Stop further intervals
    } else {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_APPOINTMENT_EMAIL_STATUS,
      ]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_APPOINTMENT]);
    }

    intervalCount++;
  }, 7000);
};

export interface IConfirmAppointmentDTO {
  appointmentId: string;
  send_confirm_email: boolean;
  note?: string;
  files?: any;
}
export const useConfirmAppointment = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IConfirmAppointmentDTO) => confirmAppointment(params),
    {
      onSuccess: () => {
        toast.success(t('Appointment confirmed'), {
          className: ReactMutationKeys.CONFIRM_APPOINTMENT,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_APPOINTMENT,
          appointmentId,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CONFIRM_APPOINTMENT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CONFIRM_APPOINTMENT,
    }
  );
};

export const useMarkAsFailedAppointment = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (appointmentId: string) => markAsFailedAppointment(appointmentId),
    {
      onSuccess: () => {
        toast.success(t('Appointment marked as failed'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_APPOINTMENT,
          appointmentId,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SUPPLIER_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_CUSTOMER_APPOINTMENTS,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.FAILED_APPOINTMENT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.FAILED_APPOINTMENT,
    }
  );
};

export const useMarkAsSuccessfulAppointment = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (appointmentId: string) => markAsSuccessfulAppointment(appointmentId),
    {
      onSuccess: () => {
        toast.success(t('Appointment marked as successful'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_APPOINTMENT,
          appointmentId,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SUPPLIER_APPOINTMENTS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_CUSTOMER_APPOINTMENTS,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SUCCESSFUL_APPOINTMENT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SUCCESSFUL_APPOINTMENT,
    }
  );
};

export const useGetAppointmentIntervals = (
  appointmentId: string,
  isEnabled = true
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_APPOINTMENT_INTERVALS, appointmentId],
    queryFn: () => {
      return getSingleAppointmentIntervals(appointmentId);
    },
    enabled: !!appointmentId && isEnabled,
  });

export const useGetTimerData = (appointmentId: string, isEnabled = true) => {
  // isTimerLoading is used so the timer doesnt get displayed if the intervals havent been fetched yet,
  // Only after the intervals have been initially fetched, the timer is loaded and its initial state is calculated
  const [isTimerLoading, setIsTimerLoading] = useState<boolean>(true);

  const {
    data: appointmentIntervals,
    isSuccess: getIntervalsSuccess,
    isLoading: getIntervalsIsLoading,
  } = useGetAppointmentIntervals(appointmentId, isEnabled);
  const intervals = appointmentIntervals?.appointment_intervals;

  useEffect(() => {
    if (getIntervalsSuccess && isTimerLoading) {
      setIsTimerLoading(false);
    }
  }, [getIntervalsSuccess]);

  const memoizedOngoingInterval = useMemo(() => {
    return intervals?.find(
      (interval: IAppointmentInterval) => !interval.end_date
    );
  }, [intervals]);

  const memoizedLatestInterval = useMemo(() => {
    return intervals?.reduce(
      (latest: IAppointmentInterval, current: IAppointmentInterval) => {
        if (!latest) return current;

        const latestDate = moment(latest.start_date);
        const currentDate = moment(current.start_date);

        return currentDate.isAfter(latestDate) ? current : latest;
      },
      null
    );
  }, [intervals]);

  const isTimerLoadingFinal = isTimerLoading || getIntervalsIsLoading;
  return [memoizedOngoingInterval, memoizedLatestInterval, isTimerLoadingFinal];
};

export const useGetAppointmentLatestEmailStatus = (
  appointmentId: string,
  isEnabled?: boolean
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_APPOINTMENT_EMAIL_STATUS,
      appointmentId,
    ],
    queryFn: () => {
      return getAppointmentLatestEmailStatus(appointmentId);
    },
    enabled: isEnabled,
  });

export const useGetAppointmentReport = (
  appointmentId: string,
  isEnabled: boolean
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_APPOINTMENT_REPORT, appointmentId],
    queryFn: () => {
      return getSingleAppointmentReport(appointmentId);
    },
    enabled: !!appointmentId && isEnabled,
  });

export const useUpdateIsAppointmentProcessedInOffice = (
  appointmentId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (isProcessedInOffice: boolean) =>
      updateIsAppointmentProcessedInOffice(appointmentId, isProcessedInOffice),
    {
      onSuccess: () => {
        toast.success(t('Appointment updated'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_APPOINTMENT,
          appointmentId,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPDATE_IS_APPOINTMENT_PROCESSED_IN_OFFICE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.UPDATE_IS_APPOINTMENT_PROCESSED_IN_OFFICE,
    }
  );
};

// Unused
export const useGetAppointmentAttachmentsPreview = (
  id: string,
  type: AddEmailNoteModalType
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_APPOINTMENT_ATTACHMENTS_PREVIEW, id],
    queryFn: () => {
      return getAppointmentAttachmentsPreview(id);
    },
    enabled:
      !!id &&
      (type === AddEmailNoteModalType.APPOINTMENT ||
        type === AddEmailNoteModalType.APPOINTMENT_CONFIRM ||
        type === AddEmailNoteModalType.FW_APPOINTMENT),
  });

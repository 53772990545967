import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { ILabel } from 'types/EntityLabel.types';
import {
  Container,
  RowSpaceBetween,
  StaticLabel,
} from './LabelDropdownItem.styled';
import { useGetLabelGroupOptions } from 'pages/Manager/LabelsPage/hooks';
import Spinner from 'components/Spinner/Spinner';

interface ILabelDropdownItem {
  label: ILabel;
  onClick: (id: number) => void;
}
export const LabelDropdownItem = ({ label, onClick }: ILabelDropdownItem) => {
  const isLabelNameLong = label.name.length > 15;
  const { data: labelGroupOptions, isLoading: isLoadingLabelGroupOptions } =
    useGetLabelGroupOptions();

  return (
    <Container onClick={() => onClick(label.id)}>
      <RowSpaceBetween>
        <StaticLabel>
          {isLabelNameLong ? `${label.name.substring(0, 15)}...` : label.name}
        </StaticLabel>
        <EntityLabel
          label={label}
          maxWidth="120rem"
          tooltipContent={isLabelNameLong ? label.name : label.description}
        />
      </RowSpaceBetween>
      <StaticLabel>
        {isLoadingLabelGroupOptions ? (
          <>
            <Spinner size={8} />
          </>
        ) : (
          labelGroupOptions?.find(
            (group: any) => Number(group.value) === label.label_group_id
          )?.label
        )}
      </StaticLabel>
    </Container>
  );
};

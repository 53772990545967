import { useTranslation } from 'react-i18next';
import {
  Container,
  ContentContainer,
  FirstRow,
  NoContentLabel,
  SpinnerNoContentWrapper,
  Title,
} from './LabelsPage.styled';
import {
  IReorderLabelDTO,
  IReorderLabelsRequestDTO,
  useDeleteLabel,
  useGetLabelGroupOptions,
  useGetLabelsInfinite,
  useReorderLabels,
} from './hooks';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';
import { Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddEditLabelModal } from './AddEditLabelModal/AddEditLabelModal';
import { LABELS_PER_PAGE } from './constants';
import { ILabel } from 'types/EntityLabel.types';
import { LabelRow } from './LabelRow/LabelRow';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import Draggable from 'components/DragAndDrop/Draggable/Draggable';
import DndProvider from 'components/DragAndDrop/DndProvider/DndProvider';
import Spinner from 'components/Spinner/Spinner';
import {
  isOrderDifferentFromInitial,
  sortLabelsByIndex,
  swapDragAndHoverLabelIndexes,
} from './helpers';

const LabelsPage = () => {
  const { t } = useTranslation();
  const { data: labelGroupOptions } = useGetLabelGroupOptions();

  const [isCreateLabelModalOpen, setIsCreateLabelModalOpen] =
    useState<boolean>(false);
  const [labelForEditing, setLabelForEditing] = useState<ILabel | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [labelIdForDeletion, setLabelIdForDeletion] = useState<number | null>(
    null
  );
  const [draggedId, setDraggedId] = useState<number | null>(null);

  const [localLabels, setLocalLabels] = useState<ILabel[]>([]);
  const {
    data: labelsData,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingGetLabels,
  } = useGetLabelsInfinite(LABELS_PER_PAGE, '');

  const { mutate: deleteLabel, isLoading: isLoadingDeleteLabel } =
    useDeleteLabel();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoadingGetLabels);

  const labels: ILabel[] = useMemo(() => {
    return (labelsData?.pages || []).map((page) => page.labels).flat();
  }, [labelsData]);

  useEffect(() => {
    if (
      labels &&
      (!localLabels.length ||
        localLabels.length !== labels?.length ||
        JSON.stringify(labels) !== JSON.stringify(localLabels))
    ) {
      setLocalLabels(labels);
    }
  }, [labels]);

  const { mutate: reorderLabels, isLoading: isLoadingReorderLabels } =
    useReorderLabels();

  useEffect(() => {
    if (!draggedId && !isLoadingGetLabels) {
      const reorderLabelsDTOs: IReorderLabelDTO[] = localLabels.map(
        (label: ILabel) => {
          return { labelId: label.id, labelIndex: label.index };
        }
      );

      if (
        reorderLabelsDTOs.length &&
        labels &&
        isOrderDifferentFromInitial(labels, reorderLabelsDTOs)
      ) {
        const dto: IReorderLabelsRequestDTO = { labels: reorderLabelsDTOs };
        reorderLabels(dto);
      }
    }
  }, [localLabels, draggedId, isLoadingGetLabels]);

  const onDragHover = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (localLabels && dragIndex !== hoverIndex) {
        const newLocalLabels: ILabel[] = swapDragAndHoverLabelIndexes(
          localLabels,
          dragIndex,
          hoverIndex
        );
        setLocalLabels(newLocalLabels);
      }
    },
    [localLabels]
  );

  return (
    <Container>
      <FirstRow>
        <Title>{t('Labels')}</Title>
        <Button
          icon={Plus}
          label={t('Create new label')}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          link
          onClick={() => setIsCreateLabelModalOpen(true)}
          fontSize="18rem"
        />
      </FirstRow>

      <ContentContainer>
        <DndProvider>
          {sortLabelsByIndex(localLabels).map(
            (label: ILabel, nonImportantIndex: number) => {
              return (
                <Draggable
                  index={label.index}
                  key={`label-row-${label.id}-${label.index}`}
                  id={label.id}
                  draggedId={draggedId}
                  setDraggedId={setDraggedId}
                  onDragHover={onDragHover}
                  style={{ width: '100%' }}
                  isDragDisabled={isLoadingGetLabels || isLoadingReorderLabels}
                >
                  <LabelRow
                    labelGroupOptions={labelGroupOptions}
                    label={label}
                    isLast={localLabels.length - 1 === nonImportantIndex}
                    onEditClick={(label: ILabel) => setLabelForEditing(label)}
                    onDeleteClick={(labelId: number) => {
                      setLabelIdForDeletion(labelId);
                      setIsDeleteModalOpen(true);
                    }}
                  />
                </Draggable>
              );
            }
          )}
        </DndProvider>
        <div
          ref={loadMoreRef}
          style={{
            height: '1px',
          }}
        />
        <SpinnerNoContentWrapper>
          {isLoadingGetLabels ? (
            <Spinner />
          ) : labels.length ? null : (
            <NoContentLabel>{t('No labels found')}</NoContentLabel>
          )}
        </SpinnerNoContentWrapper>
      </ContentContainer>
      <AddEditLabelModal
        labelGroupOptions={labelGroupOptions}
        isOpen={isCreateLabelModalOpen}
        labelForEditing={labelForEditing}
        setIsOpen={setIsCreateLabelModalOpen}
        onCancel={() => {
          setIsCreateLabelModalOpen(false);
          labelForEditing && setLabelForEditing(null);
        }}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (labelIdForDeletion) {
                deleteLabel(labelIdForDeletion);
                setLabelIdForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: isLoadingDeleteLabel,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete label')}
          description={t('Are you sure you want to delete this Label') + '?'}
        />
      </Modal>
    </Container>
  );
};
export default LabelsPage;

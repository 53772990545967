import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getCustomerQuotations,
  getCustomerRecurringQuotations,
} from 'services/Customer/CustomerService';

export const useGetSingleCustomerQuotations = (
  customerId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_CUSTOMER_QUOTATIONS,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerQuotations(customerId, page, perPage);
    },
  });

export const useGetSingleCustomerRecurringQuotations = (
  customerId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_CUSTOMER_RECURRING_QUOTATIONS,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerRecurringQuotations(customerId, page, perPage);
    },
  });

import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  color: string;
}

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorSquare = styled.div`
  border-radius: 5rem;
  border: 1rem solid ${COLORS.STRICT_WHITE};
  margin-right: 10rem;
  width: 30rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;

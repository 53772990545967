import { COLORS, marginSm, paddingSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.BACKGROUND_COLOR};
  padding: ${paddingSm};
`;

export const Label = styled.label`
  font-size: 50rem;
  margin-bottom: ${marginSm};
  font-weight: 100;
  line-height: 120rem;
  text-align: center;

  @media screen and (min-width: 200px) and (max-width: 600px) {
    font-size: 20rem;
    line-height: 50rem;
    width: 350rem;
  }
`;

export const LoadingPaymentInfoLabel = styled.label`
  font-size: 50rem;
  margin-bottom: 200rem;
`;

import { useGetSingleSalesInvoice } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  ColumnContainer,
  Container,
  Label,
  LabelTitle,
  MainContainer,
  TextContainer,
  TitleContainer,
} from './SingleSalesInvoiceSalesOrderPage.styled';
import { useState } from 'react';
import { H3, H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/numberFormatter';
import { useGetSingleSalesOrder } from 'pages/Manager/SingleSalesOrder/hooks';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { Tooltip } from 'components/Tooltip/Tooltip';

const SingleSalesInvoiceSalesOrderPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const canReadSalesOrder = useCan(Actions.READ_SALES_ORDER);

  const { data, isFetched } = useGetSingleSalesInvoice(
    typeof id === 'string' ? id : ''
  );

  const { data: salesOrder } = useGetSingleSalesOrder(
    data?.sales_invoice.sales_order_id
  );

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_INVOICE_SALES_ORDERS,
    id,
    isFetched
  );

  return (
    <Container className="single-sales-invoice-step-6">
      <Card>
        <TitleContainer>
          <H3>{salesOrder?.number}</H3>
          <Button
            onClick={() =>
              navigate(`/sales/${data?.sales_invoice?.sales_order_id}`)
            }
            label={t('View sales order')}
            primary
            disabled={!canReadSalesOrder}
          ></Button>
        </TitleContainer>
        <MainContainer>
          <ColumnContainer>
            <H4>{t('DETAILS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Date')}</LabelTitle>
              <Label>{`${moment(salesOrder?.date)
                .tz(moment.tz.guess())
                .format('DD-MM-YYYY')}`}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Reference')}</LabelTitle>
              <Tooltip
                content={
                  salesOrder?.reference.length > 15
                    ? salesOrder?.reference
                    : undefined
                }
                placement="left"
              >
                <Label>
                  {salesOrder?.reference.length > 15
                    ? `${salesOrder?.reference.substring(0, 15)}...`
                    : salesOrder?.reference}
                </Label>
              </Tooltip>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Customer')}</LabelTitle>
              <Label>
                {salesOrder?.customer_snapshot?.first_name +
                  ' ' +
                  salesOrder?.customer_snapshot?.last_name}
              </Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Amount')}</LabelTitle>
              <Label>
                {currencySymbol}
                {formatNumber(salesOrder?.total_amount)}
              </Label>
            </TextContainer>
          </ColumnContainer>
          <ColumnContainer>
            <H4>{t('STATUS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Payment')}</LabelTitle>
              <Label>{t(salesOrder?.payment_status?.name)}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Status')}</LabelTitle>
              <Label>{t(salesOrder?.status?.name)}</Label>
            </TextContainer>
          </ColumnContainer>
        </MainContainer>
      </Card>
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesInvoiceSalesOrderPage;

import { Option } from 'components/Select/type';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { addLabel, IAddEditLabelDTO } from 'services/Label/LabelService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useAddLabel = () => {
  const { t } = useTranslation();
  return useMutation((dto: IAddEditLabelDTO) => addLabel(dto), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Label added'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_LABELS_INFINITE]);
    },
    mutationKey: ReactMutationKeys.ADD_LABEL,
  });
};

export const useGetLabelColorOptions = (customColor: string | null) => {
  const colorOptions: Option[] = [
    { value: '#cc338b', label: '#cc338b', key: '#cc338b' },
    { value: '#dc143c', label: '#dc143c', key: '#dc143c' },
    { value: '#c21e56', label: '#c21e56', key: '#c21e56' },
    { value: '#cd5b45', label: '#cd5b45', key: '#cd5b45' },
    { value: '#ed9121', label: '#ed9121', key: '#ed9121' },
    { value: '#eee600', label: '#eee600', key: '#eee600' },
    { value: '#009966', label: '#009966', key: '#009966' },
    { value: '#8fbc8f', label: '#8fbc8f', key: '#8fbc8f' },
    { value: '#6699cc', label: '#6699cc', key: '#6699cc' },
    { value: '#e6e6fa', label: '#e6e6fa', key: '#e6e6fa' },
    { value: '#9400d3', label: '#9400d3', key: '#9400d3' },
    { value: '#330066', label: '#330066', key: '#330066' },
    { value: '#36454f', label: '#36454f', key: '#36454f' },
    { value: '#808080', label: '#808080', key: '#808080' },
  ];
  const customColorOption: Option = {
    value: customColor || '',
    label: customColor || '',
    key: customColor || '',
  };

  return customColor ? [customColorOption, ...colorOptions] : colorOptions;
};

import { CSSProperties } from 'react';
import {
  CheckboxInput,
  CheckboxSpan,
  ClickableContainer,
  Container,
} from './Checkbox.styled';

interface ICheckboxProps {
  label?: string;
  isChecked: boolean;
  onChange: (param: boolean) => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  disabled?: boolean;
}

export const Checkbox = ({
  label,
  onChange,
  isChecked,
  style,
  inputStyle,
  disabled = false,
}: ICheckboxProps) => {
  return (
    <Container>
      <ClickableContainer
        onClick={() => {
          if (!disabled) {
            onChange(!isChecked);
          }
        }}
        disabled={disabled}
      >
        <CheckboxInput
          onChange={() => null}
          checked={isChecked}
          disabled={disabled}
          style={inputStyle}
        />
        {label && (
          <CheckboxSpan style={style} disabled={disabled}>
            {label}
          </CheckboxSpan>
        )}
      </ClickableContainer>
    </Container>
  );
};

import { useTranslation } from 'react-i18next';
import { TabsOptions, TabsType } from '../constants';
import { TabsWrapper, TabText } from './Tabs.styled';
import { Tab } from './Tabs.styled';

export interface ITabsProps {
  onSelectTab: (tab: TabsType) => void;
  activeTab: TabsType;
}

interface ITab {
  name: TabsType;
}

const Tabs = ({ onSelectTab, activeTab }: ITabsProps) => {
  const calendarTabs: ITab[] = TabsOptions;
  const { t } = useTranslation();

  return (
    <TabsWrapper>
      {calendarTabs?.map((tab: ITab) => (
        <Tab
          key={tab.name}
          isActive={tab.name === activeTab}
          onClick={() => onSelectTab(tab.name)}
        >
          <TabText>{t(tab.name)}</TabText>
        </Tab>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;

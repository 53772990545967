import styled, { css } from 'styled-components';
import { COLORS, borderRadiusSm, marginMd, marginSm } from 'assets/styled';

interface StyledProps {
  isSelected: boolean;
  isDraft: boolean;
  $withoutMargin: boolean;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 770rem;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 30rem;
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 34rem;
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginSm};
`;

export const LabelsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${marginMd};
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
`;

export const AbsoluteDiscountOptionsWrapper = styled.div`
  position: absolute;
  top: -50rem;
  display: flex;
  flex-direction: row;
`;

export const DiscountOptionLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const RelativeDiscountFieldAndDiscountOptionsWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const Separator = styled.div`
  height: 40rem;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IsDraftLabel = styled.label`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  color: ${COLORS.GREY_700};
  ${(props: StyledProps) =>
    !props.isDraft &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;

export const TotalPurchasePriceWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const EyeWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 10rem;
  right: 15rem;
  width: 20rem;
`;

export const AddLabelDropdownWrapper = styled.div`
  margin-left: ${marginSm};
  ${(props: StyledProps) =>
    props.$withoutMargin &&
    css`
      margin: 0;
    `};
`;

import { Container, SelectWrapper } from './SelectCell.styled';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { PriorityOptions } from './constants';
import { useGetUsersSummaryInfinite } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { useMemo, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';

interface IProps {
  field: string;
  defaultValue: any;
  options?: Option[];
  onChange: (e: Option) => void;
}

export const USERS_PER_PAGE = 10;

const SelectCell = ({ field, defaultValue, options, onChange }: IProps) => {
  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);

  const isUsers = field === 'assignedTo';

  let selectOptions: Option[] = [];

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(
    USERS_PER_PAGE,
    debouncedUserSearchBy,
    isUsers
  );

  const users = useMemo(() => {
    if (usersData?.pages?.length) {
      return usersData.pages
        .map((page) => page.users)
        .flat()
        .map((user: any) => {
          return {
            value: user?.id,
            label: user.name + ' ' + user.last_name,
          };
        });
    }
    return [];
  }, [usersData]);

  if (options) {
    selectOptions = options;
  } else {
    switch (field) {
      case 'assignedTo':
        selectOptions = users;
        break;
      case 'priority':
        selectOptions = PriorityOptions;
        break;
    }
  }

  return (
    <Container>
      <SelectWrapper>
        <Select
          name="tableSelect"
          placeholder=""
          defaultValue={
            isUsers
              ? defaultValue
              : selectOptions.find((op) => op.key === defaultValue)
          }
          options={selectOptions}
          onChange={(e: Option) => onChange(e)}
          onInputChange={(e: string) => setUserSearchBy(e)}
          onMenuScrollToBottom={() =>
            isUsers && hasNextPageUsers && fetchNextPageUsers()
          }
          isClearable={isUsers ?? true}
          isLoading={isLoadingUsers}
          translate={isUsers ? false : true}
        />
      </SelectWrapper>
    </Container>
  );
};

export default SelectCell;

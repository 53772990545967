import { Select } from 'components/Select/Select';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { useGetSingleCustomer } from 'pages/Manager/SingleCustomerPage/hooks';
import { Option } from 'components/Select/type';
import { useGetCustomersInfinite } from '../hooks';

const CUSTOMERS_PER_PAGE = 10;

interface ICustomerSelectProps {
  handleValueChange: (e: any) => void;
  defaultValue: Option | number | string;
}

const CustomerSelect = ({
  handleValueChange,
  defaultValue,
}: ICustomerSelectProps) => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const {
    data: customersData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingCustomers,
  } = useGetCustomersInfinite(CUSTOMERS_PER_PAGE, debouncedSearchBy, true);

  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    isFetched,
  } = useGetSingleCustomer(
    defaultValue?.toString() || '',
    typeof defaultValue === 'string' || typeof defaultValue === 'number'
  );

  const preselectedCustomer =
    typeof defaultValue === 'object'
      ? defaultValue
      : {
          label: isFetched
            ? `${customerData?.customer?.name}  ${customerData?.customer?.last_name}`
            : '',
          value: customerData?.customer?.id,
        };

  const isLoading = isLoadingCustomers || isLoadingCustomer;

  const customers = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: any) => {
          return {
            value: customer?.id,
            label: `${customer?.name} ${customer?.last_name}`,
          };
        });
    }
    return [];
  }, [customersData]);

  return (
    <Select
      key={`${isLoadingCustomer}`}
      name="advancedFilterValue"
      placeholder={t('Value')}
      isMulti={false}
      isDisabled={false}
      defaultValue={preselectedCustomer}
      isSearchable={true}
      onChange={handleValueChange}
      onInputChange={(e: string) => setSearchBy(e)}
      onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
      options={customers || []}
      labelTransform={(string: string) => capitalizeFirstLetter(string)}
      isLoading={isLoading}
    />
  );
};

export default CustomerSelect;

import { COLORS, marginMd, marginSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import styled, { css } from 'styled-components';

interface StyledProps {
  $withoutMargin: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  max-height: 859rem;
  overflow-y: auto;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding-top: 20rem;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20rem;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const HorizontalLine = styled.div`
  width: 100%;
  max-width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-bottom: 10rem;
`;

export const FieldTitle = styled.span`
  font-size: 16rem;
  display: block;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;
`;

export const HalfFieldWrapper = styled.div`
  width: 100%;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  height: 100%; // Prevent error message from overflowing
  width: 100%;
`;

export const MultiFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
  width: 100%;
`;

export const HalfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10rem;
`;

export const BlueValue = styled.span`
  display: block;
  font-size: 16rem;
  margin-bottom: 10rem;
  color: ${COLORS.PRIMARY};
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
`;

export const AddLabelDropdownWrapper = styled.div`
  margin-left: ${marginSm};
  ${(props: StyledProps) =>
    props.$withoutMargin &&
    css`
      margin: 0;
    `};
`;

export const LabelsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

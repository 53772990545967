import { SortDirection } from 'components/Table/constants';
import { ICreateCustomerDTO } from 'pages/Manager/NewCustomerPage/types';
import {
  IComment,
  IEditCustomerOrSupplierDTO,
} from 'pages/Manager/SingleCustomerPage/types';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/customers/';

export const getCustomers = async (
  page?: number,
  perPage?: number,
  searchBy?: string,
  isActive?: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    is_active: isActive?.toString(),
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCustomersSummary = async (
  page?: number,
  perPage?: number,
  searchBy?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
  };
  const url = `${BASE_URL}summary`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCustomersInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSingleCustomer = async (customerId: string): Promise<any> => {
  const url = `${BASE_URL}${customerId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getSingleCustomerByEmail = async (
  customerEmail: string
): Promise<any> => {
  const url = `${BASE_URL}email/${customerEmail}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const createCustomer = async (
  createCustomerData: ICreateCustomerDTO
): Promise<any> => {
  const url = `${BASE_URL}`;
  const fullUrl = getUrlWithQueryParams(url, createCustomerData.queryParams);
  const { data } = await apiClient.post<any>(fullUrl, createCustomerData.data);

  return data.data;
};

export const getCustomerQuotations = async (
  customerId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${customerId}/quotations`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const editCustomer = async (
  customerId: string,
  editCustomerData: IEditCustomerOrSupplierDTO
): Promise<any> => {
  const url = `${BASE_URL}${customerId}`;
  const { data } = await apiClient.patch<any>(url, editCustomerData);

  return data.data;
};

export const deleteCustomer = async (customerId: string): Promise<any> => {
  const url = `${BASE_URL}${customerId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const activateCustomer = async (customerId: string): Promise<any> => {
  const url = `${BASE_URL}${customerId}`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getCustomerComments = async (
  customerId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${customerId}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addCommentForCustomer = async (
  id: string,
  commentData: IComment
): Promise<any> => {
  const url = `${BASE_URL}${id}/comments`;
  const { data } = await apiClient.post<any>(url, commentData);

  return data.data;
};

export const deleteCommentForCustomer = async (
  customerId: string,
  commentId: string
): Promise<any> => {
  const url = `${BASE_URL}${customerId}/comments/${commentId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const getCustomerReportsCurrent = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getCustomerReportsHistorical = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/historical-reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getCustomerSalesOrders = async (
  customerId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${customerId}/sales-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCustomerAppointments = async (
  customerId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${customerId}/appointments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCustomerInfoById = async (customerId: string): Promise<any> => {
  const url = `${BASE_URL}${customerId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getCustomerRecurringQuotations = async (
  customerId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${customerId}/recurring`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

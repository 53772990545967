import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import {
  createFwAppointmentLine,
  editFwAppointmentLine,
} from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  AppointmentType,
  ICreateFwAppointmentLineDTO,
  IEditFwAppointmentLineDTO,
} from 'types/Appointment.types';
import { useGetSingleFwAppointment } from '../hooks';
import { getPendingAppointments } from 'services/SalesOrder/SalesOrderService';

export const useAddFwAppointmentLine = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (createFwAppointmentLineData: ICreateFwAppointmentLineDTO) =>
      createFwAppointmentLine(appointmentId, createFwAppointmentLineData),
    {
      onSuccess: () => {
        toast.success(t('Successfully added Line to Appointment'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_FW_APPOINTMENT_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.ADD_FW_APPOINTMENT_LINE,
    }
  );
};

export const useEditFwAppointmentLine = (
  appointmentId: string,
  appointmentLineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (editFwAppointmentLineDTO: IEditFwAppointmentLineDTO) =>
      editFwAppointmentLine(
        appointmentId,
        appointmentLineId,
        editFwAppointmentLineDTO
      ),
    {
      onSuccess: () => {
        toast.success(t('Successfully edited appointment line'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_FW_APPOINTMENT_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_FW_APPOINTMENT_LINE,
    }
  );
};

export const useGetPrepopulateLineData = () => {
  const { state } = useLocation();
  const line = state?.line || null;
  const canAddOnlyStockItems: boolean = state?.canAddOnlyStockItems || false;
  const salesOrderId: string = state?.salesOrderId || '';
  return [line, canAddOnlyStockItems, salesOrderId];
};

export const useGetAppointmentType = (id: string): AppointmentType => {
  const { data: appointmentData } = useGetSingleFwAppointment(id);
  return appointmentData.appointment.type.name;
};

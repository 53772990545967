import { Switch } from '@mui/material';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { COLORS } from 'assets/styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { excludedTableDataKeys, translatableColumnFields } from './constants';
import {
  ActionCell,
  ActionHeaderCell,
  Body,
  BodyCell,
  BodyLabel,
  ButtonActionCell,
  Header,
  HeaderCell,
  HeaderLabel,
  Row,
  SwitchLabel,
  SwitchWrapper,
  Table,
} from './MollieMandatesTable.styled';

interface IMollieMandatesTable {
  tableData: any[];
  tableConfig: IMollieMandatesTableConfig;
  onButtonClick?: (param: any) => void;
  onView?: () => void;
  onEnabled?: (enabled: boolean, id: string) => void;
  onDelete?: (param: any) => void;
  animatedRows?: boolean;
  removedRows?: string[];
  primaryMandate: string;
}

export interface IMollieMandatesTableConfig {
  rowConfigs: IIMollieMandatesTableRowConfig[];
}

export interface IIMollieMandatesTableRowConfig {
  header: string;
  flexValue: number;
  isBlue?: boolean;
}

export const MollieMandatesTable = ({
  tableConfig,
  tableData,
  onButtonClick,
  onView,
  onEnabled,
  onDelete,
  animatedRows,
  removedRows,
  primaryMandate,
}: IMollieMandatesTable) => {
  const { t } = useTranslation();
  return (
    <Table>
      <Header>
        {tableConfig?.rowConfigs.map((rowData, index) => (
          <HeaderCell key={index} $flexValue={rowData.flexValue}>
            <HeaderLabel>{t(rowData.header)}</HeaderLabel>
          </HeaderCell>
        ))}
        {onView && <ActionHeaderCell></ActionHeaderCell>}
        {onEnabled && <ActionHeaderCell></ActionHeaderCell>}
        {onDelete && <ActionHeaderCell></ActionHeaderCell>}
        {onButtonClick && <ActionHeaderCell></ActionHeaderCell>}
      </Header>
      <Body>
        {tableData?.map((rowData, rowIndex) => {
          return (
            <Row
              key={rowIndex}
              className={
                animatedRows && removedRows?.includes(rowData.entityId)
                  ? 'exit-row'
                  : ''
              }
            >
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell key={cellIndex}>
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex]?.isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                  </BodyCell>
                ))}
              {onView && (
                <ActionCell>
                  <Button
                    onClick={() => onView()}
                    width="100rem"
                    label={t('View')}
                    link
                    underline
                  />
                </ActionCell>
              )}
              {onEnabled && (
                <ButtonActionCell>
                  <SwitchWrapper>
                    <SwitchLabel $enabled={rowData.enabled}>
                      {t('Enabled')}
                    </SwitchLabel>
                    <Switch
                      checked={rowData.enabled}
                      onChange={() => onEnabled(!rowData.enabled, rowData.id)}
                    />
                  </SwitchWrapper>
                </ButtonActionCell>
              )}
              {onDelete && (
                <ActionCell>
                  <Button
                    onClick={() => onDelete(rowData)}
                    redLink
                    underline
                    label={t('Delete')}
                    icon={Trash}
                    colorIcon={COLORS.RED_TRASH}
                    sizeIcon={15}
                  />
                </ActionCell>
              )}
              {onButtonClick && (
                <ButtonActionCell>
                  <Button
                    disabled={rowData['id'] == primaryMandate}
                    onClick={() => onButtonClick(rowData)}
                    tableAlt
                    width="150rem"
                    label={
                      rowData['id'] == primaryMandate
                        ? t('Preferred')
                        : t('Set as preferred')
                    }
                  />
                </ButtonActionCell>
              )}
            </Row>
          );
        })}
      </Body>
    </Table>
  );
};

import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { Option } from 'components/Select/type';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { generateLogyxIntent } from 'services/Logyx/LogyxService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGetQuickFieldOptions = () => {
  const quickFieldOptions: Option[] = [
    { value: 'Width', label: 'Width', key: 'Width' },
    { value: 'Height', label: 'Height', key: 'Height' },
    { value: 'Color', label: 'Color', key: 'Color' },
    { value: 'Depth', label: 'Depth', key: 'Depth' },
  ];
  return quickFieldOptions;
};

interface IGenerateLogyxIntentDTO {
  logyxModelId: number;
  productId?: number;
  logyxConfigurationIntentUUID?: string;
}

export const useGenerateLogyxIntent = () =>
  useMutation(
    (params: IGenerateLogyxIntentDTO) =>
      generateLogyxIntent(
        params.logyxModelId,
        params.productId,
        params.logyxConfigurationIntentUUID
      ),
    {
      onSuccess: () => {
        // onSuccess
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GENERATE_LOGYX_INTENT,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.GENERATE_LOGYX_INTENT,
    }
  );

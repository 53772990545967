// Function that determines whether a black or white label should be used based on the luminance of a hex color
export const getContrastingLabelColor = (hexColor: string): string => {
  // Normalize hex color to full 6 characters
  if (hexColor.length === 4) {
    hexColor =
      '#' +
      hexColor[1] +
      hexColor[1] +
      hexColor[2] +
      hexColor[2] +
      hexColor[3] +
      hexColor[3];
  }
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate luminance
  const luminance =
    0.2126 * (r / 255) ** 2.2 +
    0.7152 * (g / 255) ** 2.2 +
    0.0722 * (b / 255) ** 2.2;

  // Determine contrast color (simple threshold)
  return luminance > 0.179 ? '#000000' : '#FFFFFF';
};

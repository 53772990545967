import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1075rem;
  background-color: ${COLORS.WHITE};
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const DateLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.GREY_1000};
`;

export const DescriptionLabel = styled.label`
  margin-top: ${marginXs};
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  gap: 30rem;
`;

import styled from 'styled-components';
import { COLORS, marginSm, marginXs } from 'assets/styled';
import { Field } from 'formik';
import { hoverLineOut } from 'assets/styled/commonStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 859rem;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
  margin-right: 10rem;
`;

export const AltTimeWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const TimeWrapper = styled.div`
  width: 50%;
  max-width: 100%;
`;
export const Heading = styled.div`
  padding-top: ${marginXs};
  display: flex;
  align-items: center;
`;

export const ArrivalButton = styled.div`
  margin: 10rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
  width: 100%;
  max-width: 100%;
`;

export const StyledSelect = styled(Field)`
  width: 100%;
`;

export const StyledSelectBig = styled(Field)`
  width: 100%;
`;

export const HorizontalLine = styled.div`
  background-color: ${COLORS.GREY_200};
  width: 100%;
  max-width: 100%;
  height: 1rem;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;

export const WorkersAndResources = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20rem;
  padding: 20rem 0 10rem;
`;

export const ContBig = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${marginSm};
  width: 100%;
  max-width: 100%;
`;

export const PlusIcon = styled.img`
  margin-left: 10rem;
  cursor: pointer;
`;

export const IconCont = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0;
`;

export const WorkersAndResourcesHeading = styled.div`
  margin-top: ${marginSm};
`;

export const ErrorMessageCont = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: red;
  font-size: 14rem;
  font-weight: 100;
`;

export const ErrorMessage = styled.label`
  font-size: 14rem;
  font-weight: 100;
  color: ${COLORS.RED_200};
`;

export const ProductsButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const ProductErrorLabel = styled.label`
  font-weight: 100;
  color: ${COLORS.RED_100};
  margin-bottom: ${marginSm};
`;

export const UsersHeading = styled.div`
  margin-top: ${marginXs};
`;

export const UsersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40rem;
  padding: 20rem 0 10rem;
`;

export const HalfCont = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10rem);
`;

export const ArrivalTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`;

export const ArrivalTime = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.PRIMARY};
`;

export const ArrivalTimeInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  padding: 10rem 0;
`;

export const ArrivalTimeText = styled.span`
  ${hoverLineOut}
  cursor: pointer;
  display: inline-block;
  align-self: auto;
  font-size: 18rem;
`;

export const DateLabel = styled.label`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  display: block;
  margin-top: 5rem;
  font-weight: 300;
`;

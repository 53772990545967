import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ClearLabel,
  ClearWraper,
  Container,
  FilterItem,
  FilterList,
  FilterWrapper,
  ItemColor,
  SearchContainer,
} from './LabelsFilter.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useGetLabelsInfinite } from 'pages/Manager/LabelsPage/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { ILabel } from 'types/EntityLabel.types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import { MagnifyingGlass } from '@phosphor-icons/react';

const LABELS_PER_PAGE = 10;

interface ILabelsFilterProps {
  onSelectedLabelsChange: (selectedIds: number[]) => void;
}

const LabelsFilter = ({ onSelectedLabelsChange }: ILabelsFilterProps) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef();

  useOutsideAlerter(dropdownButtonRef, () => setIsFilterOpen(false));

  const {
    data: labelsData,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingGetLabels,
  } = useGetLabelsInfinite(LABELS_PER_PAGE, debouncedSearchBy);

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoadingGetLabels);

  const labels: ILabel[] = useMemo(() => {
    return (labelsData?.pages || []).map((page) => page.labels).flat();
  }, [labelsData]);

  useEffect(() => {
    onSelectedLabelsChange(selectedLabelIds);
  }, [selectedLabelIds, onSelectedLabelsChange]);

  const handleFilterItemClick = (id: number) => {
    setSelectedLabelIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((labelId) => labelId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <Container ref={dropdownButtonRef}>
      <Button
        onClick={() => setIsFilterOpen((prev) => !prev)}
        label={t('Labels')}
        width="120rem"
        height="31rem"
        secondary={!selectedLabelIds.length}
        primary={!!selectedLabelIds.length}
      />
      {isFilterOpen && (
        <FilterWrapper>
          <SearchContainer>
            <Input
              icon={MagnifyingGlass}
              width={'100%'}
              height={'30rem'}
              placeholder={t('Search')}
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              style={{ fontSize: '14rem' }}
              wrapperStyles={{ width: '100%' }}
            />
          </SearchContainer>
          <ClearWraper>
            <ClearLabel onClick={() => setSelectedLabelIds([])}>
              {t('Clear selected')}
            </ClearLabel>
          </ClearWraper>
          <FilterList>
            {labels.map((label: ILabel) => {
              const isChecked = selectedLabelIds.includes(label.id);

              return (
                <FilterItem
                  key={label.id}
                  onClick={() => handleFilterItemClick(label.id)}
                  $selected={isChecked}
                >
                  <Checkbox
                    isChecked={isChecked}
                    label={label.name}
                    onChange={() => null}
                  />
                  <ItemColor $bgColor={label.color} />
                </FilterItem>
              );
            })}
            <div
              ref={loadMoreRef}
              style={{
                height: '1px',
              }}
            />
          </FilterList>
        </FilterWrapper>
      )}
    </Container>
  );
};

export default LabelsFilter;

import { Checkbox } from 'components/Checkbox/Checkbox';
import { Container } from './CheckboxCell.styled';

interface IProps {
  value: boolean;
  onChange: (row: any) => void;
}

const CheckboxCell = ({ value, onChange }: IProps) => {
  return (
    <Container>
      <Checkbox
        isChecked={value}
        onChange={onChange}
        inputStyle={{ transform: 'scale(1.5)' }}
      />
    </Container>
  );
};

export default CheckboxCell;

import { MagnifyingGlass } from '@phosphor-icons/react';
import {
  Container,
  FlexAlignCenter,
  Row,
  TitleContainer,
} from './ToDoPage.styled';
import { H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Table from 'components/Table/Table';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGetToDoAdvancedFilters } from './advancedFilters';
import { tableConfig, TabsType, TODOS_PER_PAGE } from './constants';
import { IToDo, IToDoTableDTO } from 'types/ToDo.types';
import Tabs from './Tabs/Tabs';
import AddEditToDoModal from './AddEditToDoModal/AddEditToDoModal';
import ViewToDoModal from './ViewToDoModal/ViewToDoModal';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetToDos, useGetToDoTypes, useUpdateToDo } from './hooks';
import { formatToDosTableData } from 'components/Table/tableDataFormatter';
import { SortDirection } from 'components/Table/constants';
import { Option } from 'components/Select/type';

const ToDoPage = () => {
  const { t } = useTranslation();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [isAddEditToDoModalOpen, setIsAddEditToDoModalOpen] =
    useState<boolean>(false);
  const [isViewToDoModalOpen, setIsViewToDoModalOpen] =
    useState<boolean>(false);
  const [selectedToDoId, setSelectedToDoId] = useState<string>('');
  const [toDoForEditId, setToDoForEditId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState<string>('');
  const [activeTab, setActiveTab] = useState<TabsType>(TabsType.ALL);
  const [selectedTab, setSelectedTab] = useState<boolean | undefined>(
    undefined
  );
  const [relatedTypeId, setRelatedTypeId] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined
  );
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const { data, isLoading: isLoadingData } = useGetToDos(
    forceUpdate,
    page,
    sortBy,
    sortDirection,
    TODOS_PER_PAGE,
    debouncedSearchBy,
    advancedFilters,
    selectedTab
  );

  const { mutate: updateToDo } = useUpdateToDo();

  let tableData: IToDoTableDTO[] = [];
  if (data) {
    tableData = formatToDosTableData(
      data.todos
        .map((todo: IToDo[]) => {
          return todo;
        })
        .flat()
    );
  }
  const { data: toDoTypesOptions } = useGetToDoTypes();
  const { filterTypes } = useGetToDoAdvancedFilters(toDoTypesOptions);

  const changeTab = (tab: TabsType) => {
    setPage(1);
    setActiveTab(tab);
    if (tab === TabsType.FINISHED) {
      setSelectedTab(true);
    } else if (tab === TabsType.OPEN) {
      setSelectedTab(false);
    } else {
      setSelectedTab(undefined);
    }
  };

  const handleViewButtonClick = (rowData: IToDoTableDTO) => {
    setRelatedTypeId(rowData?.relatedTypeId);
    setSelectedToDoId(rowData?.todoId?.toString());
    setIsViewToDoModalOpen(true);
  };

  const onCheckboxChange = (rowData: IToDoTableDTO) => {
    updateToDo({
      id: rowData.todoId.toString(),
      updateToDoData: { finished: !rowData.finished },
    });
  };

  const onSelectChange = (
    e: Option,
    rowData: IToDoTableDTO,
    rowDataKey: string
  ) => {
    let updateToDoData;
    if (rowDataKey === 'priority') updateToDoData = { priority_id: e.value };
    if (rowDataKey === 'assignedTo')
      updateToDoData = { assigned_to_user_id: e === null ? null : e.value };

    updateToDo({
      id: rowData.todoId,
      updateToDoData,
    });
  };

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <Button
          className="quotations-step-3"
          onClick={() => {
            setIsEditMode(false);
            setToDoForEditId('');
            setIsAddEditToDoModalOpen(true);
          }}
          label={t('Create To Do')}
          width="200rem"
          height="41rem"
          primary
        />
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <Button
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </Row>
      <Tabs activeTab={activeTab} onSelectTab={changeTab} />
      <Table
        key={forceUpdate}
        page={page}
        isLoading={isLoadingData}
        perPage={TODOS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        withoutMarginTop={true}
        onCheckboxChange={onCheckboxChange}
        onSelectChange={onSelectChange}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        labelsRow
      />
      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />

      {isAddEditToDoModalOpen && (
        <AddEditToDoModal
          isOpen={isAddEditToDoModalOpen}
          setIsOpen={setIsAddEditToDoModalOpen}
          id={toDoForEditId}
          isEditMode={isEditMode}
          key={toDoForEditId}
          setForceUpdate={setForceUpdate}
          relatedTypeId={relatedTypeId}
        />
      )}

      {isViewToDoModalOpen && (
        <ViewToDoModal
          isOpen={isViewToDoModalOpen}
          setIsOpen={setIsViewToDoModalOpen}
          onEdit={(id: string) => {
            setToDoForEditId(id);
            setIsEditMode(true);
            setIsAddEditToDoModalOpen(true);
          }}
          id={selectedToDoId}
          key={selectedToDoId + 'key'}
          setForceUpdate={setForceUpdate}
          relatedTypeId={relatedTypeId}
        />
      )}
    </Container>
  );
};

export default ToDoPage;

import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import {
  borderRadiusSm,
  marginMd,
  marginSm,
  marginXs,
  paddingLg,
  paddingSm,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isSelected: boolean;
  isSideMenuCollapsed: boolean;
  isTablet: boolean;
  routerWithoutPaddingBottom: boolean;
  headerHeight: number;
  $withoutMargin: boolean;
  $noLabels: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 50rem;
  padding-right: 50rem;
  padding-bottom: 0;
  margin-left: 265rem;

  ${respondTo.bigTablet`
    margin-left: 0rem;
    padding: 0 30rem;
  `}
`;
export const HeaderWrapper = styled.div`
  width: calc(100vw - 265rem - 265rem);
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  padding-right: 100rem;
  background-color: ${COLORS.BACKGROUND_COLOR};
  justify-content: flex-start;

  ${respondTo.bigTablet`
    padding-right: 65rem;
  `} ${(props: StyledProps) =>
    props.isSideMenuCollapsed
      ? props.isTablet
        ? css`
            width: calc(100vw - 115rem);
          `
        : css`
            width: calc(100vw - 115rem - 265rem);
          `
      : props.isTablet
      ? css`
          width: calc(100vw - 265rem);
        `
      : css`
          width: calc(100vw - 265rem - 265rem);
        `};
`;
export const RouterWrapper = styled.div`
  margin-top: 185rem;
  ${(props: StyledProps) =>
    props.headerHeight &&
    css`
      margin-top: ${props.headerHeight}px;
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50rem;
  flex-direction: column;

  ${(props: StyledProps) => {
    if (props.routerWithoutPaddingBottom) {
      return css`
        padding-bottom: 0rem;
      `;
    }
  }}
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-between;
  padding-top: ${paddingLg};
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const H3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24rem;
  padding-right: 30rem;
  color: ${COLORS.BLACK};
`;
export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${marginXs};

  ${(props: StyledProps) =>
    !props.$noLabels &&
    css`
      margin-bottom: ${marginSm};
      margin-top: ${marginXs};
    `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: flex-end;
`;
export const ButtonContainer = styled.div`
  gap: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${paddingSm};
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const PenImg = styled.div`
  display: flex;
  width: 15rem;
  height: 15rem;
  margin-bottom: 15rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const P = styled.span`
  cursor: pointer;
  color: ${COLORS.PRIMARY};
  font-weight: 300;
  font-size: 18rem;
  margin-right: 3rem;
`;
export const P2 = styled.span`
  color: ${COLORS.PRIMARY};
  padding-left: 5rem;
  font-weight: 300;
  padding-right: 30rem;
  font-size: 14rem;
`;

export const Margin = styled.div`
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
`;

export const MarginSmall = styled.div`
  margin-top: ${marginXs};
  margin-bottom: ${marginXs};
`;

export const ButtonModalContainer = styled.div`
  gap: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10rem;
  justify-content: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  position: relative;
`;

export const StatusOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 10rem;
  position: absolute;
  width: 200rem;
  border: 1px solid ${COLORS.GREY_200};
  z-index: 2;
  top: 40rem;
`;

export const StatusOptionLabel = styled.div`
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
    `}

  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10rem;
  padding-top: 10rem;
  border-bottom: 1px solid ${COLORS.GREY_200};
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    background-color: ${COLORS.GREY_200};
    border-radius: ${borderRadiusSm};
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25rem;
  height: 25rem;
  margin-left: ${marginXs};
  margin-right: ${marginXs};
`;

export const AddLabelDropdownWrapper = styled.div`
  margin-left: ${marginSm};
  ${(props: StyledProps) =>
    props.$withoutMargin &&
    css`
      margin: 0;
    `};
`;

export const SpinnerWrapper = styled.div`
  margin-right: ${marginSm};
`;
